import { render, staticRenderFns } from "./Cupones.vue?vue&type=template&id=801bbfae&scoped=true"
import script from "./Cupones.vue?vue&type=script&lang=js"
export * from "./Cupones.vue?vue&type=script&lang=js"
import style0 from "./Cupones.vue?vue&type=style&index=0&id=801bbfae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "801bbfae",
  null
  
)

export default component.exports