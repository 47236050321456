<template>
  <div>
    <div class="row">
      <div class="col-6">
        <ValidationProvider name="estatus_migratorio" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estatus migratorio es obligatorio'
        }">
          <label for="">Estatus Migratorio</label>
          <select v-model="user.estatus_migratorio" class="custom-select" :disabled="user.disabled">
            <option value="Mexicano">Mexicano</option>
            <option value="Residente Temporal">Residente Temporal</option>
            <option value="Residente Permanente">Residente Permanente</option>
            <option value="Extranjero">Extranjero</option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-6" v-if="user.estatus_migratorio === 'Extranjero'">
        <base-input type="date" label="Fecha de ingreso al país" placeholder="Fecha de ingreso al país"
          v-model="user.fecha_ingreso_pais" :disabled="user.disabled">
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="user.nombre"
            :disabled="user.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio'
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno" v-model="user.paterno"
            :disabled="user.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno" v-model="user.materno"
          :disabled="user.disabled">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="identificacion_tipo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de identificación es obligatorio'
        }">
          <label>Identificación: Tipo</label>
          <select v-model="user.identificacion_tipo" class="custom-select mb-2" :disabled="user.disabled"
            @change="selectTipoId">
            <option v-for="option in optionsIdentificacionTipo" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <base-input type="text" label="" placeholder="Tipo" v-model="user.identificacion_tipo_otro"
            v-if="user.identificacion_tipo === 'Otro'" :disabled="user.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="identificacion_autoridad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La autoridad es obligatoria'
        }">
          <label>Autoridad</label>
          <select v-model="user.identificacion_autoridad" class="custom-select mb-2" :disabled="user.disabled">
            <option v-for="option in optionsIdentificacionAutoridad" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <base-input type="text" :disabled="user.disabled" label="" placeholder="Autoridad"
            v-model="user.identificacion_autoridad_otro" v-if="user.identificacion_autoridad === 'Otro'">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="identificacion_numero" :rules="requiredIfNotRatificacion" v-slot="{ errors }"
          :custom-messages="{
            required: 'El número es obligatorio'
          }">
          <base-input type="text" label="Número de Teléfono" placeholder="Número de Teléfono"
            v-model="user.identificacion_numero" :disabled="user.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div v-if="user.estatus_migratorio === 'Extranjero'" class="row">
      <div class="col-md-6">
        <ValidationProvider name="rfc" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El ID Tax es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="ID Tax" placeholder="ID Tax" v-model="user.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="curp" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número de Identidad Nacional es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Número de Identidad Nacional, NSS o similar."
            placeholder="Número de Identidad Nacional, NSS o similar." v-model="user.curp">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-6">
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres'
        }">
          <base-input :disabled="user.disabled" type="text" label="RFC" placeholder="RFC" v-model="user.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="curp" rules="required|length:18" v-slot="{ errors }" :custom-messages="{
          required: 'La CURP es obligatorio',
          length: 'Debe tener 18 caracteres'
        }">
          <base-input :disabled="user.disabled" type="text" label="CURP" placeholder="CURP" v-model="user.curp">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <ValidationProvider name="fecha_nacimiento" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de nacimiento es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="Fecha de nacimiento"
            placeholder="Fecha de nacimiento" v-model="user.fecha_nacimiento">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-5">
        <!-- TODO: Refactorizar este select para utilizar las nacionalidades de la api -->
        <ValidationProvider name="nacionalidad" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'La nacionalidad es obligatoria' }">
          <label>Nacionalidades</label>
          <!-- <v-select :disabled="user.disabled" multiple v-model="user.nacionalidades" :label="name" :options="optionsNacionalidades"
            :selectable="option => !option.includes('____________________________________________________')" /> -->
          <v-select :disabled="user.disabled" multiple v-model="user.nacionalidades" label="nacionalidad"
            :options="optionsNacionalidades" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="sexo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El sexo es obligatorio'
        }">
          <label for="">Género</label>
          <select v-model="user.sexo" class="custom-select" :disabled="user.disabled">
            <option value="Femenino">Femenino</option>
            <option value="Masculino">Masculino</option>
            <option value="Prefiero no decir">Prefiero no decir</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ciudad de nacimiento es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Lugar de nacimiento: Ciudad/Alcaldía"
            placeholder="Ciudad" v-model="user.lugar_nacim_ciudad">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Estado" placeholder="Estado"
            v-model="user.lugar_nacim_estado">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }"> -->
      <!-- <base-input
            type="text"
            label="País"
            placeholder="País"
            v-model="user.lugar_nacim_pais"
          >
          </base-input> -->
      <!-- <label>País</label>
          <select v-model="user.lugar_nacim_pais" class="custom-select" :disabled="user.disabled">
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->

      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }">
          <label>Lugar de nacimiento: País</label>
          <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control" :disabled="user.disabled">
            <option v-for="option in countries" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio',
        }">
          <label>Lugar de nacimiento: Estado</label>
          <select @change="fetchCitiesByState" v-model="selectState" class="form-control" :disabled="user.disabled">
            <option v-if="isEmptyStates" value="noStates" selected>
              {{ noStatesText }}
            </option>
            <option v-for="option in states" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ciudad de nacimiento es obligatoria',
        }">
          <label>Municipio / Alcaldía</label>
          <select v-model="selectCity" class="form-control" :disabled="user.disabled">
            <option v-if="isEmptyCities" value="noCities" selected>
              {{ noCitiesText }}
            </option>
            <option v-for="option in cities" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="ocupacion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ocupación es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Ocupación / Actividad / Profesión"
            placeholder="Ocupación" v-model="user.ocupacion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="giro_negocios" :rules="requiredIfNotRatificacion" v-slot="{ errors }"
          :custom-messages="{
            required: 'El giro de negocios es obligatorio'
          }">
          <base-input :disabled="user.disabled" type="text" label="Giro de negocios" placeholder="Giro de negocios"
            v-model="user.giro_negocios">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="estado_civil" :rules="requiredIfNotRatificacion" v-slot="{ errors }" :custom-messages="{
          required: 'El estado civil es obligatorio'
        }">
          <label for="">Estado civil</label>
          <select v-model="user.estado_civil" class="custom-select" :disabled="user.disabled">
            <option value="Soltero (a)">Soltero (a)</option>
            <option value="Casado (a)">Casado (a)</option>
            <option value="Unión Libre">Unión Libre</option>
            <option value="Sociedad de Convivencia">Sociedad de Convivencia</option>
            <option value="Viudo (a)">Viudo (a)</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row" v-if="isFromConstitutiva">
      <div class="col-md-4">
        <ValidationProvider name="alias" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El alias es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Alias" placeholder="Alias" v-model="user.alias">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="regimen_patrimonial" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El régimen patrimonial es obligatorio'
        }">
          <label for="">Régimen patrimonial</label>
          <select v-model="user.regimen_patrimonial" class="custom-select" :disabled="user.disabled">
            <option value="Sociedad Conyugal">Sociedad Conyugal</option>
            <option value="Separación de Bienes">Separación de Bienes</option>
            <option value="No Aplica">No Aplica</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <div v-if="user.regimen_patrimonial === 'No Aplica'">
          <base-input :disabled="true" type="text" label="Nombre del cónyuge" placeholder="Nombre del cónyuge"
            v-model="user.identificacion_conyuge">
          </base-input>
        </div>
        <div v-else>
          <ValidationProvider name="identificacion_conyuge" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'El nombre del cónyuge es obligatorio'
          }">
            <base-input :disabled="user.disabled" type="text" label="Nombre del cónyuge"
              placeholder="Nombre del cónyuge" v-model="user.identificacion_conyuge">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <!--
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider
          name="alias"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El alias es obligatorio',
          }"
        >
          <base-input
            type="text"
            label="Alias"
            placeholder="Alias"
            v-model="user.alias"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider
          name="pais_residencia"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El país de residencia es obligatorio',
          }"
        >
          <base-input
            type="text"
            label="País de residencia"
            placeholder="País de residencia"
            v-model="user.pais_residencia"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider
          name="estado_civil"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El estado civil es obligatorio' }"
        >
          <base-input
            type="text"
            label="Estado civil"
            placeholder="Estado civil"
            v-model="user.estado_civil"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <base-input
          type="text"
          label="Identificación de cónyuge"
          placeholder="Identificación de cónyuge"
          v-model="user.identificacion_conyuge"
        >
        </base-input>

      </div>
      <div class="col-md-4">
        <base-input
          type="text"
          label="Régimen patrimonial"
          placeholder="Régimen patrimonial"
          v-model="user.regimen_patrimonial"
        >
        </base-input>
      </div>
    </div> -->

    <!-- <button type="submit" class="btn orange-btn bottom">Guardar</button> -->
    <div class="">
      <b-button type="submit" class="btn-fill orange-btn bottom" size="lg" :disabled="user.disabled">Guardar</b-button>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";

import { CatNacionalidadesApi } from "@/api";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "FormFisica",
  components: {
    Card
  },

  props: {
    user: { type: Object },
    ratificacion: { type: Boolean }
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),
    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),
    requiredIfNotRatificacion() {
      return this.ratificacion ? "" : "required";
    }
  },

  created() {
    this.fetchNacionalidades();
    this.fromConstitutiva();
  },

  watch: {
    "user.regimen_patrimonial": function watchRegimenPatrimonial(val, oldVal) {
      if (val === "No Aplica") {
        this.user.identificacion_conyuge = "No Aplica";
      }
    },

    "user.curp": function (value) {
      if (!value) {
        this.user.curp = "";

        return;
      }

      this.user.curp = this.user.curp.toUpperCase();
    },

    "user.rfc": function (value) {
      if (!value) {
        this.user.rfc = "";

        return;
      }

      this.user.rfc = this.user.rfc.toUpperCase();
    },

    "selectCountry": function (val) {
      this.user.lugar_nacim_pais = this.selectCountry;
    },

    "selectState": function () {
      this.user.lugar_nacim_estado = this.selectState;
    },

    "selectCity": function (val) {
      this.user.lugar_nacim_ciudad = this.selectCity;
    }
  },

  data() {
    return {
      isFromConstitutiva: false,
      optionsNacionalidades: [],
      // "____________________________________________________",

      optionsIdentificacionTipo: [
        // { text: "IFE", value: "IFE" },
        // { text: "INE", value: "INE" },
        { text: "Credencial para votar", value: "Credencial para votar" },
        { text: "Pasaporte", value: "Pasaporte" },
        { text: "Cédula Profesional", value: "Cédula Profesional" },
        { text: "Licencia de Conducir", value: "Licencia de Conducir" },
        { text: "Forma Migratoria", value: "Forma Migratoria" },
        { text: "Otro", value: "Otro" }
      ],
      optionsIdentificacionAutoridad: [
        {
          text: "Instituto Federal Electoral",
          value: "Instituto Federal Electoral"
        },
        {
          text: "Instituto Nacional Electoral",
          value: "Instituto Nacional Electoral"
        },
        {
          text: "Secretaría de Relaciones Exteriores",
          value: "Secretaría de Relaciones Exteriores"
        },
        {
          text: "Secretaría de Educación Pública",
          value: "Secretaría de Educación Pública"
        },
        // { text: "Licencia de Conducir", value: "Licencia de Conducir" },
        {
          text: "Instituto Nacional de Migración",
          value: "Instituto Nacional de Migración"
        },
        { text: "Otro", value: "Otro" }
      ],
      options: [
        { text: "México", value: "México" },
        { text: " España", value: " España" },
        { text: " Argentina", value: " Argentina" },
        { text: " Estados Unidos", value: " Estados Unidos" },
        { text: " Afganistán", value: " Afganistán" },
        { text: " Albania", value: " Albania" },
        { text: " Alemania", value: " Alemania" },
        { text: " Andorra", value: " Andorra" },
        { text: " Angola", value: " Angola" },
        { text: " Antigua y Barbuda", value: " Antigua y Barbuda" },
        { text: " Arabia Saudita", value: " Arabia Saudita" },
        { text: " Argelia", value: " Argelia" },
        { text: " Armenia", value: " Armenia" },
        { text: " Australia", value: " Australia" },
        { text: " Austria", value: " Austria" },
        { text: " Azerbaiyán", value: " Azerbaiyán" },
        { text: " Bahamas", value: " Bahamas" },
        { text: " Bangladés", value: " Bangladés" },
        { text: " Barbados", value: " Barbados" },
        { text: " Baréin", value: " Baréin" },
        { text: " Bélgica", value: " Bélgica" },
        { text: " Belice", value: " Belice" },
        { text: " Benín", value: " Benín" },
        { text: " Bielorrusia", value: " Bielorrusia" },
        { text: " Birmania", value: " Birmania" },
        { text: " Bolivia", value: " Bolivia" },
        { text: " Bosnia y Herzegovina", value: " Bosnia y Herzegovina" },
        { text: " Botsuana", value: " Botsuana" },
        { text: " Brasil", value: " Brasil" },
        { text: " Brunéi", value: " Brunéi" },
        { text: " Bulgaria", value: " Bulgaria" },
        { text: " Burkina Faso", value: " Burkina Faso" },
        { text: " Burundi", value: " Burundi" },
        { text: " Bután", value: " Bután" },
        { text: " Cabo Verde", value: " Cabo Verde" },
        { text: " Camboya", value: " Camboya" },
        { text: " Camerún", value: " Camerún" },
        { text: " Canadá", value: " Canadá" },
        { text: " Catar", value: " Catar" },
        { text: " Chad", value: " Chad" },
        { text: " Chile", value: " Chile" },
        { text: " China", value: " China" },
        { text: " Chipre", value: " Chipre" },
        { text: " Ciudad del Vaticano", value: " Ciudad del Vaticano" },
        { text: " Colombia", value: " Colombia" },
        { text: " Comoras", value: " Comoras" },
        { text: " Corea del Norte", value: " Corea del Norte" },
        { text: " Corea del Sur", value: " Corea del Sur" },
        { text: " Costa de Marfil", value: " Costa de Marfil" },
        { text: " Costa Rica", value: " Costa Rica" },
        { text: " Croacia", value: " Croacia" },
        { text: " Cuba", value: " Cuba" },
        { text: " Dinamarca", value: " Dinamarca" },
        { text: " Dominica", value: " Dominica" },
        { text: " Ecuador", value: " Ecuador" },
        { text: " Egipto", value: " Egipto" },
        { text: " El Salvador", value: " El Salvador" },
        { text: " Emiratos Árabes Unidos", value: " Emiratos Árabes Unidos" },
        { text: " Eritrea", value: " Eritrea" },
        { text: " Eslovaquia", value: " Eslovaquia" },
        { text: " Eslovenia", value: " Eslovenia" },
        { text: " Estonia", value: " Estonia" },
        { text: " Etiopía", value: " Etiopía" },
        { text: " Filipinas", value: " Filipinas" },
        { text: " Finlandia", value: " Finlandia" },
        { text: " Fiyi", value: " Fiyi" },
        { text: " Francia", value: " Francia" },
        { text: " Gabón", value: " Gabón" },
        { text: " Gambia", value: " Gambia" },
        { text: " Georgia", value: " Georgia" },
        { text: " Ghana", value: " Ghana" },
        { text: " Granada", value: " Granada" },
        { text: " Grecia", value: " Grecia" },
        { text: " Guatemala", value: " Guatemala" },
        { text: " Guyana", value: " Guyana" },
        { text: " Guinea", value: " Guinea" },
        { text: " Guinea ecuatorial", value: " Guinea ecuatorial" },
        { text: " Guinea-Bisáu", value: " Guinea-Bisáu" },
        { text: " Haití", value: " Haití" },
        { text: " Honduras", value: " Honduras" },
        { text: " Hungría", value: " Hungría" },
        { text: " India", value: " India" },
        { text: " Indonesia", value: " Indonesia" },
        { text: " Irak", value: " Irak" },
        { text: " Irán", value: " Irán" },
        { text: " Irlanda", value: " Irlanda" },
        { text: " Islandia", value: " Islandia" },
        { text: " Islas Marshall", value: " Islas Marshall" },
        { text: " Islas Salomón", value: " Islas Salomón" },
        { text: " Israel", value: " Israel" },
        { text: " Italia", value: " Italia" },
        { text: " Jamaica", value: " Jamaica" },
        { text: " Japón", value: " Japón" },
        { text: " Jordania", value: " Jordania" },
        { text: " Kazajistán", value: " Kazajistán" },
        { text: " Kenia", value: " Kenia" },
        { text: " Kirguistán", value: " Kirguistán" },
        { text: " Kiribati", value: " Kiribati" },
        { text: " Kuwait", value: " Kuwait" },
        { text: " Laos", value: " Laos" },
        { text: " Lesoto", value: " Lesoto" },
        { text: " Letonia", value: " Letonia" },
        { text: " Líbano", value: " Líbano" },
        { text: " Liberia", value: " Liberia" },
        { text: " Libia", value: " Libia" },
        { text: " Liechtenstein", value: " Liechtenstein" },
        { text: " Lituania", value: " Lituania" },
        { text: " Luxemburgo", value: " Luxemburgo" },
        { text: " Macedonia del Norte", value: " Macedonia del Norte" },
        { text: " Madagascar", value: " Madagascar" },
        { text: " Malasia", value: " Malasia" },
        { text: " Malaui", value: " Malaui" },
        { text: " Maldivas", value: " Maldivas" },
        { text: " Malí", value: " Malí" },
        { text: " Malta", value: " Malta" },
        { text: " Marruecos", value: " Marruecos" },
        { text: " Mauricio", value: " Mauricio" },
        { text: " Mauritania", value: " Mauritania" },
        { text: " México", value: " México" },
        { text: " Micronesia", value: " Micronesia" },
        { text: " Moldavia", value: " Moldavia" },
        { text: " Mónaco", value: " Mónaco" },
        { text: " Mongolia", value: " Mongolia" },
        { text: " Montenegro", value: " Montenegro" },
        { text: " Mozambique", value: " Mozambique" },
        { text: " Namibia", value: " Namibia" },
        { text: " Nauru", value: " Nauru" },
        { text: " Nepal", value: " Nepal" },
        { text: " Nicaragua", value: " Nicaragua" },
        { text: " Níger", value: " Níger" },
        { text: " Nigeria", value: " Nigeria" },
        { text: " Noruega", value: " Noruega" },
        { text: " Nueva Zelanda", value: " Nueva Zelanda" },
        { text: " Omán", value: " Omán" },
        { text: " Países Bajos", value: " Países Bajos" },
        { text: " Pakistán", value: " Pakistán" },
        { text: " Palaos", value: " Palaos" },
        { text: " Panamá", value: " Panamá" },
        { text: " Papúa Nueva Guinea", value: " Papúa Nueva Guinea" },
        { text: " Paraguay", value: " Paraguay" },
        { text: " Perú", value: " Perú" },
        { text: " Polonia", value: " Polonia" },
        { text: " Portugal", value: " Portugal" },
        { text: " Reino Unido", value: " Reino Unido" },
        {
          text: " República Centroafricana",
          value: " República Centroafricana"
        },
        { text: " República Checa", value: " República Checa" },
        { text: " República del Congo", value: " República del Congo" },
        {
          text: " República Democrática del Congo",
          value: " República Democrática del Congo"
        },
        { text: " República Dominicana", value: " República Dominicana" },
        { text: " Ruanda", value: " Ruanda" },
        { text: " Rumanía", value: " Rumanía" },
        { text: " Rusia", value: " Rusia" },
        { text: " Samoa", value: " Samoa" },
        { text: " San Cristóbal y Nieves", value: " San Cristóbal y Nieves" },
        { text: " San Marino", value: " San Marino" },
        {
          text: " San Vicente y las Granadinas",
          value: " San Vicente y las Granadinas"
        },
        { text: " Santa Lucía", value: " Santa Lucía" },
        { text: " Santo Tomé y Príncipe", value: " Santo Tomé y Príncipe" },
        { text: " Senegal", value: " Senegal" },
        { text: " Serbia", value: " Serbia" },
        { text: " Seychelles", value: " Seychelles" },
        { text: " Sierra Leona", value: " Sierra Leona" },
        { text: " Singapur", value: " Singapur" },
        { text: " Siria", value: " Siria" },
        { text: " Somalia", value: " Somalia" },
        { text: " Sri Lanka", value: " Sri Lanka" },
        { text: " Suazilandia", value: " Suazilandia" },
        { text: " Sudáfrica", value: " Sudáfrica" },
        { text: " Sudán", value: " Sudán" },
        { text: " Sudán del Sur", value: " Sudán del Sur" },
        { text: " Suecia", value: " Suecia" },
        { text: " Suiza", value: " Suiza" },
        { text: " Surinam", value: " Surinam" },
        { text: " Tailandia", value: " Tailandia" },
        { text: " Tanzania", value: " Tanzania" },
        { text: " Tayikistán", value: " Tayikistán" },
        { text: " Timor Oriental", value: " Timor Oriental" },
        { text: " Togo", value: " Togo" },
        { text: " Tonga", value: " Tonga" },
        { text: " Trinidad y Tobago", value: " Trinidad y Tobago" },
        { text: " Túnez", value: " Túnez" },
        { text: " Turkmenistán", value: " Turkmenistán" },
        { text: " Turquía", value: " Turquía" },
        { text: " Tuvalu", value: " Tuvalu" },
        { text: " Ucrania", value: " Ucrania" },
        { text: " Uganda", value: " Uganda" },
        { text: " Uruguay", value: " Uruguay" },
        { text: " Uzbekistán", value: " Uzbekistán" },
        { text: " Vanuatu", value: " Vanuatu" },
        { text: " Venezuela", value: " Venezuela" },
        { text: " Vietnam", value: " Vietnam" },
        { text: " Yemen", value: " Yemen" },
        { text: " Yibuti", value: " Yibuti" },
        { text: " Zambia", value: " Zambia" },
        { text: " Zimbabue", value: " Zimbabue" }
      ],
      form: {
        persona: null,
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        rfc: null,
        fecha_nacimiento: null,
        lugar_nacim_ciudad: null,
        lugar_nacim_estado: null,
        lugar_nacim_pais: null,

        nacionalidades: [],

        curp: null,
        ocupacion: null,
        giro_negocios: null,
        sexo: null,
        // alias: null,
        // pais_residencia: null,
        // estado_civil: null,
        // identificacion_conyuge: null,
        // regimen_patrimonial: null,
        identificacion_tipo: null,
        identificacion_autoridad: null,
        identificacion_numero: null,

        identificacion_tipo_otro: null,
        identificacion_autoridad_otro: null,

        estatus_migratorio: null,
        fecha_ingreso_pais: null
      },

      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    };
  },

  async mounted() {
    await this.fetchCountries();

    if (this.user.lugar_nacim_pais) {
      this.selectCountry = this.user.lugar_nacim_pais;

      if (this.user.telefonos[0].clave) {
        this.phoneKey = this.user.telefonos[0].clave;
      }
      if (this.user.lugar_nacim_estado) {
        await this.getExistCountry(this.user.lugar_nacim_pais);
      }
      if (this.user.lugar_nacim_estado) {
        await this.getExistState(this.user.lugar_nacim_estado);
      }
      if (this.user.lugar_nacim_ciudad) {
        await this.getExistCity(this.user.lugar_nacim_ciudad);
      }
    }
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.user.lugar_nacim_pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    // setGeoCompareciente() {
    //   /**
    //    * Setea los valores de los campos de compareciente
    //    */
    //   this.currentCompareciente.lugar_nacim_pais = this.selectCountry;
    //   this.currentCompareciente.lugar_nacim_estado = this.selectState;
    //   this.currentCompareciente.lugar_nacim_ciudad = this.selectCity;
    //   this.currentCompareciente.telefonos[0].clave = this.phoneKey;
    // },

    async fetchNacionalidades() {
      const { value } = await CatNacionalidadesApi.list({
        page: 0,
        registersPerPage: 0
      });

      this.optionsNacionalidades = value;
    },

    selectTipoId() {
      let identificacionTipo = this.user.identificacion_tipo;
      if (identificacionTipo === "Credencial para votar") {
        this.user.identificacion_autoridad = "Instituto Nacional Electoral";
      }
      // if (identificacionTipo === "IFE") {
      //   this.user.identificacion_autoridad = "Instituto Federal Electoral";
      // } else if (identificacionTipo === "INE") {
      //   this.user.identificacion_autoridad = "Instituto Nacional Electoral";
      // }
      else if (identificacionTipo === "Pasaporte") {
        this.user.identificacion_autoridad =
          "Secretaría de Relaciones Exteriores";
      } else if (identificacionTipo === "Cédula Profesional") {
        this.user.identificacion_autoridad = "Secretaría de Educación Pública";
      } else if (identificacionTipo === "Licencia de Conducir") {
        this.user.identificacion_autoridad = "Otro";
      } else if (identificacionTipo === "Forma Migratoria") {
        this.user.identificacion_autoridad = "Instituto Nacional de Migración";
      } else if (identificacionTipo === "Otro") {
        this.user.identificacion_autoridad = "Otro";
      }
    },

    fromConstitutiva() {
      const query = this.$route.query;
      let proceso = query.proceso;
      if (proceso === "addconstitucion" || proceso === "validconstitucion") {
        this.isFromConstitutiva = true;
      } else {
        this.isFromConstitutiva = false;
      }
    }
  }
};
</script>

<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
  /* position: fixed;
  right: 0;
  bottom: 0; */
  /* cursor: pointer;
  display: block;
  float: right;
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 5px; */
}

.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}

.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.bottom {
  position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100;
}

/* .foot {
  /* flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; */
/* position: relative;
  height: 200px;
  margin-top: -200px; */
/* position: sticky; */
/* top: 0; */
/* position: fixed; */
/* background-color: black; */
/* opacity: 0.9; */
/* height: 10vh;
  width: 20vw; */
/* overflow: visible;
  z-index: 100;
  right: 2%;
  bottom: 5%; */
/* } */
</style>
