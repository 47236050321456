<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar al accionista?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteAccionista">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="accionista-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAccionista)">
          <ModalCapitalAccionista :currentAccionista="currentAccionista" @submit.prevent
            @calcular="calcularCapitales" />
        </form>
      </ValidationObserver>
    </b-modal>

    <h4 class="cardD">Datos generales del Capital y de los Accionistas</h4>

    <div class="row mt-4">
      <div class="col-6">
        <ValidationProvider name="capital_fijo" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El capital fijo es obligatorio' }">
          <base-input :disabled="constitutiva.disabled" type="number" label="Monto de Capital Fijo" placeholder="$"
            v-model="constitutiva.capital_fijo" @keyup="calcularCapitales">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <!-- <div class="col-6" v-if="this.constitutiva.tiene_capital_variable">
        <ValidationProvider name="capital_variable" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El capital variable es obligatorio' }">
          <base-input :disabled="constitutiva.disabled" type="number" label="Monto de Capital Variable" placeholder="$"
            v-model="constitutiva.capital_variable" @keyup="calcularCapitales">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label for="">Hay Personas Físicas extranjeras</label>
        <b-form-checkbox v-model="constitutiva.capital_extranjero" :disabled="constitutiva.disabled">
        </b-form-checkbox>
        <label class="mt-2 leyenda" v-if="constitutiva.capital_extranjero">
          La cotización básica no incluye capital extranjero.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <label for="">Cuadro Accionario</label>
      <b-button :disabled="constitutiva.disabled" variant="success" class="btn-fill spaceL" size="sm"
        @click="addAccionista">+</b-button>
    </div>

    <div class="mt-4">
      <label for="">Seleccionar Comparecientes</label>
      <select class="custom-select" v-model="selectedCompareciente" @change="selectCompareciente"
        :disabled="constitutiva.disabled">
        <option v-for="option in options_comparecientes" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
    </div>

    <div>
      <b-table hover :fields="fieldsCuadro" :items="constitutiva.accionistas">
        <template #cell(acciones)="{ item }">
          <b-button :disabled="constitutiva.disabled" variant="warning" size="sm" v-b-tooltip.hover title="Editar"
            class="editBtn" @click="editAccionista(item)">
            <b-icon-pencil />
          </b-button>
          <b-button :disabled="constitutiva.disabled" variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
            @click="predeleteAccionista(item.index)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
      <label v-if="totalPorcentajes != null" for="">Total {{ totalPorcentajes }} %</label>
      <p></p>
      <ValidationProvider name="porcentaje_total" rules="cien" v-slot="{ errors }">
        <input type="text" v-model="totalPorcentajes" hidden />
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <!-- </div> -->

    <label class="mt-2 leyenda" v-if="constitutiva.accionistas.length > 2">
      La cotización aumentará de precio al agregar más de 2 accionistas personas físicas.
    </label>
    <!-- v-if="constitutiva.accionistas.length > 2" -->
    <label class="mt-2 leyenda" v-if="constitutiva.accionistas.length < 2">
      Se deben agregar mínimo 2 accionistas.
    </label>

    <div class="mt-4" v-if="constitutiva.accionistas.length < 2">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m" disabled>Continuar</b-button>
      </div>
    </div>

    <div class="mt-4" v-else>
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="constitutiva.disabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCapitalAccionista from "@/views/clients/procesos/constitucion/infoBase/modales/ModalCapitalAccionista.vue";

import { ClientesApi, ComparecientesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "DatosCapital",

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        // { key: "paterno", label: "Paterno", class: "text-center" },
        // { key: "materno", label: "Materno", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "porcentaje", label: "Porcentaje", class: "text-center" },
        { key: "capital_fijo", label: "Capital Fijo", class: "text-center" },
        // {
        //   key: "capital_variable",
        //   label: "Capital Variable",
        //   class: "text-center"
        // },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    }
  },

  created() {
    this.getComparecientes();
    this.calcularCapitales();
  },

  components: {
    ModalCapitalAccionista
  },

  props: {
    constitutiva: { type: Object }
  },

  data() {
    return {
      loading: false,
      comparecientes: [],
      options_comparecientes: [],
      selectedCompareciente: null,

      currentAccionistaId: null,
      currentAccionista: null,
      totalPorcentajes: null,

      form: {
        capital_fijo: null,
        capital_variable: null,
        accionistas: [],
        totalPorcentajesF: null,
        capital_extranjero: null
      }
    };
  },

  methods: {
    async getComparecientes() {
      this.loading = true;
      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);

      let clientes_id = 0;
      if (id != 0) {
        clientes_id = id;
      }

      try {
        // const { value } = await ComparecientesApi.myList(clientes_id, {
        //   page: 0,
        //   registersPerPage: 0,
        // });
        const { value } = await ComparecientesApi.combo({
          clientes_id: clientes_id
        });

        value.forEach(element => {
          let nombreTotal = "";
          if (element.persona === "Física") {
            nombreTotal = `${element.nombre} ${element.paterno} ${element.materno}`;
          } else {
            nombreTotal = element.denominacion_social;
          }
          const obj = {
            text: nombreTotal,
            value: element.id
          };
          this.options_comparecientes.push(obj);

          element.nombreCompleto = nombreTotal;
          element.persona = `Persona ${element.persona}`;
          element.rfc = element.rfc;

          // element.encargados = [];
        });
        this.comparecientes = value;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    selectCompareciente() {
      let indexYa = this.constitutiva.accionistas.findIndex(o => o.id === this.selectedCompareciente);
      // console.log(this.selectedCompareciente);
      // console.log(indexYa);
      if (indexYa != -1) {
        this.$toast.warning(
          "No se puede seleccionar 2 veces al mismo compareciente",
          {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          }
        );
        return;
      }

      let index = this.comparecientes.findIndex(
        o => o.id === this.selectedCompareciente
      );
      // console.log(this.comparecientes[index]);
      this.comparecientes[index].capital_fijo = 0;
      this.comparecientes[index].capital_variable = 0;
      this.comparecientes[index].porcentaje = 0;
      this.comparecientes[index].rfc = this.comparecientes[index].rfc;
      const a = Object.assign({}, this.comparecientes[index]);

      a.index = this.constitutiva.accionistas.length + 1;

      this.constitutiva.accionistas.push(a);

      // let index2 = this.options_comparecientes.findIndex(
      //   (o) => o.value === this.selectedCompareciente
      // );
      // this.selectedCompareciente = null;
      // this.options_comparecientes.splice(index2, 1);
    },

    calcularCapitales() {
      let totalPorcentajes = 0;
      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        let capital_fijo =
          (this.constitutiva.accionistas[i].porcentaje * this.constitutiva.capital_fijo) / 100;

        this.constitutiva.accionistas[i].capital_fijo = capital_fijo;

        let capital_variable =
          (this.constitutiva.accionistas[i].porcentaje *
            this.constitutiva.capital_variable) /
          100;
        this.constitutiva.accionistas[i].capital_variable = capital_variable;

        totalPorcentajes =
          totalPorcentajes +
          parseFloat(this.constitutiva.accionistas[i].porcentaje);

        var a = Object.assign({}, this.constitutiva.accionistas[i]);
        this.constitutiva.accionistas.splice([i], 1, a);
        // this.constitutiva.accionistas[i] = a;
      }
      this.totalPorcentajes = totalPorcentajes;
    },

    addAccionista() {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = {};
      this.currentAccionista.capital_fijo = 0;
      this.currentAccionista.capital_variable = 0;
    },

    editAccionista(item) {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = item;
    },

    putAccionista() {
      if (!this.currentAccionista.index) {
        let id = this.constitutiva.accionistas.length + 1;
        this.currentAccionista.index = id;

        let nombre = "";
        if (this.currentAccionista.persona === "Persona Física") {
          nombre = `${this.currentAccionista.nombre} ${this.currentAccionista.paterno
            } ${this.currentAccionista.materno}`;
        } else {
          nombre = `${this.currentAccionista.denominacion_social}`;
        }
        this.currentAccionista.nombreCompleto = nombre;
        this.currentAccionista.visible = 1;

        var a = Object.assign({}, this.currentAccionista);
        this.constitutiva.accionistas.push(a);
      } else {
        let index = this.constitutiva.accionistas.findIndex(
          o => o.id === this.currentAccionista.id
        );
        let nombre = "";
        if (this.currentAccionista.persona === "Persona Física") {
          nombre = `${this.currentAccionista.nombre} ${this.currentAccionista.paterno
            } ${this.currentAccionista.materno}`;
        } else {
          nombre = `${this.currentAccionista.denominacion_social}`;
        }
        this.currentAccionista.nombreCompleto = nombre;

        var a = Object.assign({}, this.currentAccionista);
        this.constitutiva.accionistas.splice([index], 1, a);
        // this.constitutiva.accionistas[index] = a;
      }
      this.calcularCapitales();
      this.$refs["accionista-modal"].hide();
    },

    predeleteAccionista(id) {
      this.currentAccionistaId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteAccionista() {
      let index2 = this.constitutiva.accionistas.findIndex(
        o => o.id === this.currentAccionistaId
      );
      this.constitutiva.accionistas.splice(index2, 1);

      // // //regresa a options
      // let index = this.comparecientes.findIndex(
      //   (o) => o.id === this.currentComparecienteId
      // );
      // const obj = {
      //   text: this.comparecientes[index].nombre_comercial,
      //   value: this.comparecientes[index].id,
      // };
      // this.options_comparecientes.push(obj);

      this.calcularCapitales();
      this.$refs["modal-confirm-dialog"].hide();
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.spaceL {
  margin-left: 1%;
}
</style>
