export default {
  data: () => ({
    loading: false,
  }),

  methods: {
    toggleOnLoading() {
      // console.log('toggleOnLoadingEvent from mixin');
      this.$emit('toggleOnLoadingEvent');
    },
    toggleOffLoading() {
      // console.log('toggleOffLoadingEvent from mixin');
      this.$emit('toggleOffLoadingEvent');
    },
    toggleOnLoadingHandler() {
      // console.log("toggle On from mixin");
      this.loading = true;
    },
    toggleOffLoadingHandler() {
      // console.log("toggle Off from mixin");
      this.loading = false;
    },
  },
};