<template>
  <div class="container">
    <h4 class="cardD">Asignar Correduría</h4>

    <div class="mt-5 mb-4"><label for="">Ubicación del cliente</label></div>

    <div class="row ">
      <div class="col-3">
        <label>Entidad federativa</label>
      </div>
      <div class="col-9">
        {{ entidad }}
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-3">
        <label>Municipio</label>
      </div>
      <div class="col-9">
        {{ municipio }}
      </div>
    </div>

    <label>Correduría</label>
    <select class="custom-select" v-model="selectedCorreduria">
      <option v-for="option in options_correduria" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <b-button @click="selectCorreduria()" class="btn-fill orange-btn bottom2" size="m">Asignar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "TabAsignarCorreduria",

  props: {
    ratificacion: { type: Object }
  },

  mixins: [loadingMixin],

  created() {
    this.selectedCorreduria = this.ratificacion.corredurias_id;
    this.getCorredurias();
  },

  data() {
    return {
      entidad: null,
      municipio: null,
      options_correduria: [],
      selectedCorreduria: 1
    };
  },

  methods: {
    async getCorredurias() {
      try {
        this.toggleOnLoading();

        this.entidad = this.ratificacion.domicilio.entidad;
        if (this.entidad != "Ciudad de México") {
          this.municipio = this.ratificacion.domicilio.delegacion_municipio;
        }
        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: null,
          municipio: null
        });

        value.forEach(element => {
          let exists = this.options_correduria.findIndex(x => x.text === element.entidad);

          if (exists == -1) {
            const obj = {
              text: `${element.entidad}, ${element.delegacion_municipio}, ${element.colonia}`,
              value: element.corredurias_id
            };

            this.options_correduria.push(obj);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.toggleOffLoading();
      }
    },

    selectCorreduria() {
      this.ratificacion.corredurias_id = this.selectedCorreduria;
      this.$emit('allChecksPassed');
    }
  }
};
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}
</style>
