<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="l">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putDocumento)">
          <ModalDocumento :currentDocumento="currentDocumento" :optionsDocumentos="optionsDocumentos" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los Documentos</h4>

    <div class="mt-4">
      <!-- <div class="col-12 mt-4"> -->
      <label for="">Agregar documento</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="addDocumento">+</b-button>
    </div>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="cotejo.documentos">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            @click="editDocumento(item)">
            <b-icon-pencil />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox v-model="urgencia" :disabled="urgenciaNoAplica">
            Los documentos se requieren de urgencia
          </b-form-checkbox>
          <span v-if="urgencia" class="cardErrors">
            Se generarán cargos adicionales y no se pueden garantizar los tiempos de entrega.
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox v-model="envioDomicilio" :disabled="urgencia">
            Envío a domicilio
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkDocumentos()">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumento from "@/views/clients/procesos/cotejo/infoBase/modales/ModalDocumento.vue";

import { CatDocumentosApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "Documentos",

  components: { ModalDocumento },

  props: {
    cotejo: { type: Object },
  },

  mixins: [loadingMixin],

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "paginas", label: "No. Páginas", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    },

    urgenciaNoAplica() {
      if (this.envioDomicilio) {
        return true;
      }
      let countTieneAnexo = 0;
      for (let i = 0; i < this.cotejo.documentos.length; i++) {
        const documento = this.cotejo.documentos[i];
        if (!documento.hasOwnProperty('anexoDe')) {
          countTieneAnexo++;
          if (parseInt(documento.paginas) > 150 || parseInt(documento.paginas) + parseInt(documento.paginas_anexo) > 150) {
            return true;
          }
        }
      }
      return countTieneAnexo > 5;
    }
  },

  async created() {
    parseInt(this.cotejo.id_prioridad) == 2 ? this.urgencia = false : this.urgencia = true;
    parseInt(this.cotejo.envio_domicilio) == 1 ? this.envioDomicilio = true : this.envioDomicilio = false;
    await this.getCatDocumentosCotejo();
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,

      optionsDocumentos: [],
      id_anexo: 0,

      urgencia: false,
      envioDomicilio: false
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getCatDocumentosCotejo() {
      try {
        this.toggleOnLoading();
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "cotejos"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsDocumentos.push(obj);
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.toggleOffLoading();
      }
    },

    getIdAnexo() {
      this.optionsDocumentos.forEach(element => {
        if (element.text === 'Anexo') {
          this.id_anexo = element.value;
        }
      })
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    putDocumento() {
      if (this.currentDocumento.descripcion) {
        this.currentDocumento.tipo = this.currentDocumento.descripcion;
      } else {
        this.optionsDocumentos.forEach(element => {
          if (element.value === this.currentDocumento.catDocumentos_id) {
            this.currentDocumento.tipo = element.text;
          }
        })
      }
      if (!this.currentDocumento.id) {
        let id = this.cotejo.documentos.length + 1;
        this.currentDocumento.id = id;

        if (this.currentDocumento.urgencia) {
          this.cotejo.id_prioridad = 1;
        }
        else {
          this.cotejo.DocSinUrgencia = true;
        }

        this.cotejo.documentos.push(this.currentDocumento);
      }
      let index = this.cotejo.documentos.findIndex(
        (o) => o.id === this.currentDocumento.id
      );
      this.cotejo.documentos[index].tipo = this.currentDocumento.tipo;
      if (this.currentDocumento.tiene_anexo) {
        this.addAnexo(this.currentDocumento, index + 1);

      } else {
        if (this.cotejo.documentos[index + 1]) {
          if (this.cotejo.documentos[index + 1].anexoDe) {
            this.cotejo.documentos.splice(index + 1, 1);
          }
        }
      }
      this.$refs.table.refresh();
      this.$refs["documento-modal"].hide();
    },

    editDocumento(item) {
      let documentoPadre = item;
      if (item.anexoDe) {
        this.cotejo.documentos.forEach(documento => {
          if (documento.id == item.anexoDe) {
            documentoPadre = documento;
          }
        })
      }
      item = documentoPadre;
      this.$refs["documento-modal"].show();
      this.currentDocumento = item;
    },

    addAnexo(documento, index_to_slice) {
      let doc_urgencia = false
      if (documento.urgencia) {
        doc_urgencia = true
      }
      if (this.cotejo.documentos[index_to_slice]) {
        if (this.cotejo.documentos[index_to_slice].anexoDe) {
          this.cotejo.documentos.splice(index_to_slice, 1);
        }
      }
      const currentDocumento = {
        catDocumentos_id: this.id_anexo,
        paginas: documento.paginas_anexo,
        urgencia: doc_urgencia,
        tipo: "Anexo",
        anexoDe: documento.id
      };
      this.cotejo.documentos.splice(index_to_slice, 0, currentDocumento);
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteDocumento() {
      let index = this.cotejo.documentos.findIndex(
        (o) => o.id === this.currentDocumentoId
      );
      if (this.cotejo.documentos[index].anexoDe) {
        this.cotejo.documentos.forEach(documento => {
          if (documento.id == this.cotejo.documentos[index].anexoDe) {
            documento.tiene_anexo = false;
          }
        })
      }
      if (this.cotejo.documentos[index + 1]) {
        if (this.cotejo.documentos[index + 1].anexoDe) {
          this.cotejo.documentos.splice(index + 1, 1);
        }
      }
      this.cotejo.documentos.splice(index, 1);

      this.$refs["modal-confirm-dialog"].hide();
      this.$refs.table.refresh();
    },

    checkDocumentos() {
      if (this.cotejo.documentos.length == 0) {
        this.showErrorToast('Debe ingresar al menos 1 documento.');
        return;
      }
      this.urgencia ? this.cotejo.id_prioridad = 1 : this.cotejo.id_prioridad = 2;
      this.envioDomicilio ? this.cotejo.envio_domicilio = 1 : this.cotejo.envio_domicilio = 0;
      this.$emit('allChecksPassed');
    }
  },

}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
