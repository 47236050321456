<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">
        Inicio
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Nueva Firma Digital de Documentos</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-cotizacion" hide-footer id="modal-confirm-cotizacion">
      <h4 class="text-center">
        ¿Seguro que quiere continuar al pago?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-cotizacion')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaRatificacion()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(payCotizacion)">
          <Openpay :cotizacion_id="ratificacion.id_cotizaciones" :precio_total="ratificacion.precio_total"
            @submit.prevent @payCotizacion="payCotizacion($event)" />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <card>
            <TreeRatificacionesDigital :ratificacion="ratificacion" :user="'Client'" @node="selected" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Comparecientes'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="comparecientes" @submit.prevent="handleSubmit(changeCurrentComponentBody('Montos'))">
                      <Ratificantes :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="submitForm('comparecientes')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Montos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="montos" @submit.prevent="handleSubmit(changeCurrentComponentBody('Documentos'))">
                      <Montos :ratificacion="ratificacion" @submit.prevent @allChecksPassed="submitForm('montos')"
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Documentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="documentos" @submit.prevent="handleSubmit(changeCurrentComponentBody('Cotizacion'))">
                      <Documentos :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="submitForm('documentos')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="cotizacion" @submit.prevent="handleSubmit(confirmaCotizacion())">
                      <Cotizacion :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="submitForm('cotizacion')" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import Card from "src/components/Cards/Card.vue";
// Ratificaciones
import TreeRatificacionesDigital from "@/views/clients/procesos/ratificacionesdigital/TreeRatificacionesDigital.vue";
import Cotizacion from "@/views/clients/procesos/ratificacionesdigital/infoBase/Cotizacion.vue";
import Documentos from "@/views/clients/procesos/ratificacionesdigital/infoBase/Documentos.vue";
import Montos from "@/views/clients/procesos/ratificacionesdigital/infoBase/Montos.vue";
import Ratificantes from "@/views/clients/procesos/ratificacionesdigital/infoBase/Ratificantes.vue";
// Openpay
// API
import { ClientesApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi, RatificacionesApi, RatificantesApi } from "@/api";
// Usuario
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "AddRatificacionesDigital",

  components: {
    Card,
    TreeRatificacionesDigital,
    Ratificantes,
    Montos,
    Documentos,
    Cotizacion
  },

  created() {
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'EditRatificaciones') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentBody: "Comparecientes",
    cliente_id: 0,

    ratificacion: {
      // Objeto creado en BD
      ratificaciones_id: null,
      estatus_id: null,
      cotizaciones_id: null,
      monto_global: 0,
      clausula_adeudo: 0,
      adeudo: 0,
      inscripcion_rug: 0,
      monto_garantia: 0,
      entregables_adicionales: 0,
      anexos_certificados: 0,

      // Valores de Tree
      currentComponentValues: {
        Comparecientes: false,
        Montos: false,
        Documentos: false,
        Cotizacion: false
      },

      // Ratificantes
      ratificantes: [],

      // Documentos
      documentos: [],

      // Extras
      firma_fuera_oficina: 0,
      entrega_fuera_oficina: 0,
      fad_instrumento: 0,

      // Totales cotizacion
      costo_total: 0,

      // Cotizacion
      cotizacion: {},
    },

    comparecientes: [],
  }),

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.ratificacion.currentComponentValues);
      const values = Object.values(this.ratificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      const currentIndex = keys.findIndex(value => value == this.currentComponentBody);
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.ratificacion.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if ((destIndex - lastCompletedIndex) === 1) {
            this.currentComponentBody = dest;
          }
          else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0]
            }
            this.$toast.warning("Completa la sección " + lastCompleted + " para poder continuar", {
              timeout: 3000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true,
            });
          }
        }
      }
      // Ir mas atras
      else {
        this.currentComponentBody = dest;
      }
    },

    changeCurrentComponentBody(body) {
      this.ratificacion.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },

    submitForm(formToSubmit) {
      this.$refs[formToSubmit].dispatchEvent(new Event('submit'));
    },


    confirmaCotizacion() {
      this.$refs["modal-confirm-cotizacion"].show();
    },

    generaRatificacion() {
      console.log(this.ratificacion);
      this.loading = true;
      this.getClienteId()
        .then(() => this.postRatificacion())
        .then(() => this.modifyCotizacion())
        .then(() => this.postRatificantes())
        .then(() => this.addDocumentos())
        .then(() => this.addAnexos())
        .then(() => this.moveToEditRatificaciones())
        .catch((error) => {
          console.log(error);
          this.showErrorToast('No se pudieron obtener los datos. Intente más tarde.')
        })
        .finally(() => { this.loading = false; })
    },

    async getClienteId() {
      var clienteEmail = storage.getItem("user");
      const { id } = await ClientesApi.clienteId(clienteEmail);
      this.cliente_id = id;
      return;
    },

    async postRatificacion() {
      console.log('Postea Ratificacion')
      const { ratificaciones_id, cotizaciones_id } = await RatificacionesApi.create({
        clientes_id: this.cliente_id,
        proceso_digital: 1,
        monto_global: parseFloat(this.ratificacion.monto_global),
        clausula_adeudo: this.ratificacion.clausula_adeudo,
        adeudo: parseFloat(this.ratificacion.adeudo),
        inscripcion_rug: this.ratificacion.inscripcion_rug,
        monto_garantia: parseFloat(this.ratificacion.monto_garantia),
        firma_fuera_oficina: 0,
        entrega_fuera_oficina: this.ratificacion.entrega_fuera_oficina,
        fad_instrumento: 0,
        entregables_adicionales: parseInt(this.ratificacion.entregables_adicionales),
        anexos_certificados: this.ratificacion.anexos_certificados,
        corredurias_id: 1,
      });
      this.ratificacion.ratificaciones_id = ratificaciones_id;
      this.ratificacion.cotizaciones_id = cotizaciones_id;
      console.log('Ratificacion Posteada')
      return;
    },

    async modifyCotizacion() {
      console.log('Modifica Cotizacion')
      return await CotizacionesApi.edit(this.ratificacion.cotizaciones_id, {
        precio_total: this.ratificacion.costo_total,
        activo: 1
      });
    },

    async postRatificantes() {
      console.log('Postea Ratificantes')
      this.ratificacion.ratificantes.forEach(ratificante => {
        if (!('cliente_id' in ratificante)) {
          ratificante.cliente_id = this.cliente_id;
        }
        ratificante.ratificaciones_id = this.ratificacion.ratificaciones_id;
        ratificante.ratificantes_id = ratificante.id;
      })
      return await RatificantesApi.postClonados({
        ratificantes: this.ratificacion.ratificantes
      })
    },

    async addDocumentos() {
      console.log('Agrega docs principales')
      const promises = this.ratificacion.documentos.map(async documento => {
        const documento_id = await this.postDocumento(
          47, null, null, parseInt(documento.paginas), documento.nombre
        )
        documento.documento_id = documento_id;
      })
      await Promise.all(promises);
      console.log('Documentos principales Agregados')
      return;
    },

    async addAnexos() {
      const promises = []; // to store all promises

      this.ratificacion.documentos.forEach(documento => {
        if (documento.tiene_anexo) {
          if (documento.numero_anexos > 0) {
            for (let i = 0; i < documento.numero_anexos; i++) {
              console.log('Agrega anexo')
              promises.push(this.postDocumento(
                48, documento.documento_id, null, parseInt(documento.paginas_anexos[i]), `Anexo ${i + 1}`
              ));
            }
          }
        }
        if (documento.digital) {
          console.log('Agrega anexo digital')
          promises.push(this.postDocumento(
            50, documento.documento_id, null, 0, 'Contenido Digital'
          ));
        }
      })

      await Promise.all(promises);
      return;
    },

    async postDocumento(cat_documentos_id, auto_referencia, descripcion, paginas, nombre) {
      const { id } = await DocumentosApi.create({
        documento: "",
        cat_documentos_id: cat_documentos_id,
        auto_referencia: auto_referencia,
        descripcion: descripcion,
        paginas: paginas,
        copias_certificadas: null,
        nombre: nombre
      })
      await DocumentosXServiciosApi.create({
        documentos_id: id,
        cat_servicios_id: 6,
        proceso_id: this.ratificacion.ratificaciones_id
      })
      return id;
    },

    moveToEditRatificaciones() {
      this.$router.push({
        path: `/dashboard/clienttasks/editratificaciondigital/${this.ratificacion.ratificaciones_id}`,
      })
      return;
    }
  }
};
</script>

<style scoped></style>
