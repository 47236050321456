<template>
  <div class="container">
    <h4 class="cardD">Cotización</h4>

    <h4 class="cardD mt-1" v-if="subtotal_montos > 0">Resumen de Montos</h4>

    <div class="row" v-if="subtotal_monto_global > 0">
      <label class="grey col-md-1"></label>
      <label class="grey col-md-5"> Costo por Monto Global</label>
      <label class="grey col-md-6">${{ subtotal_monto_global }}</label>
    </div>
    <div class="row" v-if="ratificacion.inscripcion_rug == 1">
      <label class="grey col-md-1">✓</label>
      <label class="grey col-md-5"> Inscripción en RUG</label>
      <label class="grey col-md-6">${{ precio_inscripcion_rug }}</label>
    </div>
    <div class="row mt-2" v-if="subtotal_montos > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_montos }}</label>
    </div>

    <h4 class="cardD mt-1" v-if="subtotal_documentos > 0">Documentos</h4>
    <div class="row" v-if="documentos_base > 0">
      <label class="grey col-md-1">{{ documentos_base }}</label>
      <label class="grey col-md-5">Documentos Base</label>
      <label class="grey col-md-6">${{ documentos_base * precio_documento_base }}</label>
    </div>
    <div class="row" v-if="contenido_digital > 0">
      <label class="grey col-md-1">{{ contenido_digital }}</label>
      <label class="grey col-md-5">Documentos con Contenido Digital</label>
      <label class="grey col-md-6">${{ contenido_digital * precio_contenido_digital }}</label>
    </div>
    <div class="row" v-if="paginas_extra > 0">
      <label class="grey col-md-1">{{ paginas_extra }}</label>
      <label class="grey col-md-5">Páginas Adicionales</label>
      <label class="grey col-md-6">${{ paginas_extra * precio_pagina_adicional }}</label>
    </div>
    <div class="row mt-2" v-if="subtotal_documentos > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_documentos }}</label>
    </div>

    <h4 class="cardD mt-1" v-if="subtotal_comparecientes > 0">Comparecientes</h4>
    <div class="row" v-if="comparecientes_fisicas_extra > 0">
      <label class="grey col-md-1">{{ comparecientes_fisicas_extra }}</label>
      <label class="grey col-md-5">Personas Físicas Adicionales</label>
      <label class="grey col-md-6">${{ comparecientes_fisicas_extra * precio_adicional_persona_fisica }}</label>
    </div>
    <div class="row" v-if="comparecientes_morales_extra > 0">
      <label class="grey col-md-1">{{ comparecientes_morales_extra }}</label>
      <label class="grey col-md-5">Personas Morales Adicionales</label>
      <label class="grey col-md-6">${{ comparecientes_morales_extra * precio_adicional_persona_moral }}</label>
    </div>
    <div class="row mt-2" v-if="subtotal_comparecientes > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_comparecientes }}</label>
    </div>

    <div class="row mt-1">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">${{ total_previo_descuento }}</h4>
      </div>
    </div>

    <hr>

    <h4 class="cardD">Agregar Extras</h4>

    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox id="chkEntregablesAdicionales" v-model="entregablesAdicionales">
            <label for="chkEntregablesAdicionales" class="control-label validacionDatosLabel">
              Ejemplares adicionales del documento certificado (Se incluyen 2)
            </label>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-6" v-if="entregablesAdicionales">
      <div class="col-md-12">
        <ValidationProvider name="entregables" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El monto es obligatorio',
        }">
          <label for="">Cantidad de Entregables Adicionales</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="ratificacion.entregables_adicionales">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox id="chkAnexosCertificados" v-model="anexosCertificados">
            <label for="chkAnexosCertificados" class="control-label validacionDatosLabel">
              Agregar Anexos Certificados
            </label>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-4" v-if="parseInt(paginas_anexos) > 0"> -->
    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox id="chkFirmaFuera" v-model="firmaFuera">
            <label for="chkFirmaFuera" class="control-label validacionDatosLabel">
              Firma Fuera de la Oficina (No se recaban firmas en domicilios particulares, únicamente en oficinas
              corporativas)
            </label>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-6" v-if="firmaFuera">
      <div class="col-md-12">
        <ValidationProvider name="firmas-fuera" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El número es obligatorio' }">
          <label for="input-firmas-fuera">Número de firmas fuera de la oficina</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="cantidad_firmas_fuera_oficina">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div style="display: flex; align-items: center;">
          <b-form-checkbox id="chkEntregaFuera" v-model="entregaFuera"></b-form-checkbox>
          <label for="chkEntregaFuera" class="control-label validacionDatosLabel">
            Envío a domicilio del entregable
          </label>
        </div>
      </div>
    </div>

    <hr v-if="subtotal_extras > 0">

    <h4 class="cardD" v-if="subtotal_extras > 0">Extras</h4>

    <div class="row" v-if="entregablesAdicionales && parseInt(ratificacion.entregables_adicionales) > 0">
      <label class="grey col-md-1">{{ ratificacion.entregables_adicionales }}</label>
      <label class="grey col-md-5">Entregables Adicionales</label>
      <label class="grey col-md-6">${{ ratificacion.entregables_adicionales * precio_entregable_adicional }}</label>
    </div>
    <div class="row" v-if="anexosCertificados && parseInt(paginas_anexos) > 0">
      <label class="grey col-md-1">{{ paginas_anexos }}</label>
      <label class="grey col-md-5">Paginas de Anexos</label>
      <label class="grey col-md-6">${{ paginas_anexos * precio_pagina_anexo_certificado }}</label>
    </div>
    <div class="row" v-if="firmaFuera">
      <label class="grey col-md-1"></label>
      <label class="grey col-md-5">Firma Fuera de la Oficina</label>
      <label class="grey col-md-6">${{ precio_firma_fuera_oficina }}</label>
    </div>
    <div class="row" v-if="entregaFuera">
      <label class="grey col-md-1"></label>
      <label class="grey col-md-5">Envío a domicilio del entregable</label>
      <label class="grey col-md-6">$500</label>
    </div>

    <div class="row mt-2" v-if="subtotal_extras > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_extras }}</label>
    </div>

    <h4 class="cardD mt-1" v-if="entregaFuera">Descuentos</h4>
    <div class="row" v-if="entregaFuera">
      <label class="grey col-md-1"></label>
      <!-- <label class="grey col-md-5"> Envío a domicilio del entregable </label> -->
      <label class="grey col-md-5">Envío a domicilio del entregable</label>
      <label class="grey col-md-6"> - $500</label>
    </div>
    <div class="row mt-2" v-if="entregaFuera">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">- $500</label>
    </div>

    <h4 class="cardD mt-1">Cupones</h4>
    <div class="row">
      <div class="col-12">
        <base-input type="text" label="Si cuenta con un cupón puede aplicarlo." placeholder="Cupón de descuento"
          :disabled="cuponAplicado" v-model="nombreCuponDescuento"></base-input>
      </div>
      <div class="col-12 d-flex">
        <br />
        <b-button class="orange-btn" size="m" :disabled="cuponAplicado"
          @click="validarCupon(nombreCuponDescuento)">Aplicar</b-button>
      </div>
    </div>

    <div class="row mt-1"
      v-if="total_post_descuento || total_post_descuento > total_previo_descuento || this.entregaFuera">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">${{ total_post_descuento }}</h4>
      </div>
    </div>

    <hr>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionTerminos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria'
          }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox id="chkValidacionTerminos" v-model="validacionTerminos">
              <label for="chkValidacionTerminos" class="control-label validacionDatosLabel"> Acepto los Términos y
                condiciones. </label>
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionPaginas" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria'
          }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox id="chkValidacionPaginas" v-model="validacionPaginas">
              <label for="chkValidacionPaginas" class="control-label validacionPaginasLabel"> Declaro bajo protesta de
                decir la verdad, que el volumen de páginas corresponde a la totalidad de las páginas que conforman los
                documentos COMPLETOS. </label>
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionMontos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria'
          }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox id="chkValidacionMontos" v-model="validacionMontos">
              <label for="chkValidacionMontos" class="control-label validacionMontosLabel"> Declaro bajo protesta de
                decir la verdad, que los montos indicados son correctos. </label>
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" :disabled="isAbleToPay()"
          @click="submitCotizacion()">Pagar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { CatGastosApi, CuponesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "Cotizacion",

  props: {
    ratificacion: { type: Object }
  },

  mixins: [loadingMixin],

  created() {
    this.toggleOnLoading();
    this.getGastos()
      .then(() => this.calculaMontoGlobal())
      .then(() => this.calculaSubtotalMontos())
      .then(() => this.cuentaDocumentosBase())
      .then(() => this.cuentaPaginasDocumentos())
      .then(() => this.calculaPaginasExtra())
      .then(() => this.cuentaContenidoDigital())
      .then(() => this.calculaSubtotalDocumentos())
      .then(() => this.cuentaComparecientes())
      .then(() => this.calculaSubtotalComparecientes())
      .then(() => this.calculaTotal())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los totales. Intente más tarde.')
      })
      .finally(() => { this.toggleOffLoading(); })
  },

  data() {
    return {
      // Costos en BD
      cat_gastos: null,
      precio_documento_base: 0,
      precio_pagina_adicional: 0,
      precio_contenido_digital: 0,
      precio_inscripcion_rug: 0,
      precio_adicional_persona_fisica: 0,
      precio_adicional_persona_moral: 0,
      precio_entregable_adicional: 0,
      precio_pagina_anexo_certificado: 0,
      precio_firma_fuera_oficina: 0,

      // Etapas monto global
      monto_max_por_etapa: [1000000, 5000000, 10000000, 25000000, 50000000, 75000000],
      factor_por_etapa: [0, 0.00075, 0.00065, 0.00055, 0.00050, 0.00045],
      max_por_etapa: [0, 3000, 3250, 8250, 12500, 11250],

      // Cantidades
      documentos_base: 0,
      paginas_totales: 0,
      paginas_extra: 0,
      contenido_digital: 0,
      comparecientes_fisicas: 0,
      comparecientes_morales: 0,
      comparecientes_fisicas_extra: 0,
      comparecientes_morales_extra: 0,
      paginas_anexos: 0,

      // Subtotales
      subtotal_monto_global: 0,
      subtotal_montos: 0,
      subtotal_documentos: 0,
      subtotal_comparecientes: 0,
      subtotal_extras: 0,

      // Totales
      total_previo_descuento: 0,
      total_post_descuento: 0,

      // Casillas de validacion
      validacionTerminos: false,
      validacionPaginas: false,
      validacionMontos: false,

      // Extras
      entregablesAdicionales: false,
      anexosCertificados: false,
      firmaFuera: false,
      entregaFuera: false,

      // Cupones
      cuponAplicado: false,
      nombreCuponDescuento: "",

      // # firmas fuera
      cantidad_firmas_fuera_oficina: 0
    }
  },

  watch: {
    'entregablesAdicionales': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    'ratificacion.entregables_adicionales': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    'anexosCertificados': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    'firmaFuera': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    'entregaFuera': {
      handler(newValue) {
        this.recalculaExtras();
      },
      immediate: true,
    },
    "cantidad_firmas_fuera_oficina": {
      handler() {
        this.recalculaExtras();
      },
      immediate: true
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    isAbleToPay() {
      return !(this.validacionTerminos && this.validacionPaginas && this.validacionMontos);
    },

    async getGastos() {
      const { value } = await CatGastosApi.filtraProcesos({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: "6"
      });
      this.cat_gastos = value;
      for (const gasto in this.cat_gastos) {
        if (this.cat_gastos[gasto]['clave'] === 'documento_base') {
          this.precio_documento_base = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'pagina_adicional') {
          this.precio_pagina_adicional = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'contenido_digital') {
          this.precio_contenido_digital = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'inscripcion_rug') {
          this.precio_inscripcion_rug = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'adicional_persona_fisica') {
          this.precio_adicional_persona_fisica = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'adicional_persona_moral') {
          this.precio_adicional_persona_moral = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'entregable_adicional') {
          this.precio_entregable_adicional = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'pagina_anexo_certificado') {
          this.precio_pagina_anexo_certificado = this.cat_gastos[gasto]['costo'];
        }
        if (this.cat_gastos[gasto]['clave'] === 'firma_fuera_oficina') {
          this.precio_firma_fuera_oficina = this.cat_gastos[gasto]['costo'];
        }
      }
      return;
    },

    calculaMontoGlobal() {
      this.subtotal_monto_global = this.precio_acumulativo(this.ratificacion.monto_global);
    },

    precio_acumulativo(monto_global) {
      const etapa_actual = this.identificar_etapa(monto_global);
      // console.log(etapa_actual);
      // console.log(monto_global);
      if (etapa_actual == null) {
        this.showErrorToast('El monto global ingresado está fuera de los límites establecidos')
        return;
      }
      let precio_acumulado = 0;
      for (let etapa_anterior = 0; etapa_anterior < etapa_actual; etapa_anterior++) {
        precio_acumulado += this.precio_por_etapa(this.monto_max_por_etapa[etapa_anterior], etapa_anterior);
      }
      precio_acumulado += this.precio_por_etapa(monto_global, etapa_actual);
      return precio_acumulado;
    },

    identificar_etapa(monto_global) {
      let etapa = 0;
      for (let i = 0; this.monto_max_por_etapa.length; i++) {
        let monto_min = -1;
        if (i > 0) {
          monto_min = this.monto_max_por_etapa[i - 1];
        }
        const monto_max = this.monto_max_por_etapa[i]
        if (monto_global > monto_min && monto_global <= monto_max) {
          etapa = i;
          return etapa;
        }
      }
      return null;
    },

    precio_por_etapa(monto_global, etapa_actual) {
      let monto_max_etapa_anterior = 0;
      if (etapa_actual > 1) {
        monto_max_etapa_anterior = this.monto_max_por_etapa[etapa_actual - 1];
      }
      const diferencia_monto = monto_global - monto_max_etapa_anterior;
      const precio = Math.min(this.max_por_etapa[etapa_actual], diferencia_monto * this.factor_por_etapa[etapa_actual]);
      return precio;
    },

    calculaSubtotalMontos() {
      this.subtotal_montos += this.subtotal_monto_global;
      if (this.ratificacion.inscripcion_rug == 1) {
        this.subtotal_montos += this.precio_inscripcion_rug;
      }
      return;
    },

    cuentaDocumentosBase() {
      this.documentos_base = this.ratificacion.documentos.length;
      return;
    },

    cuentaPaginasDocumentos() {
      let paginas = 0;
      this.ratificacion.documentos.forEach(documento => {
        paginas += parseInt(documento.paginas);
        if (documento.numero_anexos > 0) {
          for (let i = 0; i < documento.numero_anexos; i++) {
            paginas += parseInt(documento.paginas_anexos[i]);
            this.paginas_anexos += parseInt(documento.paginas_anexos[i]);
          }
        }
      })
      this.paginas_totales = paginas;
      return;
    },

    calculaPaginasExtra() {
      const paginas_incluidas = this.documentos_base * 50;
      this.paginas_extra = Math.max(this.paginas_totales - paginas_incluidas, 0);
      return;
    },

    cuentaContenidoDigital() {
      this.ratificacion.documentos.forEach(documento => {
        if (documento.digital) {
          this.contenido_digital += 1
        }
      })
      return;
    },

    calculaSubtotalDocumentos() {
      this.subtotal_documentos += this.documentos_base * this.precio_documento_base;
      this.subtotal_documentos += this.paginas_extra * this.precio_pagina_adicional;
      this.subtotal_documentos += this.contenido_digital * this.precio_contenido_digital;
      return;
    },

    cuentaComparecientes() {
      this.ratificacion.ratificantes.forEach(ratificante => {
        if (ratificante.principal || ratificante.persona == "Moral") {
          if (ratificante.persona == "Moral") {
            this.comparecientes_morales += 1;
          } else {
            this.comparecientes_fisicas += 1;
          }
        }
      })
      return;
    },

    calculaSubtotalComparecientes() {
      const comparecientes_totales = this.comparecientes_fisicas + this.comparecientes_morales;
      if (comparecientes_totales <= 2) {
        return;
      }
      let comparecientes_incluidos = 0;
      for (let i = 1; i <= this.comparecientes_morales; i++) {
        if (comparecientes_incluidos < 2) {
          comparecientes_incluidos += 1;
        } else {
          this.comparecientes_morales_extra += 1;
        }
      }
      for (let i = 1; i <= this.comparecientes_fisicas; i++) {
        if (comparecientes_incluidos < 2) {
          comparecientes_incluidos += 1;
        } else {
          this.comparecientes_fisicas_extra += 1;
        }
      }
      this.subtotal_comparecientes += this.comparecientes_fisicas_extra * this.precio_adicional_persona_fisica;
      this.subtotal_comparecientes += this.comparecientes_morales_extra * this.precio_adicional_persona_moral;
    },

    calculaTotal() {
      this.total_previo_descuento += this.subtotal_documentos + this.subtotal_montos + this.subtotal_comparecientes;
      return;
    },

    recalculaExtras() {
      this.calculaEntregablesAdicionales()
        .then(() => this.calculaAnexosCertificados())
        .then(() => this.agregaFirmaFueraOficina())
        .then(() => this.agregaEntregaFueraOficina())
        .then(() => this.calculaTotalpostDescuento())
        .catch((error) => {
          console.log(error);
          this.showErrorToast('No se pudieron obtener los totales. Intente más tarde.')
        })
        .finally(() => { })
    },

    calculaEntregablesAdicionales() {
      return new Promise((resolve, reject) => {
        try {
          this.subtotal_extras = 0;
          if (!this.entregablesAdicionales) {
            this.ratificacion.entregables_adicionales = 0;
          }
          if (this.entregablesAdicionales && parseInt(this.ratificacion.entregables_adicionales) > 0) {
            this.subtotal_extras += this.ratificacion.entregables_adicionales * this.precio_entregable_adicional;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    calculaAnexosCertificados() {
      return new Promise((resolve, reject) => {
        try {
          if (!this.entregablesAdicionales) {
            this.subtotal_extras = 0;
          }
          if (this.anexosCertificados) {
            this.subtotal_extras += this.paginas_anexos * this.precio_pagina_anexo_certificado;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    agregaFirmaFueraOficina() {
      return new Promise((resolve, reject) => {
        try {
          if (!this.entregablesAdicionales && !this.anexosCertificados) {
            this.subtotal_extras = 0;
          }
          if (this.firmaFuera) {
            this.subtotal_extras += this.precio_firma_fuera_oficina * this.cantidad_firmas_fuera_oficina;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    agregaEntregaFueraOficina() {
      return new Promise((resolve, reject) => {
        try {
          if (!this.entregablesAdicionales && !this.anexosCertificados && !this.firmaFuera) {
            this.subtotal_extras = 0;
          }
          if (this.entregaFuera) {
            this.subtotal_extras += 500;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    calculaTotalpostDescuento() {
      return new Promise((resolve, reject) => {
        try {
          this.total_post_descuento = this.total_previo_descuento;
          if (this.subtotal_extras > 0) {
            this.total_post_descuento += this.subtotal_extras;
          }
          if (this.entregaFuera) {
            this.total_post_descuento = this.total_post_descuento - 500;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    submitCotizacion() {
      if (this.firmaFuera) {
        this.ratificacion.firma_fuera_oficina = 1;
      }
      if (this.entregaFuera) {
        this.ratificacion.entrega_fuera_oficina = 1;
      }
      if (this.anexosCertificados) {
        this.ratificacion.anexos_certificados = 1;
      }

      if (this.total_post_descuento > this.total_previo_descuento) {
        this.ratificacion.costo_total = this.total_post_descuento;
      } else {
        this.ratificacion.costo_total = this.total_previo_descuento;
      }

      this.$emit('allChecksPassed');
    },

    async validarCupon(texto_cupon) {
      try {
        this.toggleOnLoading();
        const time_zone = -(new Date().getTimezoneOffset() / 60);
        const data = {
          nombre: texto_cupon,
          timeZone: time_zone
        };
        const { id, isValid, tipo, descuento } = await CuponesApi.cuponValido(data);

        if (!isValid) {
          this.$toast.warning("El cupón no es válido", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        let total = 0;

        if (this.total_post_descuento > 0) {
          total = this.total_post_descuento;
        } else if (this.total_previo_descuento > 0) {
          total = this.total_previo_descuento;
        }

        let precio_total = 0;

        if (tipo === "Porcentaje" && descuento < 1) {
          let descuentoDecimal = descuento;
          if (descuento > 1) {
            descuentoDecimal = descuento / 100;
          }
          precio_total = total * (1 - descuentoDecimal);
        } else if (tipo == "Monto") {
          precio_total = total - descuento;
        }

        this.total_post_descuento = precio_total;
        this.cuponAplicado = true;

        if (!this.ratificacion.cotizacion) {
          this.ratificacion.cotizacion = {
            cupones_id: id
          };
        }

        this.ratificacion.cotizacion.cupones_id = id;

        // En este momento ya se deja al componenete padre actualizar o crear la cotizacion con el nuevo precio

        this.$toast.success("El cupón se aplicó correctamente", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        this.$toast.warning("El cupón es inválido", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.toggleOffLoading();
      }
    },

    borrarCupon() {
      // this.notificacion.costo_total = this.notificacion.costo_total_antes_descuentos;
      // this.notificacion.cotizacion.cupones_id = 0;
      this.nombreCuponDescuento = "";
      this.cuponAplicado = false;
    },
  },
}
</script>
