import { render, staticRenderFns } from "./ModalDocumentos.vue?vue&type=template&id=0d0a4e68&scoped=true"
import script from "./ModalDocumentos.vue?vue&type=script&lang=js"
export * from "./ModalDocumentos.vue?vue&type=script&lang=js"
import style0 from "./ModalDocumentos.vue?vue&type=style&index=0&id=0d0a4e68&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0a4e68",
  null
  
)

export default component.exports