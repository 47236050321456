<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Firma Digital de Documentos</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveRatificacion)">
          <ModalPago :ratificacion="ratificacion" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-4">
          <card>
            <TreeEditRatificacionesDigital :ratificacion="ratificacion" :user="'Client'" @node="selected" />
          </card>
        </b-col>
        <b-col class="col-8">
          <card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Pago'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pago" @submit.prevent="
                      handleSubmit(
                        changeCurrentComponentBody('ComplementaInformacion')
                      )
                      ">
                      <Pago :ratificacion="ratificacion" @submit.prevent @allChecksPassed="submitForm('pago')"
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaInformacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="complementainformacion"
                      @submit.prevent="handleSubmit(changeCurrentComponentBody('Documentos'))">
                      <ComplementaInformacion :ratificacion="ratificacion" @submit.prevent
                        @allChecksPassed="informacionCompletada()" @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Documentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="documentos" @submit.prevent="handleSubmit(documentosCompletos())">
                      <EntregaDocumentos :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Domicilio'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="domicilio" @submit.prevent="handleSubmit()">
                      <Domicilio :ratificacion="ratificacion" @submit.prevent @allChecksPassed="domicilioCompleto()"
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pagocomplementario" @submit.prevent="handleSubmit()">
                      <PagoComplementario :ratificacion="ratificacion" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EsperaValidacion'">
                  <EsperaValidacion @submit.prevent />
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import EsperaValidacion from "@/components/EsperaValidacion.vue";
import ComplementaInformacion from "@/views/clients/procesos/ratificacionesdigital/ComplementaInformacion.vue";
import Domicilio from "@/views/clients/procesos/ratificacionesdigital/Domicilio.vue";
import EntregaDocumentos from "@/views/clients/procesos/ratificacionesdigital/EntregaDocumentos.vue";
import Pago from "@/views/clients/procesos/ratificacionesdigital/Pago.vue";
import PagoComplementario from "@/views/clients/procesos/ratificacionesdigital/PagoComplementario.vue";
import TreeEditRatificacionesDigital from "@/views/clients/procesos/ratificacionesdigital/TreeEditRatificacionesDigital.vue";
import Card from "src/components/Cards/Card.vue";

import { CatDocumentosApi, ComparecientesApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi, DomiciliosApi, RatificacionesApi, RatificantesApi } from "@/api";
import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "EditRatificacionesDigital",

  components: {
    Card,
    TreeEditRatificacionesDigital,
    Pago,
    ComplementaInformacion,
    EntregaDocumentos,
    PagoComplementario,
    Domicilio,
    EsperaValidacion
  },

  created() {
    this.loading = true;
    this.ratificacion.ratificaciones_id = this.$route.params.id;
    this.getRatificacion()
      .then(() => this.getCotizacion())
      .then(() => this.getRatificantes())
      .then(() => this.fillRatificantes())
      .then(() => this.fillNombresCompletos())
      .then(() => this.getCatDocumentosRatificaciones())
      .then(() => this.getDocumentosXServiciosRatificaciones())
      .then(() => this.getDocumentosRatificaciones())
      .then(() => this.addTipoToDocumentosRatificacion())
      .then(() => this.getDomicilio())
      .then(() => this.getCotizacionComplementaria())
      .then(() => this.getEstatus())
      .catch(error => {
        this.showErrorToast("Sucedió un error, intente más tarde");
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'EditComparecientes') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentBody: "Pago",

    ratificacion: {
      ratificaciones_id: null,
      estatus_id: null,
      clientes_id: null,
      cotizaciones_id: null,
      pago_complementario: null,
      cotizaciones_id_complementario: null,
      proceso_digital: null,
      monto_global: null,
      clausula_adeudo: null,
      adeudo: null,
      inscripcion_rug: null,
      monto_garantia: null,
      firma_fuera_oficina: null,
      entrega_fuera_oficina: null,
      fad_instrumento: null,
      fecha_firma: null,
      domicilio_firma: null,
      liga_zoom_firma: null,
      entregables_adicionales: null,
      anexos_certificados: null,
      domicilios_id: null,
      corredurias_id: null,

      link_calendario: null,

      ratificantes: [],
      documentos: [],
      precio_total: null,

      currentComponentValues: {
        Pago: false,
        ComplementaInformacion: false,
        Documentos: false,
        Domicilio: false,
        PagoComplementario: false,
        Cita: false,
        Terminado: false
      }
    },

    catDocumentos: [],
    documentosXServicios: [],
  }),

  methods: {
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.ratificacion.currentComponentValues);
      const values = Object.values(this.ratificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      const pagoIndex = keys.findIndex(value => value === "Pago");
      const currentIndex = keys.findIndex(
        value => value == this.currentComponentBody
      );
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.ratificacion.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if (destIndex - lastCompletedIndex === 1) {
            this.currentComponentBody = dest;
          } else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0];
            }
            this.$toast.warning(
              "Completa la sección " + lastCompleted + " para poder continuar",
              {
                timeout: 3000, //false,
                hideProgressBar: false,
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                closeButton: "button",
                icon: true
              }
            );
          }
        }
      }
      // Ir mas atras
      else {
        if (destIndex > pagoIndex) {
          this.currentComponentBody = dest;
        }
      }
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    },

    async getRatificacion() {
      this.ratificacion = {
        ...this.ratificacion,
        ...await RatificacionesApi.detail(this.ratificacion.ratificaciones_id)
      };
      return;
    },

    async getCotizacion() {
      const { precio_total } = await CotizacionesApi.detail(
        this.ratificacion.cotizaciones_id
      );
      this.ratificacion.precio_total = precio_total;
      return;
    },

    async getRatificantes() {
      const { value } = await RatificantesApi.list({
        page: 0,
        registersPerPage: 0,
        id_ratificaciones: this.ratificacion.ratificaciones_id
      });
      this.ratificacion.ratificantes = value;
      return;
    },

    async fillRatificantes() {
      const promises = this.ratificacion.ratificantes.map(async ratificante => {
        const compareciente = await this.getCompareciente(
          ratificante.comparecientes_id
        );
        return {
          ...ratificante,
          ...compareciente
        };
      });
      this.ratificacion.ratificantes = await Promise.all(promises);
      return;
    },

    async getCompareciente(comparecientes_id) {
      const compareciente = await ComparecientesApi.detail(comparecientes_id);
      return compareciente;
    },

    fillNombresCompletos() {
      return new Promise((resolve, reject) => {
        try {
          this.ratificacion.ratificantes.forEach(ratificante => {
            let nombreCompletoTemp = "";
            if (ratificante.persona === "Física") {
              nombreCompletoTemp =
                ratificante.nombre + " " + ratificante.paterno;
              if (ratificante.materno) {
                nombreCompletoTemp =
                  nombreCompletoTemp + " " + ratificante.materno;
              }
            } else {
              nombreCompletoTemp = ratificante.denominacion_social;
            }
            ratificante.nombreCompleto = nombreCompletoTemp;
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    async getCatDocumentosRatificaciones() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "ratificaciones"
      });
      this.catDocumentos = value;
      return;
    },

    async getDocumentosXServiciosRatificaciones() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 6,
        id_proceso: this.ratificacion.ratificaciones_id
      });
      this.documentosXServicios = value;
      return;
    },

    async getDocumentosRatificaciones() {
      const promises = this.documentosXServicios.map(
        async documentoXServicio => {
          const documento = await this.getDocumentoDetail(
            documentoXServicio.documentos_id
          );
          return documento;
        }
      );
      this.ratificacion.documentos = await Promise.all(promises);
      return;
    },

    async getDocumentoDetail(id) {
      return await DocumentosApi.detail(id);
    },

    async addTipoToDocumentosRatificacion() {
      const promises = this.ratificacion.documentos.map(async documento => {
        const tipo = this.getCatDocumentoNombreById(
          documento.cat_documentos_id
        );
        documento.tipo = tipo;
        return documento;
      });
      this.ratificacion.documentos = await Promise.all(promises);
      return;
    },

    getCatDocumentoNombreById(cat_documentos_id) {
      let catDocumento = this.catDocumentos.find(
        doc => (doc.id = cat_documentos_id)
      );
      return catDocumento ? catDocumento.documento : null;
    },

    async getDomicilio() {
      this.ratificacion.domicilio = await DomiciliosApi.detail(
        this.ratificacion.domicilios_id
      );
      return;
    },

    async getCotizacionComplementaria() {
      if (this.ratificacion.cotizaciones_id_complementario == null) {
        return;
      }
      const { liga_pago } = await CotizacionesApi.detail(
        this.ratificacion.cotizaciones_id_complementario
      );
      this.ratificacion.liga_pago_complementario = liga_pago;
      return;
    },

    getEstatus() {
      const keys = Object.keys(this.ratificacion.currentComponentValues);
      var currentIndex = keys.findIndex(value => value === "Pago");
      if (this.ratificacion.estatus_id == 46) {
        this.currentComponentBody = "ComplementaInformacion";
        currentIndex = keys.findIndex(
          value => value === "ComplementaInformacion"
        );
      }
      if (
        this.ratificacion.estatus_id == 47 ||
        ((this.ratificacion.estatus_id == 48 ||
          this.ratificacion.estatus_id == 49) &&
          this.ratificacion.entrega_fuera_oficina == 0)
      ) {
        this.currentComponentBody = "Documentos";
        currentIndex = keys.findIndex(value => value === "Documentos");
      }
      if (
        (this.ratificacion.estatus_id == 48 ||
          this.ratificacion.estatus_id == 49) &&
        this.ratificacion.entrega_fuera_oficina == 1
      ) {
        this.currentComponentBody = "Domicilio";
        currentIndex = keys.findIndex(value => value === "Domicilio");
      }
      // After staff revision
      if (this.ratificacion.estatus_id >= 50) {
        this.currentComponentBody = "PagoComplementario";
        currentIndex = keys.findIndex(value => value === "PagoComplementario");
      }
      if (this.ratificacion.estatus_id >= 51) {
        this.currentComponentBody = 'PagoComplementario';
        currentIndex = keys.findIndex(value => value === 'Terminado');
      }
      for (let i = 0; i < currentIndex; i++) {
        this.ratificacion.currentComponentValues[keys[i]] = true;
      }
      return;
    },

    changeCurrentComponentBody(body) {
      this.ratificacion.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },

    submitForm(formToSubmit) {
      this.$refs[formToSubmit].dispatchEvent(new Event("submit"));
    },

    async informacionCompletada() {
      this.ratificacion.estatus_id = 47;
      await this.actualizaRatificacion();
      this.submitForm("complementainformacion");
    },

    documentosCompletos() {
      this.ratificacion.estatus_id = 48;

      this.actualizaRatificacion()
        .then(() => {
          if (this.ratificacion.entrega_fuera_oficina == 1) {
            this.changeCurrentComponentBody("Domicilio");
          }

          this.$toast.success("La información fue agregada correctamente y será revisada por un corredor.", {
            timeout: 40000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });

          this.currentComponentBody = "EsperaValidacion";
        });
    },

    domicilioCompleto() {
      this.loading = true;
      this.ratificacion.estatus_id = 49;

      this.actualizaDomicilio()
        .then(() => this.actualizaRatificacion())
        .then(() => {
          this.currentComponentBody = "EsperaValidacion";
        });
    },

    async actualizaDomicilio() {
      this.$toast.success("La información fue agregada correctamente y será revisada por un corredor. Posteriormente recibirá un mail con las instrucciones para la Firma Digital de sus documentos.", {
        timeout: 40000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      }
      );
      return await DomiciliosApi.edit(this.ratificacion.domicilios_id, {
        calle: this.ratificacion.domicilio.calle,
        num_exterior: this.ratificacion.domicilio.num_exterior,
        num_interior: this.ratificacion.domicilio.num_interior,
        colonia: this.ratificacion.domicilio.colonia,
        delegacion_municipio: this.ratificacion.domicilio.delegacion_municipio,
        ciudad: this.ratificacion.domicilio.ciudad,
        entidad: this.ratificacion.domicilio.entidad,
        pais: this.ratificacion.domicilio.pais,
        codigo_postal: this.ratificacion.domicilio.codigo_postal,
        extranjero: 0,
        tipo: "Personal",
        tieneExtranjero: null,
        tieneTipo: null,
        lat: this.ratificacion.domicilio.lat,
        lng: this.ratificacion.domicilio.lng,
        valido: 1,
        comentario: null,
        validador: null
      });
    },

    async actualizaRatificacion() {
      try {
        this.loading = true;
        delete this.ratificacion.actualizado;

        await RatificacionesApi.edit(this.ratificacion.ratificaciones_id, this.ratificacion);
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo actualizar la ratificación. Intente más tarde.')
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
