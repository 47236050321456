<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item active>Procesos</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div>
        <b-card no-body>
          <div class="p-4">
            <div class="row mb-1">
              <b-form-group label="Buscar" label-for="search-input" class="col-4">
                <b-input-group>
                  <b-form-input id="search-input" v-model="searchData" placeholder="Ingrese un término de búsqueda" />
                  <b-input-group-append>
                    <b-button class="orange-btn" :disabled="isTableBusy" @click="handleSearchData">Buscar</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="row">
              <div class="col-4" v-if="showSelects">
                <b-form-select class="custom-select" :options="optionsCatCorredurias" :disabled="isTableBusy"
                  v-model="selectedCorreduria">
                  <template #first>
                    <b-form-select-option :value="null">Corredurías...</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-4">
                <b-form-select class="custom-select" :options="optionsCatServicios" :disabled="isTableBusy"
                  v-model="selectedProceso">
                  <template #first>
                    <b-form-select-option :value="null">Procesos...</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-4">
                <b-form-select class="custom-select" :options="optionsStatus" :disabled="isTableBusy"
                  v-model="selectedStatus">
                  <template #first>
                    <b-form-select-option :value="null">Estatus...</b-form-select-option>
                  </template>
                </b-form-select>
              </div>

            </div>
            <div class="text-center">
              <b-table class="mt-4" hover :fields="tableFields" :items="procesos" :busy="isTableBusy"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @sort-changed="sortingChanged">
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>
                <template #cell(index)="{ index }">
                  {{ getIndex(index + 1) }}
                </template>
                <template #cell(acciones)="{ item }">
                  <router-link v-if="item.proceso == 'Constitución de sociedades'"
                    :to="{ path: '/dashboard/tasks/valid/constitucion/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link :to="{ path: '/dashboard/tasks/valid/formalizacion/' + item.id }"
                    v-if="item.proceso === 'Formalización de actas'">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link v-else-if="item.proceso == 'Notificaciones'"
                    :to="{ path: '/dashboard/tasks/valid/notificacion/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link v-else-if="item.proceso == 'Ratificación de firmas'"
                    :to="{ path: '/dashboard/tasks/valid/ratificacion/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link v-else-if="item.proceso === 'Firma Digital de Documentos'"
                    :to="{ path: '/dashboard/tasks/valid/ratificaciondigital/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                  <router-link v-else-if="item.proceso == 'Copias certificadas'"
                    :to="{ path: '/dashboard/tasks/valid/cotejo/' + item.id }">
                    <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn">
                      <b-icon-pencil />
                    </b-button>
                  </router-link>
                </template>
                <template #cell(listo)="{ item }">
                  <div v-if="item.estatus === 'Pagado'" class="circle completado_red">
                    <span class="number"><i class="fa fa-minus"> </i> </span>
                  </div>
                  <div v-else-if="item.estatus === 'Información Completada'" class="circle completado_red">
                    <span class="number"><i class="fa fa-minus"> </i> </span>
                  </div>
                  <div v-else class="circle completado_yellow">
                    <span class="number"><i class="fa fa-minus"> </i> </span>
                  </div>
                </template>
              </b-table>
              <div class="d-flex my-2">
                <span>
                  Ordenado por: <b>{{ sortBy }}</b>, en dirección: <b>{{ sortDesc ? 'descendente' : 'ascendente' }}</b>
                </span>
              </div>
              <div class="d-flex justify-content-between pages">
                <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalProcesos" />
                <span>Mostrando {{ procesos.length }} registros de {{ totalProcesos }}</span>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Stepper from "src/views/clients/tasks/Stepper.vue";

import {
  CorreduriasApi,
  EstatusApi,
  FuncionesApi
} from "@/api";

import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "Tasks",

  components: {
    Stepper
  },

  async created() {
    this.rol = storage.getItem("role");
    this.corredurias_id_log = storage.getItem("corredurias_id");

    if (this.rol === "Staff Administrador") {
      this.clientes_id = 0;
    }
    // else {
    //   const clienteEmail = storage.getItem("user");
    //   const { id } = await ClientesApi.clienteId(clienteEmail);

    //   this.clientes_id = id;
    // }

    if (this.rol === "Staff Usuario") {
      this.selectedCorreduria = this.corredurias_id_log;
    }

    this.getProcesos();
    this.getCatServicios();
    this.getCatCorredurias();
  },

  watch: {
    selectedCorreduria: "getProcesos",
    selectedProceso: "getEstatus",
    selectedStatus: "getProcesos",
    currentPage: "getProcesos",
  },

  computed: {
    tableFields() {
      //cambiarle el nombre
      return [
        { key: "index", label: "#", class: "text-center" },
        { key: "proceso", label: "Proceso", class: "text-center", sortable: true },
        { key: "folio", label: "Folio", class: "text-center", sortable: true },
        { key: "cliente", label: "Cliente", class: "text-center", sortable: true },
        { key: "descripcion", label: "Descripción", class: "text-center", sortable: true },
        { key: "creado", label: "Fecha creación", class: "text-center", sortable: true, sortDirection: "desc" },
        { key: "estatus", label: "Estatus", class: "text-center", sortable: true },
        { key: "correduria", label: "Correduría", class: "text-center", sortable: true },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
    },
    showSelects() {
      return this.corredurias_id_log == 1 && this.rol == "Staff Administrador";
    }
  },

  mounted() {
    this.interval_id = setInterval(() => {
      if (this.isTableBusy) {
        return;
      }

      this.getProcesosSinLoader();
    }, 60000);
  },

  beforeDestroy() {
    this.clearInterval();
  },

  data() {
    return {
      interval_id: null,

      rol: null,
      corredurias_id_log: null,
      clientes_id: null,

      procesos: [],
      optionsCatCorredurias: [],
      optionsCatServicios: [],
      optionsStatus: [],

      searchData: null,
      selectedProceso: null,
      selectedStatus: null,
      selectedCorreduria: null,

      loading: false,

      // Table
      isTableBusy: false,
      currentPage: 1,
      perPage: 10,
      totalProcesos: 0,
      sortBy: "creado",
      sortDesc: true,
      sortDirection: "desc",
      total: 0,
    };
  },

  methods: {
    clearInterval() {
      clearInterval(this.interval_id);
    },

    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async handleSearchData() {
      await this.getProcesos();
    },

    async getProcesos() {
      try {
        this.isTableBusy = true;

        await this.getProcesosSinLoader();

      } catch (error) {
        console.log(error);
      } finally {
        this.isTableBusy = false;
      }
    },

    async getCatCorredurias() {
      try {
        const { value } = await CorreduriasApi.list({
          page: 0,
          registersPerPage: 0
        });

        value.forEach(element => {
          this.optionsCatCorredurias.push({ text: element.nombre, value: element.id });
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getCatServicios() {
      /*
      Trae la data que llena el select de procesos:
      DATA:
        "Constitución de sociedades",
        "Copias certificadas",
        "Formalización de actas",
        "Notificaciones",
        "Ratificación de firmas",
        "Ratificación de firmas digital",
      */
      try {
        const result = await EstatusApi.procesos({});
        this.optionsCatServicios = result;
      } catch (error) {
        console.log(error);
      }
    },

    async getEstatus() {
      /*
      Trae la data que llena el select de estatus,
      depende del proceso que se seleccione
      */
      try {
        this.optionsStatus = [];
        this.selectedStatus = null;

        this.getProcesosSinLoader();

        const { value } = await EstatusApi.list({
          page: 0,
          registersPerPage: 0,
          proceso: this.selectedProceso
        });

        value.forEach(element => {
          this.optionsStatus.push({ value: element.tipo, text: element.tipo });
        });

      } catch (error) {
        console.log(error);
      }
    },

    async getProcesosSinLoader() {
      try {
        let estatus = "Todos";

        if (this.selectedStatus) {
          estatus = this.selectedStatus;
        }

        const {
          odata_page: page,
          odata_total: total,
          odata_perpage: perPage,
          value
        } = await FuncionesApi.procesos(estatus, {
          clientes_id: this.clientes_id,
          proceso: this.selectedProceso,
          page: this.currentPage,
          data: this.searchData,
          registersPerPage: this.perPage,
          corredurias_id: this.selectedCorreduria,
          sort: `${this.sortBy} ${this.sortDirection}`
        });

        this.currentPage = page;
        this.totalProcesos = total;
        this.procesos = value;
      } catch (error) {
        console.log(error);
      }
    },

    async sortingChanged(ctx) {
      // console.log(ctx);
      this.sortBy = ctx.sortBy;
      this.currentPage = 1;
      this.sortDesc = ctx.sortDesc;
      this.sortDirection = ctx.sortDesc ? 'DESC' : 'ASC';
      this.isTableBusy = true;

      await this.getProcesos();
    },

  }
};
</script>

<style scoped>
.custom-select {
  display: inline-block;
  width: 100%;
  height: 3rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;

  appearance: none;
}

.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}

.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

a {
  color: #ea640b;
}

a:hover {
  color: #495057
}

a:focus {
  color: #ea640b;
}

.invi {
  display: none !important;
}

.completado_red {
  background-color: #e60505 !important;
}

.completado_yellow {
  background-color: #f7e00a !important;
}

.circle {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: silver;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.led-green {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #43eb34;
  /* #690 */
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
