import { render, staticRenderFns } from "./DatosAsamblea.vue?vue&type=template&id=96e312c8&scoped=true"
import script from "./DatosAsamblea.vue?vue&type=script&lang=js"
export * from "./DatosAsamblea.vue?vue&type=script&lang=js"
import style0 from "./DatosAsamblea.vue?vue&type=style&index=0&id=96e312c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96e312c8",
  null
  
)

export default component.exports