import { render, staticRenderFns } from "./Montos.vue?vue&type=template&id=2ce0a84e&scoped=true"
import script from "./Montos.vue?vue&type=script&lang=js"
export * from "./Montos.vue?vue&type=script&lang=js"
import style0 from "./Montos.vue?vue&type=style&index=0&id=2ce0a84e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce0a84e",
  null
  
)

export default component.exports