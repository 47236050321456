<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Procesos</b-breadcrumb-item>
      <b-breadcrumb-item active>Validar Proceso</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="selected('go')">OK</b-button>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <card>
            <TreeM @node="selected" :formalizacion="formalizacion" :user="'Staff'" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <component v-bind:is="currentComponentInfoHead" class="tab"></component>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'DatosSociedad'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosSociedad @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('DatosSociedad')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosAsamblea'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosAsamblea @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('DatosAsamblea')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosDelegado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DatosDelegado @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('DatosDelegado')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EntidadFederativa'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <EntidadFederativa :formalizacion="formalizacion" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" @validaForm="validar('EntidadFederativa')" />
                    </form>
                  </ValidationObserver>
                </div>

                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <Cotizacion @submit.prevent @node="selected" :cat_gastos="cat_gastos"
                        :formalizacion="formalizacion" @validaForm="validar('Cotizacion')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaInformacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <ComplementaInformacion @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('ComplementaInformacion')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DenominacionSocial'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <DenominacionSocial :formalizacion="formalizacion" @submit.prevent @node="selected"
                        @validaForm="validar('DenominacionSocial')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <PagoComplementario @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('PagoComplementario')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'AsignarCorreduria'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <AsignarCorreduria :formalizacion="formalizacion" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Expediente'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <Expediente :formalizacion="formalizacion" @submit.prevent @node="selected"
                        @validaForm="validar('Expediente')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'GeneraDocumento'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <GeneraDocumento :formalizacion="formalizacion" @submit.prevent @node="selected"
                        @validaForm="validar('GeneraDocumento')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DocFirmado'">
                  <DocFirmado :formalizacion="formalizacion" @submit.prevent @node="selected" />
                </div>
                <div v-if="currentComponentBody === 'CitaFirma'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <CitaFirma @submit.prevent @node="selected" :formalizacion="formalizacion"
                        @validaForm="validar('CitaFirma')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Terminado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveFormalizacion)">
                      <Terminado :formalizacion="formalizacion" @submit.prevent @node="selected"
                        @validaForm="validar('Terminado')" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TreeM from "@/views/clients/procesos/formalizacionactas/Tree.vue";
import InfoBase from "@/views/clients/procesos/formalizacionactas/infoBase/InfoBase.vue";
import DatosAsamblea from "@/views/staff/tasks/validaciones/formalizacionactas/TabDatosAsamblea.vue";
import DatosDelegado from "@/views/staff/tasks/validaciones/formalizacionactas/TabDatosDelegado.vue";
import DatosSociedad from "@/views/staff/tasks/validaciones/formalizacionactas/TabDatosSociedad.vue";
import EntidadFederativa from "@/views/staff/tasks/validaciones/formalizacionactas/TabEntidadFederativa.vue";

import AsignarCorreduria from "@/views/staff/tasks/validaciones/formalizacionactas/TabAsignarCorreduria.vue";
import CitaFirma from "@/views/staff/tasks/validaciones/formalizacionactas/TabCitaFirma.vue";
import ComplementaInformacion from "@/views/staff/tasks/validaciones/formalizacionactas/TabComplementaInformacion.vue";
import Cotizacion from "@/views/staff/tasks/validaciones/formalizacionactas/TabCotizacion.vue";
import DenominacionSocial from "@/views/staff/tasks/validaciones/formalizacionactas/TabDenominacionSocial.vue";
import DocFirmado from "@/views/staff/tasks/validaciones/formalizacionactas/TabDocFirmado.vue";
import Expediente from "@/views/staff/tasks/validaciones/formalizacionactas/TabExpediente.vue";
import GeneraDocumento from "@/views/staff/tasks/validaciones/formalizacionactas/TabGeneraDocumento.vue";
import PagoComplementario from "@/views/staff/tasks/validaciones/formalizacionactas/TabPagoComplementario.vue";
import Terminado from "@/views/staff/tasks/validaciones/formalizacionactas/TabTerminado.vue";

import {
  CatDocumentosApi,
  CatFormulariosFormalizacionesApi,
  CatGastosApi,
  ComparecientesApi,
  CotizacionesApi,
  FormalizacionActasApi,
  FuncionesApi
} from "@/api";

import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  name: "ValidFormalizacionActas",

  components: {
    TreeM,
    InfoBase,
    DatosSociedad,
    DatosAsamblea,
    DatosDelegado,
    EntidadFederativa,

    Cotizacion,
    ComplementaInformacion,
    DenominacionSocial,
    PagoComplementario,
    AsignarCorreduria,
    Expediente,
    GeneraDocumento,
    DocFirmado,
    CitaFirma,
    Terminado,
    // FirmaDigital
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "EditComparecientes") {
      this.next = next;
      this.beforeLeave(true);
    } else {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
  },

  async created() {
    try {
      this.loading = true;

      //   this.getCatDocumentosPF();
      //   this.getCatDocumentosPM();
      const p1 = this.getCorredurias();
      const p2 = this.getGastos();
      const p3 = this.getFormalizacion();

      await p1;
      await p2;
      await p3;
    } catch (error) {
      console.log(error);

      this.$toast.error("Sucedió un error al cargar la información, intente más tarde.", {
        timeout: 4000,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true
      });
    } finally {
      this.loading = false;
    }
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentInfoHead: "InfoBase",
    currentComponentBody: "DatosSociedad",

    cat_gastos: [],
    emailUserStaff: null,
    optionsFormalizacionDocs: [],

    catCorredurias: [],
    formalizacion: {
      disabled: null,
      disabledSociedad: false,
      disabledAsamblea: false,
      disabledDelegado: false,
      disabledEntidad: false,
      disabledCotizacion: false,
      disabledComplementa: false,
      disabledDenominacion: false,
      disabledPagoComplementario: false,
      disabledGenera: false,
      disabledCita: false,
      disabledTerminado: false,

      validoSociedad: false,
      validoAsamblea: false,
      validoDelegado: false,
      validoEntidad: false,
      validoCotizacion: false,
      validoComplementa: false,
      validoDenominacion: false,
      validoPagoComplementario: false,
      validoGenera: false,
      validoCita: false,
      validoTerminado: false,

      accion: "validFormalizacion", //para el regreso de comparecientes

      id: null,
      clientes_id: null,
      corredurias_id: null,
      cotizaciones_id: null,

      nombre_sociedad: null,

      domicilio: {
        id: null,
        num_exterior: "",
        num_interior: "",
        colonia: "",
        delegacion_municipio: "",
        ciudad: "",
        entidad: "",
        pais: "",
        codigo_postal: "",
        tipo: "",
        lat: "19.4401467",
        lng: "-99.20719109999999"
      },

      liga_pago_complementario: null,

      sociedad_inscrita_rpc: null,
      convocatoria_lgsm: null,
      convocatoria_psm: null,
      representacion_cien: null,
      lista_asistencia_firmada: null,
      asistentes_firma_acta: null,
      rfcs_accionistas: null,
      sesion_instalada: null,
      sesion_totalitaria: null,

      modifica_capital: null, //1 o 0
      capital_modificado: null, //fijo o var
      tipo_modificado: null,
      monto_modificado: null,
      nuevos_accionistas: null,
      capital_extranjero: null,
      modifica_estatutos: null,
      reforma_total: null,
      modifica_objeto_social: null,
      modifica_duracion_sociedad: null,
      modifica_denominacion_social: null,
      modifica_domicilio_social: null,
      revoca_funcionarios: null,
      num_funcionarios_revocados: null,
      modifica_facultades_funcionarios: null,
      num_modificacion_facultades: null,
      transforma_sociedad: null,
      fusion_sociedad: null,
      disolucion_sociedad: null,
      liquidacion_sociedad: null,

      firmantes: [],

      entregable_adicional: null,
      firma_fuera_oficina: null,

      subtotal_entregable_adicional: 0,
      subtotal_firma_fuera_oficina: 0,
      subtotal_irma_electronica: 0,

      precio_total: 0,

      documentos: [],
      // cotizaciones: {},
      accionistas: [],
      accionistas_cuadro: [],
      administradores_cuadro: [],

      entidad: null,
      delegacion_municipio: null,
      direccion_firma: null,

      declaracion_kycs: null,
      denominacion_solicitada: null,
      cp_formaliza_denominacion: null,
      aviso_uif: null,
      aviso_declaranot: null,
      aviso_rnie: null,
      aviso_sipac: null,

      optionsEntidades: [],
      selectedEntidad: null,
      selectedMunicipio: null,
      selectedCorreduria: null,

      documentos_proyecto: [],
      proyecto_generado: null,
      fad_procesos: []
    }

    //   pagado: null,
    //   formularios: [],
  }),

  methods: {
    async getCorredurias() {
      const { value } = await FuncionesApi.getCorreduriasDomicilios({
        entidad: null,
        municipio: null
      });

      this.catCorredurias = value;

      value.forEach(element => {
        const exists = this.formalizacion.optionsEntidades.findIndex(x => x.text === element.entidad);

        if (exists === -1) {
          this.formalizacion.optionsEntidades.push({
            text: element.entidad,
            value: element.entidad
          });
        }
      });
    },

    async getGastos() {
      const { value } = await CatGastosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 3
      });

      this.cat_gastos = value;
    },

    async getFormalizacion() {
      const id = this.$route.params.id;
      this.formalizacion.id = id;

      let rolStaff = null;

      if (!this.emailUserStaff) {
        rolStaff = storage.getItem("role");
        this.emailUserStaff = storage.getItem("user");
      }

      if (rolStaff === "Staff Administrador") {
        this.formalizacion.disabled = false;
        this.formalizacion.roleStaff = true;
      }

      const {
        estatus,
        cotizaciones_id,
        clientes_id,
        corredurias_id,
        cotizacion,
        liga_pago_complementario,
        tipo_acta,
        subtipo_acta,
        nombre_sociedad,
        tipo_societario,
        domicilios_id,
        sociedad_inscrita_rpc,
        convocatoria_lgsm,
        convocatoria_psm,
        representacion_cien,
        lista_asistencia_firmada,
        asistentes_firma_acta,
        rfcs_accionistas,
        sesion_instalada,
        sesion_totalitaria,
        modifica_capital,
        capital_modificado,
        tipo_modificado,
        monto_modificado,
        nuevos_accionistas,
        capital_extranjero,
        modifica_estatutos,
        reforma_total,
        modifica_objeto_social,
        modifica_duracion_sociedad,
        modifica_denominacion_social,
        modifica_domicilio_social,
        revoca_funcionarios,
        num_funcionarios_revocados,
        modifica_facultades_funcionarios,
        num_modificacion_facultades,
        transforma_sociedad,
        fusion_sociedad,
        disolucion_sociedad,
        liquidacion_sociedad,
        entregable_adicional,
        firma_fuera_oficina,
        actos_inscribibles,
        accionistas_extranjeros,
        rfcs_inscritos,
        reclasificacion_acciones,
        revocan_poderes,
        fecha_firma,
        domicilio_firma,
        estatus_id,

        formularios,
        firmantes,

        documentos,
        domicilio,
        link_calendario,
        entidad,
        delegacion_municipio,
        direccion_firma,
        accionistas
      } = await FormalizacionActasApi.detail(id);

      this.formalizacion.formularios = formularios;
      for (let u = 0; u < formularios.length; u++) {
        if (formularios[u].valido === 1) {
          switch (formularios[u].formulario) {
            case "DatosSociedad":
              this.formalizacion.disabledSociedad = true;
              break;
            case "DatosAsamblea":
              this.formalizacion.disabledAsamblea = true;
              break;
            case "DatosDelegado":
              this.formalizacion.disabledDelegado = true;
              break;
            case "EntidadFederativa":
              this.formalizacion.disabledEntidad = true;
              break;
            case "Cotizacion":
              this.formalizacion.disabledCotizacion = true;
              break;
            case "ComplementaInformacion":
              this.formalizacion.disabledComplementa = true;
              break;
            case "DenominacionSocial":
              this.formalizacion.disabledDenominacion = true;
              break;
            case "PagoComplementario":
              this.formalizacion.disabledPagoComplementario = true;
              break;
            case "GeneraDocumento":
              this.formalizacion.disabledGenera = true;
              break;
            case "CitaFirma":
              this.formalizacion.disabledCita = true;
              break;
            case "Terminado":
              this.formalizacion.disabledTerminado = true;
              break;
          }
        }
      }

      this.validaEstatusForms();

      // this.cotizaciones_id = cotizaciones_id;
      this.formalizacion.id = id;
      this.formalizacion.corredurias_id = corredurias_id;
      this.formalizacion.link_calendario = link_calendario;
      this.formalizacion.clientes_id = clientes_id;
      this.formalizacion.cotizaciones_id = cotizaciones_id;
      this.formalizacion.liga_pago_complementario = liga_pago_complementario;
      this.formalizacion.tipo_acta = tipo_acta;
      this.formalizacion.subtipo_acta = subtipo_acta;
      this.formalizacion.nombre_sociedad = nombre_sociedad;
      this.formalizacion.tipo_societario = tipo_societario;
      this.formalizacion.domicilios_id = domicilios_id;
      this.formalizacion.sociedad_inscrita_rpc = sociedad_inscrita_rpc;
      this.formalizacion.convocatoria_lgsm = convocatoria_lgsm;
      this.formalizacion.convocatoria_psm = convocatoria_psm;
      this.formalizacion.representacion_cien = representacion_cien;
      this.formalizacion.lista_asistencia_firmada = lista_asistencia_firmada;
      this.formalizacion.asistentes_firma_acta = asistentes_firma_acta;
      this.formalizacion.rfcs_accionistas = rfcs_accionistas;
      this.formalizacion.sesion_instalada = sesion_instalada;
      this.formalizacion.sesion_totalitaria = sesion_totalitaria;
      this.formalizacion.modifica_capital = modifica_capital;
      this.formalizacion.capital_modificado = capital_modificado;
      this.formalizacion.tipo_modificado = tipo_modificado;
      this.formalizacion.monto_modificado = monto_modificado;
      this.formalizacion.nuevos_accionistas = nuevos_accionistas;
      this.formalizacion.capital_extranjero = capital_extranjero;
      this.formalizacion.modifica_estatutos = modifica_estatutos;
      this.formalizacion.reforma_total = reforma_total;
      this.formalizacion.modifica_objeto_social = modifica_objeto_social;
      this.formalizacion.modifica_duracion_sociedad = modifica_duracion_sociedad;
      this.formalizacion.modifica_denominacion_social = modifica_denominacion_social;
      this.formalizacion.modifica_domicilio_social = modifica_domicilio_social;
      this.formalizacion.revoca_funcionarios = revoca_funcionarios;
      this.formalizacion.num_funcionarios_revocados = num_funcionarios_revocados;
      this.formalizacion.modifica_facultades_funcionarios = modifica_facultades_funcionarios;
      this.formalizacion.num_modificacion_facultades = num_modificacion_facultades;
      this.formalizacion.transforma_sociedad = transforma_sociedad;
      this.formalizacion.fusion_sociedad = fusion_sociedad;
      this.formalizacion.disolucion_sociedad = disolucion_sociedad;
      this.formalizacion.liquidacion_sociedad = liquidacion_sociedad;
      this.formalizacion.entregable_adicional = entregable_adicional;
      this.formalizacion.firma_fuera_oficina = firma_fuera_oficina;
      this.formalizacion.actos_inscribibles = actos_inscribibles;
      this.formalizacion.accionistas_extranjeros = accionistas_extranjeros;
      this.formalizacion.rfcs_inscritos = rfcs_inscritos;
      this.formalizacion.reclasificacion_acciones = reclasificacion_acciones;
      this.formalizacion.revocan_poderes = revocan_poderes;
      this.formalizacion.fecha_firma = fecha_firma;
      this.formalizacion.domicilio_firma = domicilio_firma;
      this.formalizacion.estatus_id = estatus_id;
      this.formalizacion.estatus = estatus;
      this.formalizacion.cotizacion = cotizacion;
      this.formalizacion.domicilio = domicilio;
      this.formalizacion.firmantes = firmantes;
      this.formalizacion.formularios = formularios;
      this.formalizacion.entidad = entidad;
      this.formalizacion.delegacion_municipio = delegacion_municipio;
      this.formalizacion.direccion_firma = direccion_firma;
      this.formalizacion.selectedEntidad = entidad;
      this.formalizacion.selectedMunicipio = delegacion_municipio;
      this.formalizacion.selectedCorreduria = corredurias_id;

      for (let u = 0; u < formularios.length; u++) {
        if (formularios[u].valido === 1) {
          switch (formularios[u].formulario) {
            case "DatosSociedad":
              this.formalizacion.disabledSociedad = true;
              break;
            case "DatosAsamblea":
              this.formalizacion.disabledAsamblea = true;
              break;
            case "DatosDelegado":
              this.formalizacion.disabledDelegado = true;
              break;
            case "EntidadFederativa":
              this.formalizacion.disabledEntidad = true;
              break;
            case "Cotizacion":
              this.formalizacion.disabledCotizacion = true;
              break;
            case "ComplementaInformacion":
              this.formalizacion.disabledComplementa = true;
              break;
            case "DenominacionSocial":
              this.formalizacion.disabledDenominacion = true;
              break;
            case "PagoComplementario":
              this.formalizacion.disabledPagoComplementario = true;
              break;
            case "GeneraDocumento":
              this.formalizacion.disabledGenera = true;
              break;
            case "CitaFirma":
              this.formalizacion.disabledCita = true;
              break;
            case "Terminado":
              this.formalizacion.disabledTerminado = true;
              break;
            default:
              break;
          }
        }
      }

      this.validaEstatusForms();
      this.formalizacion.accionistas = accionistas;

      for (let y = 0; y < accionistas.length; y++) {
        const a = Object.assign({}, accionistas[y]);

        if (accionistas[y].tipo == "Accionista") {
          this.formalizacion.accionistas_cuadro.push(a);
        } else {
          //admin o repre
          this.formalizacion.administradores_cuadro.push(a);
        }
      }

      if (cotizacion) {
        this.formalizacion.precio_total = cotizacion.precio_total;
        this.formalizacion.pagado = cotizacion.pagado;
        // this.formalizacion.cupones_id = cotizacion.cupones_id;
        // this.constitutiva.folio_transaccion = cotizacion.folio_transaccion;
      }

      await this.getCatDocumentosFormalizacion();

      for (let r = 0; r < documentos.length; r++) {
        documentos[r].nombreFile = documentos[r].documento.substring(documentos[r].documento.lastIndexOf("/") + 1);

        let tipo = this.optionsFormalizacionDocs.find(x => x.value == documentos[r].cat_documentos_id);
        documentos[r].tipo = tipo.text;

        // //ToDo expediente
        // let tipobd = documentos[r].tipo;
        // if (
        //   tipobd === "Constitutiva de la Persona Moral" ||
        //   tipobd === "Comprobante de domicilio" ||
        //   tipobd === "RFC o Cédula de identificación Fiscal" ||
        //   tipobd === "CURP" ||
        //   tipobd === "Constitutiva de la persona moral" ||
        //   tipobd === "Poderes del representante" ||
        //   tipobd === "Cuadro de distribución accionaria"

        // ) {
        //   documentos[r].tipo = tipobd;
        // } else {
        //   // documentos[r].tipo = "Otro";
        //   // documentos[r].tipoOtro = tipobd;
        // }
      }

      this.formalizacion.documentos = documentos;
      let documentos_proyecto = [];
      let documentos_kycs = [];

      for (let w = 0; w < documentos.length; w++) {
        if (
          //"Proyecto PDF"
          documentos[w].cat_documentos_id === "89" ||
          documentos[w].cat_documentos_id === "90" //"Proyecto Word"
        ) {
          //proyectos cargados por correduria
          documentos_proyecto.push(documentos[w]);
        } else if (documentos[w].cat_documentos_id === "91") {
          // "Proyecto generado"
          //proyecto generado por nosotros automatico
          this.formalizacion.proyecto_generado = documentos[w];
        } else if (documentos[w].cat_documentos_id === "22") {
          // "KYC"
          documentos_kycs.push(documentos[w]);
        }
      }
      this.formalizacion.documentos_proyecto = documentos_proyecto;
      this.formalizacion.documentos_kycs = documentos_kycs;

      //node cuando regresan de comparecientes
      const query = Object.assign({}, this.$route.query);

      if (query) {
        const node = query.node;

        if (node) {
          this.currentComponentBody = node;
        }
      }
    },

    async saveFormalizacion() {
      this.loading = true;
      let saved = await this.editFormalizacion();

      if (saved) {
        let nodos = [
          "DatosSociedad",
          "DatosAsamblea",
          "DatosDelegado",
          "EntidadFederativa",
          "Cotizacion",
          "ComplementaInformacion",
          "DenominacionSocial",
          "PagoComplementario",
          "AsignarCorreduria",
          "Expediente",
          "GeneraDocumento",
          "DocFirmado",
          "CitaFirma",
          "Terminado"
        ];

        const index = nodos.findIndex(o => o === this.currentComponentBody);
        let nextIndex = parseInt(index);
        let node = null;
        if (nextIndex < nodos.length - 1) {
          node = nodos[nextIndex + 1];
        } else {
          node = "CitaFirma";
        }

        this.selected(node, true);
      }
      this.loading = false;
    },

    async validar(form) {
      this.loading = true;

      await this.editFormalizacion(1);
      //1 valid es para cambio de estatus cuando se deba

      let form_obj = this.formalizacion.formularios.find(
        x => x.formulario === form
      );

      try {
        let data = {
          cat_formularios_id: form_obj.cat_formularios_id,
          estatus: 1,
          valido: 1,
          validador: this.emailUserStaff,
          formalizacion_actas_id: this.formalizacion.id,
          activo: 1
        };
        await CatFormulariosFormalizacionesApi.edit(form_obj.id, data);
        this.$toast.success("Formulario validado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        // console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }

      if (form === "DatosSociedad") {
        this.formalizacion.disabledSociedad = true;
        this.formalizacion.validoSociedad = true;
      }
      if (form === "DatosAsamblea") {
        this.formalizacion.disabledAsamblea = true;
        this.formalizacion.validoAsamblea = true;
      }
      if (form === "DatosDelegado") {
        this.formalizacion.disabledDelegado = true;
        this.formalizacion.validoDelegado = true;
      }
      if (form === "EntidadFederativa") {
        this.formalizacion.disabledEntidad = true;
        this.formalizacion.validoEntidad = true;
      }
      if (form === "Cotizacion") {
        this.formalizacion.disabledCotizacion = true;
        this.formalizacion.validoCotizacion = true;
      }
      if (form === "ComplementaInformacion") {
        this.formalizacion.disabledComplementa = true;
        this.formalizacion.validoComplementa = true;
      }
      if (form === "DenominacionSocial") {
        this.formalizacion.disabledDenominacion = true;
        this.formalizacion.validoDenominacion = true;
      }
      if (form === "PagoComplementario") {
        this.formalizacion.disabledPagoComplementario = true;
        this.formalizacion.validoPagoComplementario = true;
      }
      if (form === "GeneraDocumento") {
        this.formalizacion.disabledGenera = true;
        this.formalizacion.validoGenera = true;
      }
      if (form === "CitaFirma") {
        this.formalizacion.disabledCita = true;
        this.formalizacion.validoCita = true;
      }
      if (form === "Terminado") {
        this.formalizacion.disabledTerminado = true;
        this.formalizacion.validoTerminado = true;
      }

      this.loading = false;

      let nodos = [
        "DatosSociedad",
        "DatosAsamblea",
        "DatosDelegado",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "DenominacionSocial",
        "PagoComplementario",
        "AsignarCorreduria",
        "Expediente",
        "GeneraDocumento",
        "DocFirmado",
        "CitaFirma",
        "Terminado"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      const nextIndex = parseInt(index);
      let node = null;

      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        node = "Terminado";
      }

      this.selected(node);
    },

    // async colocaEstatusKYCs() {
    //   //poner estatus en cada docs_kycs
    //   for (let i = 0; i < this.constitutiva.documentos_kycs.length; i++) {
    //     let nombreFile = this.constitutiva.documentos_kycs[i].nombreFile;
    //     //de nombreFile obtiene la string que está entre el guion bajo y el punto
    //     let nombre_completo = nombreFile.substring(
    //       nombreFile.lastIndexOf("_") + 1,
    //       nombreFile.lastIndexOf(".")
    //     );
    //     let index = await this.findIndexAccio(nombre_completo);
    //     let compar_id = this.constitutiva.accionistas[index].id;

    //     let indexFad = this.constitutiva.fad_procesos.findIndex(
    //       o => o.comparecientes_id === compar_id
    //     );

    //     if (indexFad != -1) {
    //       let estatus_id = this.constitutiva.fad_procesos[indexFad]
    //         .cat_fad_responses_id;

    //       this.constitutiva.documentos_kycs[
    //         i
    //       ].cat_fad_responses_id = estatus_id;

    //       //luego ya teniendo el cat_fad_responses_id, llamo a request que traiga name de este estatus y se lo pongo en .estatus
    //       let estatus_name = await FuncionesApi.getCatFadResponseRespuesta(
    //         estatus_id
    //       );
    //       this.constitutiva.documentos_kycs[i].estatus = estatus_name;

    //       var a = Object.assign({}, this.constitutiva.documentos_kycs[i]);
    //       this.constitutiva.documentos_kycs.splice([i], 1, a);
    //       //falta aqui que no lo pone en la tabla en fornt!!!
    //     } else {
    //       this.constitutiva.documentos_kycs[i].cat_fad_responses_id = null;
    //     }
    //   }
    // },
    // async findIndexAccio(nombre_completo) {
    //   let index;
    //   let nombre_completo_nospace = nombre_completo.replaceAll(" ", "");
    //   this.constitutiva.accionistas.forEach(accio => {
    //     let nombre_accio = accio.nombreCompleto.replaceAll(" ", "");
    //     if (nombre_accio === nombre_completo_nospace) {
    //       index = this.constitutiva.accionistas.findIndex(
    //         o => o.id === accio.id
    //       );
    //     }
    //   });
    //   return index;
    // },

    validaEstatusForms() {
      for (let i = 0; i < this.formalizacion.formularios.length; i++) {
        if (this.formalizacion.formularios[i].valido === 1) {
          switch (this.formalizacion.formularios[i].formulario) {
            case "DatosSociedad":
              this.formalizacion.validoSociedad = true;
              break;
            case "DatosAsamblea":
              this.formalizacion.validoAsamblea = true;
              break;
            case "DatosDelegado":
              this.formalizacion.validoDelegado = true;
              break;
            case "EntidadFederativa":
              this.formalizacion.validoEntidad = true;
              break;
            case "Cotizacion":
              this.formalizacion.validoCotizacion = true;
              break;
            case "ComplementaInformacion":
              this.formalizacion.validoComplementa = true;
              break;
            case "DenominacionSocial":
              this.formalizacion.validoDenominacion = true;
              break;
            case "PagoComplementario":
              this.formalizacion.validoPagoComplementario = true;
              break;
            case "GeneraDocumento":
              this.formalizacion.validoGenera = true;
              break;
            case "CitaFirma":
              this.formalizacion.validoCita = true;
              break;
            case "Terminado":
              this.formalizacion.validoTerminado = true;
              break;
          }
        }
      }
    },

    async getCatDocumentosFormalizacion() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "formalizacion"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsFormalizacionDocs.push(obj);
        });
      } catch (error) {
        console.log(error);
      }
    },

    async editFormalizacion(valid) {
      this.loading = true;

      let firmantesA = [];
      for (let i = 0; i < this.formalizacion.firmantes.length; i++) {
        //si tienen rfc ya existe compareciente
        if (this.formalizacion.firmantes[i].rfc) {
          const obj = {
            comparecientes_id: this.formalizacion.firmantes[i].id,
            cargo: this.formalizacion.firmantes[i].cargo
          };
          firmantesA.push(obj);
        } else {
          const obj = {
            cliente_id: this.formalizacion.clientes_id,
            nombre: this.formalizacion.firmantes[i].nombre,
            paterno: this.formalizacion.firmantes[i].paterno,
            materno: this.formalizacion.firmantes[i].materno,
            persona: "Física",
            cargo: this.formalizacion.firmantes[i].cargo,
            visible: this.formalizacion.firmantes[i].visible
          };
          firmantesA.push(obj);
        }
      }

      var data = {
        clientes_id: this.formalizacion.clientes_id,
        activo: 1,
        cotizaciones_id: this.formalizacion.cotizaciones_id
      };

      if (this.currentComponentBody === "DatosSociedad") {
        let domicilio_data = {
          id: this.formalizacion.domicilio.id,
          calle: this.formalizacion.domicilio.calle,
          num_exterior: this.formalizacion.domicilio.num_exterior,
          num_interior: this.formalizacion.domicilio.num_interior,
          colonia: this.formalizacion.domicilio.colonia,
          delegacion_municipio: this.formalizacion.domicilio
            .delegacion_municipio,
          ciudad: this.formalizacion.domicilio.ciudad,
          entidad: this.formalizacion.domicilio.entidad,
          pais: this.formalizacion.domicilio.pais,
          codigo_postal: this.formalizacion.domicilio.codigo_postal,
          lat: this.formalizacion.domicilio.lat,
          lng: this.formalizacion.domicilio.lng
        };

        data["tipo_acta"] = this.formalizacion.tipo_acta;
        data["subtipo_acta"] = this.formalizacion.subtipo_acta;
        data["nombre_sociedad"] = this.formalizacion.nombre_sociedad;
        data["tipo_societario"] = this.formalizacion.tipo_societario;
        data[
          "sociedad_inscrita_rpc"
        ] = this.formalizacion.sociedad_inscrita_rpc;
        data["domicilio"] = domicilio_data;
        data["formularios"] = [
          {
            formulario: "DatosSociedad",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "DatosAsamblea") {
        data["modifica_capital"] = this.formalizacion.modifica_capital;
        data["capital_modificado"] = this.formalizacion.capital_modificado;
        data["tipo_modificado"] = this.formalizacion.tipo_modificado;
        data["monto_modificado"] = this.formalizacion.monto_modificado;
        data["nuevos_accionistas"] = this.formalizacion.nuevos_accionistas;
        data["capital_extranjero"] = this.formalizacion.capital_extranjero;
        data["modifica_estatutos"] = this.formalizacion.modifica_estatutos;
        data["reforma_total"] = this.formalizacion.reforma_total;
        data[
          "modifica_objeto_social"
        ] = this.formalizacion.modifica_objeto_social;
        data[
          "modifica_duracion_sociedad"
        ] = this.formalizacion.modifica_duracion_sociedad;
        data[
          "modifica_denominacion_social"
        ] = this.formalizacion.modifica_denominacion_social;
        data[
          "modifica_domicilio_social"
        ] = this.formalizacion.modifica_domicilio_social;
        data["revoca_funcionarios"] = this.formalizacion.revoca_funcionarios;
        data[
          "num_funcionarios_revocados"
        ] = this.formalizacion.num_funcionarios_revocados;
        data["modifica_facultades_funcionarios"] = this.formalizacion.modifica_facultades_funcionarios;
        data["num_modificacion_facultades"] = this.formalizacion.num_modificacion_facultades;
        data["transforma_sociedad"] = this.formalizacion.transforma_sociedad;
        data["fusion_sociedad"] = this.formalizacion.fusion_sociedad;
        data["disolucion_sociedad"] = this.formalizacion.disolucion_sociedad;
        data["liquidacion_sociedad"] = this.formalizacion.liquidacion_sociedad;

        data["convocatoria_lgsm"] = this.formalizacion.convocatoria_lgsm;
        data["convocatoria_psm"] = this.formalizacion.convocatoria_psm;
        data["representacion_cien"] = this.formalizacion.representacion_cien;
        data[
          "lista_asistencia_firmada"
        ] = this.formalizacion.lista_asistencia_firmada;
        data[
          "asistentes_firma_acta"
        ] = this.formalizacion.asistentes_firma_acta;

        data["formularios"] = [
          {
            formulario: "DatosAsamblea",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "DatosDelegado") {
        data["firmantes"] = firmantesA;
        data["formularios"] = [
          {
            formulario: "DatosDelegado",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
        await this.completaComparecientes();
      }
      if (this.currentComponentBody === "EntidadFederativa") {
        data["entidad"] = this.formalizacion.selectedEntidad;
        data["delegacion_municipio"] = this.formalizacion.selectedMunicipio;
        data["formularios"] = [
          {
            formulario: "EntidadFederativa",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "Cotizacion") {
        this.formalizacion.disabled = true;

        let entregable_adicional = 0;
        if (this.formalizacion.entregable_adicional) entregable_adicional = 1;
        let firma_fuera_oficina = 0;
        if (this.formalizacion.firma_fuera_oficina) firma_fuera_oficina = 1;

        data["entregable_adicional"] = entregable_adicional;
        data["firma_fuera_oficina"] = firma_fuera_oficina;

        // let objCoti = {
        //   id: this.formalizacion.cotizacion.id,
        //   cupones_id: this.formalizacion.cotizacion.cupones_id,
        //   // cupones_id: this.formalizacion.cupones_id,
        //   precio_total: this.formalizacion.precio_total,
        //   pagado: 1,
        //   folio_transaccion: this.formalizacion.folio_transaccion
        // };
        // data["cotizacion"] = objCoti;

        const _cotizacion = await CotizacionesApi.detail(this.formalizacion.cotizacion.id);
        this.formalizacion.cotizacion = _cotizacion;
        data.cotizacion = _cotizacion;

        data["formularios"] = [
          {
            formulario: "Cotizacion",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "ComplementaInformacion") {
        if (valid === 1) {
          //si viene de validación, cambia estatus
          data["estatus_id"] = "62";
        }
        var documentos = JSON.parse(
          JSON.stringify(this.formalizacion.documentos)
        );
        data["documentos"] = documentos;
        data["accionistas"] = this.formalizacion.accionistas;
        data["formularios"] = [
          {
            formulario: "ComplementaInformacion",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "DenominacionSocial") {
        if (valid === 1) {
          //si viene de validación, cambia estatus
          data["estatus_id"] = "63";
        }
        let denominacion_solicitada = 0;
        if (this.formalizacion.denominacion_solicitada)
          denominacion_solicitada = 1;
        let cp_formaliza_denominacion = 0;
        if (this.formalizacion.cp_formaliza_denominacion)
          cp_formaliza_denominacion = 1;

        data["denominacion_solicitada"] = denominacion_solicitada;
        data["cp_formaliza_denominacion"] = cp_formaliza_denominacion;
        data["formularios"] = [
          {
            formulario: "DenominacionSocial",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "PagoComplementario") {
        if (valid === 1) {
          //si viene de validación, cambia estatus
          data["estatus_id"] = "64";
          data["formularios"] = [
            { formulario: "CitaFirma", estatus: 1 },
            {
              formulario: "PagoComplementario",
              estatus: 1,
              guardado: 1, //para que se muestre en cliente
              validador: this.emailUserStaff
            }
          ];
        } else {
          // data["formularios"] = [
          //   {
          //     formulario: "PagoComplementario",
          //     estatus: 1,
          //     validador: this.emailUserStaff
          //   }
          // ];
        }
        data[
          "liga_pago_complementario"
        ] = this.formalizacion.liga_pago_complementario;
      }
      if (this.currentComponentBody === "AsignarCorreduria") {
        data["corredurias_id"] = this.formalizacion.corredurias_id;
      }
      if (this.currentComponentBody === "GeneraDocumento") {
        // for (let s = 0; s < this.constitutiva.documentos_proyecto.length; s++) {
        //   let a = Object.assign({}, this.constitutiva.documentos_proyecto[s]);
        //   this.constitutiva.documentos.push(a);
        // }
        // data["documentos"] = this.constitutiva.documentos;
      }
      if (this.currentComponentBody === "CitaFirma") {
        data["link_calendario"] = this.formalizacion.link_calendario;
        data["fecha_firma"] = this.formalizacion.fecha_firma;
        data["direccion_firma"] = this.formalizacion.direccion_firma;
        data["formularios"] = [
          {
            formulario: "CitaFirma",
            estatus: 1,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "Terminado") {
        data["estatus_id"] = "60";
        data["formularios"] = [
          {
            formulario: "Terminado",
            estatus: 1,
            valido: 1, //unico btn
            validador: this.emailUserStaff
          }
        ];
      }

      try {
        // const { firmantes } =
        await FormalizacionActasApi.edit(this.formalizacion.id, data);
        // if (firmantes != null) {
        //   for (let i = 0; i < firmantes.length; i++) {
        //     let newId = firmantes[i].id;
        //     this.formalizacion.firmantes[i].id = newId;
        //     var a = Object.assign({}, this.formalizacion.firmantes[i]);
        //     this.formalizacion.firmantes.splice([i], 1, a);
        //   }
        // }

        // if (this.currentComponentBody === "DatosSociedad") {
        //   this.formalizacion.validoSociedad = true;
        // }
        // if (this.currentComponentBody === "DatosAsamblea") {
        //   this.formalizacion.validoAsamblea = true;
        // }
        // if (this.currentComponentBody === "DatosDelegado") {
        //   this.formalizacion.validoDelegado = true;
        // }

        // if (this.currentComponentBody === "EntidadFederativa") {
        //   this.constitutiva.validoEntidad = true;
        // }
        // if (this.currentComponentBody === "Cotizacion") {
        //   this.constitutiva.validoCotizacion = true;
        // }
        // if (this.currentComponentBody === "ComplementaInformacion") {
        //   this.constitutiva.disabledComplementa = true;
        //   this.constitutiva.validoComplementa = true;
        // }

        // if (this.currentComponentBody === "CitaFirma") {
        //   this.constitutiva.disabledCita = true;
        //   this.constitutiva.constitutiva.validoCita = true;
        // }

        this.$toast.success("Información guardada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        return true;
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
        return false;
      }
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node, automatic) {
      this.loading = true;
      if (node != "go") {
        this.prenode = node;
      }

      this.currentComponentBody = node;
      if (
        node === "DatosSociedad" ||
        node === "DatosAsamblea" ||
        node === "DatosDelegado" ||
        node === "EntidadFederativa"
      ) {
        this.currentComponentInfoHead = "InfoBase";
      }

      if (
        node === "Cotizacion" ||
        node === "ComplementaInformacion" ||
        node === "DenominacionSocial" ||
        node === "PagoComplementario" ||
        node === "AsignarCorreduria" ||
        node === "Expediente" ||
        node === "GeneraDocumento" ||
        node === "DocFirmado" ||
        node === "CitaFirma" ||
        node === "InfoBase" ||
        node === "Terminado"
      ) {
        this.currentComponentInfoHead = null;
      }

      this.loading = false;
    },

    async getEstatusForm(formulario, formalizacion_id) {
      try {
        let estatus = await CatFormulariosFormalizacionesApi.getEstatusForm(
          formulario,
          formalizacion_id
        );
        return estatus;
      } catch { }
    },

    async completaComparecientes() {
      for (const firmante of this.formalizacion.firmantes) {
        firmante.completado = true;
        firmante.cat_servicio_id = 3;
        firmante.servicio_id = this.formalizacion.id;
        firmante.tipo_validacion = "new_formalizacion";

        await ComparecientesApi.edit(firmante.id, firmante);
      }
    },
  }
};
</script>
