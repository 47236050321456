<template>
  <div class="container">
    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="entrega-documento-modal" title="" hide-footer id="modalC" size="xl">
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalEntregaDocumento :currentDocumento="currentDocumento" :ratificacion="ratificacion" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <!-- Template -->
    <h4 class="cardD">Entrega de Documentos</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="documentosToShow">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button
            v-if="(ratificacion.staff && ratificacion.validaDocumentos == 0) || (!ratificacion.staff && ratificacion.estatus_id == 35)"
            variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="openEditDocumentoModal(item)"
            class="editBtn">
            <b-icon-upload />
          </b-button>
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn">
            <b-icon-eye />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="row mt-4" v-if="!ratificacion.staff">
      <div class="col-md-12">
        <ValidationProvider name="confirmAceptacion" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación es obligatoria'
          }">
          <!-- <label for="checkInfoCorrecta" class="validacionDatosLabel">Acepto que la información proporcionada es correcta.</label> -->
          <b-form-checkbox id="checkInfoCorrecta" v-model="confirmAceptacion"
            :disabled="ratificacion.estatus_id != 35 || !documentosCompletos">
            Acepto que la información proporcionada es correcta.
          </b-form-checkbox>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m" :disabled="isButtonDisabled">
          {{ buttonText}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// Modales
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalEntregaDocumento from "@/views/clients/procesos/ratificaciones/infoBase/modales/ModalEntregaDocumento.vue";
// API
import { DocumentosApi, FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "EntregaDocumentos",

  components: {
    ModalEntregaDocumento,
    ModalPreviewImg,
    ModalPreviewPDF,
  },

  mixins: [loadingMixin],

  props: {
    ratificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      let fieldsDocumentos = [
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "tieneDocumento", label: "Documento Cargado", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsDocumentos;
    },

    buttonText() {
      return this.ratificacion.staff ? 'Validar' : 'Continuar';
    },

    isButtonDisabled() {
      return (!this.ratificacion.staff && (!this.confirmAceptacion || (this.ratificacion.estatus_id != 35 && this.documentosCompletos))) ||
        this.ratificacion.validaDocumentos == 1;
    }
  },

  created() {
    this.toggleOnLoading();
    this.selectDocumentosToShow()
      .then(() => this.areDocumentosCompletos())
      .then(() => this.checkDocsUploaded())
      .then(() => this.refreshTable())
      .catch((error) => {
        this.showErrorToast('No se pudieron cargar los documentos. Intente más tarde.');
        console.log(error);
      })
      .finally(() => { this.toggleOffLoading(); })
  },

  data() {
    return {
      documentosToShow: [],
      documentosCompletos: false,
      confirmAceptacion: false,

      currentDocumento: {},
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    selectDocumentosToShow() {
      return new Promise((resolve, reject) => {
        const cat_documentos_id_to_include = [47, 48];
        this.documentosToShow = this.ratificacion.documentos.filter(documento =>
          cat_documentos_id_to_include.includes(parseInt(documento.cat_documentos_id)));
        resolve();
      })
    },

    checkDocsUploaded() {
      return new Promise((resolve, reject) => {
        this.documentosToShow.forEach(documento => {
          documento.documento != '' ? documento.tieneDocumento = '✔' : documento.tieneDocumento = 'X';
        })
        resolve();
      })
    },

    openEditDocumentoModal(documento) {
      this.$refs["entrega-documento-modal"].show();
      this.currentDocumento = documento;
    },

    hideEditDocumentoModal() {
      this.$refs["entrega-documento-modal"].hide();
    },

    postDocumento() {
      this.hideEditDocumentoModal();
      this.sendCurrentDocumentoToAzureStorage();
    },

    async sendCurrentDocumentoToAzureStorage() {
      try {
        this.toggleOnLoading();

        const formData = new FormData();

        this.currentDocumento.nombre = this.currentDocumento.nombre;
        formData.append("file", this.currentDocumento.file);

        const path = await FuncionesApi.file(
          this.ratificacion.ratificaciones_id,
          "documentosRatificaciones",
          formData,
          {}
        );

        this.currentDocumento.documento = path;
        this.currentDocumento.tieneDocumento = '✔';
        this.updateDocumentoBD();
      } catch (error) {
        this.showErrorToast('Error al mandar el archivo. Intente más tarde.');
        console.log(error);
      } finally {
        this.toggleOffLoading();
      }
    },

    async updateDocumentoBD() {
      try {
        this.toggleOnLoading();
        await DocumentosApi.edit(this.currentDocumento.id, {
          documento: this.currentDocumento.documento,
          cat_documentos_id: this.currentDocumento.cat_documentos_id,
          auto_referencia: this.currentDocumento.auto_referencia,
          descripcion: this.currentDocumento.descripcion,
          paginas: parseInt(this.currentDocumento.paginas),
          copias_certificadas: null,
          nombre: this.currentDocumento.nombre,
          activo: 1
        })
        this.updateratificacionDocumentoFromCurrentDocumento();
      } catch (error) {
        this.showErrorToast('Error al actualizar el documento. Intente más tarde.');
        console.log(error);
      } finally {
        this.toggleOffLoading();
      }
    },

    updateratificacionDocumentoFromCurrentDocumento() {
      const ratificacionDocumentosIndex = this.getratificacionDocumentosIndex(this.currentDocumento);
      this.documentosToShow[ratificacionDocumentosIndex] = this.currentDocumento;
      this.documentosToShow[ratificacionDocumentosIndex].tieneDocumento = '✔';
      this.areDocumentosCompletos();
    },

    getratificacionDocumentosIndex(documento) {
      let ratificacionDocumentosIndex = this.documentosToShow.findIndex(
        (ratificacionDocumento) => ratificacionDocumento.id === parseInt(documento.id)
      );
      return ratificacionDocumentosIndex;
    },

    previewDocumento(item) {
      this.currentDocumento = item;
      const isCurrentDocumentImage = this.isCurrentDocumentAnImage()
      if (isCurrentDocumentImage) {
        this.$refs["documento-modal-preview-img"].show();
      } else {
        this.$refs["documento-modal-preview-pdf"].show();
      }
    },

    isCurrentDocumentAnImage() {
      let documentoSeparatedByDots = this.currentDocumento.nombre.split('.')
      let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]
      if (imageExtensions.includes(extensionDocumento)) {
        return true;
      }
      return false;
    },

    areDocumentosCompletos() {
      let completos = true;
      if (this.documentosToShow.length == 0) {
        return;
      }
      this.documentosToShow.forEach(documento => {
        if (documento.documento == "") {
          completos = false;
        }
      });
      this.documentosCompletos = completos;
      this.refreshTable();
    },

    refreshTable() {
      this.$refs.table.refresh();
    },
  }
}
</script>

<style scoped>
.cardErrors {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.cardD {
  font-size: 15px !important;
  color: #ea640b;
  margin-top: 0%;
}

.bottom2 {
  float: right;
}

.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}

.container>>>.validacionDatosLabel {
  position: absolute;
  margin-left: 30px;
  top: 25%;
  max-width: 1500px;
}
</style>
