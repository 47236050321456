<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La denominación social es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Denominación o Razón Social"
            placeholder="Denominación Social" v-model="user.denominacion_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <ValidationProvider
          name="email"
          rules="required|email"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido',
          }"
        >
          <base-input
            type="email"
            label="Email"
            placeholder="Email"
            v-model="user.email"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-2">
        <ValidationProvider
          name="rfc"
          rules="required|length:13"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El RFC es obligatorio',
            length: 'Debe tener 13 caracteres',
          }"
        >
          <base-input
            type="text"
            label="RFC"
            placeholder="RFC"
            v-model="user.rfc"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
      <div class="col-md-8">
        <ValidationProvider name="actividad" :rules="requiredIfNotRatificacion" v-slot="{ errors }" :custom-messages="{
          required: 'La actividad es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="text" label="Actividad o Giro Mercantil" placeholder="Actividad"
            v-model="user.actividad">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="fecha_constitucion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de constitución es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="Fecha de constitución"
            placeholder="Fecha de constitución" v-model="user.fecha_constitucion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <!-- TODO: Refactorizar este select para utilizar las nacionalidades de la api -->
        <ValidationProvider name="nacionalidad" rules="required" v-slot="{ errors }" :custom-messages="{required: 'La nacionalidad es obligatoria'}">
          <label>Nacionalidades</label>
          <!-- <v-select :disabled="user.disabled" multiple v-model="user.nacionalidades" :options="optionsNacionalidades"
            :selectable="option => !option.includes('_______________________________________')" /> -->
            <v-select :disabled="user.disabled" multiple v-model="user.nacionalidades" label="nacionalidad"
            :options="optionsNacionalidades" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-7">
        <ValidationProvider name="domicilio_social" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El domicilio social es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Domicilio social" placeholder="Domicilio social"
            v-model="user.domicilio_social">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ValidationProvider name="objeto_social" :rules="requiredIfNotRatificacion" v-slot="{ errors }"
          :custom-messages="{
            required: 'El objeto social es obligatorio'
          }">
          <label>Objeto Social</label>
          <b-form-textarea :disabled="user.disabled" type="text" placeholder="Objeto Social (Resumen)"
            v-model="user.objeto_social">
          </b-form-textarea>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <br />

    <div class="row" v-if="!user.entidad_extranjera">
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número de instrumento es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Instrumento Constitutivo"
            placeholder="Número de Teléfono" v-model="user.instrumento_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="De Fecha" placeholder="De Fecha"
            v-model="user.instrumento_numero_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row" v-if="!user.entidad_extranjera">
      <div class="col-md-2">
        <ValidationProvider name="instrumento_fedatario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de fedatario es obligatorio'
        }">
          <label>Tipo de Fedatario</label>
          <select v-model="user.instrumento_fedatario" class="custom-select">
            <option value="Notario">Notario</option>
            <option value="Corredor Público">Corredor Público</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_fedatario_nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre del fedatario es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Nombre de Fedatario"
            placeholder="Nombre de Fedatario" v-model="user.instrumento_fedatario_nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Número" placeholder="Número"
            v-model="user.instrumento_fedatario_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <!-- <base-input
            type="text"
            label="Entidad Federativa"
            placeholder="Estado"
            v-model="user.instrumento_fedatario_estado"
          >
          </base-input> -->
          <label>Entidad Federativa</label>
          <select :disabled="user.disabled" v-model="user.instrumento_fedatario_estado" class="custom-select">
            <option v-for="option in optionsEstados" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row" v-if="!user.entidad_extranjera">
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El RPF Folio es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Folio" placeholder="Registro Público"
            v-model="user.instrumento_rpf_folio">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha es obligatoria'
        }">
          <base-input :disabled="user.disabled" type="date" label="De Fecha" placeholder="De Fecha"
            v-model="user.instrumento_rpf_folio_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_lugar" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El lugar es obligatorio'
        }">
          <base-input :disabled="user.disabled" type="text" label="Lugar" placeholder="Lugar"
            v-model="user.instrumento_rpf_folio_lugar">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="">Entidad Extranjera</label>
        <b-form-checkbox v-model="user.entidad_extranjera" @change="entidadExtranjera(user.entidad_extranjera)">
        </b-form-checkbox>
      </div>
      <div class="col-md-8" v-if="user.entidad_extranjera">
        <base-input :disabled="user.disabled" type="text" label="Datos del documento que pruebe constitución"
          placeholder="Datos del documento que pruebe constitución" v-model="user.datos_prueba_constitucion">
        </base-input>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <ValidationProvider name="rfc" rules="required|length:12" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 12 caracteres'
        }">
          <base-input :disabled="user.disabled" type="text" label="RFC" placeholder="RFC" v-model="user.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- <button type="submit" class="btn btnForm orange-btn">Guardar</button> -->
    <b-button type="submit" class="btn-fill orange-btn bottom" size="lg" :disabled="user.disabled">Guardar</b-button>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";

import { CatNacionalidadesApi } from "@/api";

export default {
  name: "FormMoral",

  components: {
    Card
  },

  props: {
    user: { type: Object },
    ratificacion: { type: Boolean },
  },

  computed: {
    requiredIfNotRatificacion() {
      return this.ratificacion ? '' : 'required'
    }
  },

  watch: {
    "user.rfc": function (value) {
      if (!value) {
        this.user.rfc = "";

        return;
      }

      this.user.rfc = this.user.rfc.toUpperCase();
    },
  },

  created() {
    this.fetchNacionalidades();
  },

  methods: {
    async fetchNacionalidades() {
      const { value } = await CatNacionalidadesApi.list({
        page: 0,
        registersPerPage: 0
      });

      this.optionsNacionalidades = value;
    },

    entidadExtranjera(state) {
      this.$emit("state", state);
    }
  },

  data() {
    return {
      optionsNacionalidades: [],
      optionsEstados: [
        { text: "Aguascalientes", value: "Aguascalientes" },
        { text: "Baja California", value: "Baja California" },
        { text: "Baja California Sur", value: "Baja California Sur" },
        { text: "Campeche", value: "Campeche" },
        { text: "Chiapas", value: "Chiapas" },
        { text: "Chihuahua", value: "Chihuahua" },
        { text: "Coahuila", value: "Coahuila" },
        { text: "Colima", value: "Colima" },
        { text: "Ciudad de México", value: "Ciudad de México" },
        { text: "Durango", value: "Durango" },
        { text: "Estado de México", value: "Estado de México" },
        { text: "Guanajuato", value: "Guanajuato" },
        { text: "Guerrero", value: "Guerrero" },
        { text: "Hidalgo", value: "Hidalgo" },
        { text: "Jalisco", value: "Jalisco" },
        { text: "Michoacán ", value: "Michoacán " },
        { text: "Morelos", value: "Morelos" },
        { text: "Nayarit", value: "Nayarit" },
        { text: "Nuevo León", value: "Nuevo León" },
        { text: "Oaxaca", value: "Oaxaca" },
        { text: "Puebla", value: "Puebla" },
        { text: "Querétaro", value: "Querétaro" },
        { text: "Quintana Roo", value: "Quintana Roo" },
        { text: "San Luis Potosí", value: "San Luis Potosí" },
        { text: "Sinaloa", value: "Sinaloa" },
        { text: "Sonora", value: "Sonora" },
        { text: "Tabasco", value: "Tabasco" },
        { text: "Tamaulipas", value: "Tamaulipas" },
        { text: "Tlaxcala", value: "Tlaxcala" },
        { text: "Veracruz ", value: "Veracruz " },
        { text: "Yucatán", value: "Yucatán" },
        { text: "Zacatecas", value: "Zacatecas" }
      ],

      form: {
        denominacion_social: null,
        rfc: null,
        email: null,
        fecha_constitucion: null,
        nacionalidades: [],

        actividad: null,
        giro: null,
        objeto_social: null,
        instrumento_numero: null,
        instrumento_numero_fecha: null,
        instrumento_fedatario: null,
        instrumento_fedatario_nombre: null,
        instrumento_fedatario_numero: null,
        instrumento_fedatario_estado: null,
        instrumento_rpf_folio: null,
        instrumento_rpf_folio_fecha: null,
        instrumento_rpf_folio_lugar: null,

        entidad_extranjera: null,
        datos_prueba_constitucion: null
      }
    };
  }
};
</script>

<style>
.orange-btn {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

/* .btnForm {
  cursor: pointer;
  display: block;
  float: right;
  z-index: 3;
  position: absolute;
  right: 5px;
  top: 5px;
} */
.orange-btn:hover {
  background-color: #e87b31 !important;
  border-color: #e87b31 !important;
  color: white !important;
}

.orange-btn:active {
  background-color: #ea640b !important;
  border-color: #ea640b !important;
  color: white !important;
}

#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.bottom {
  position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100;
}
</style>
