<template>
  <div>
    <!-- <side-bar>
      <mobile-menu slot="content"></mobile-menu>
    </side-bar> -->

    <div class="">
      <TopNavbar />

      <!-- TODO: Cambiar la imagen por el video introductorio a CD -->
      <!-- <div class="contact-us landing full-screen"> -->
      <div class="contact-us">
        <!-- <div class="wrapper section content wrapper-full-page" id="index"> -->
        <div class="wrapper" id="index">
          <!-- <div class="text-center">
             <card id="cardText">
              <div class="container">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6 col-md-offset-2">
                    <h2 class="title text-warning" id="titulo">
                      ¡Hola! esto es Correduría Digital.
                    </h2>
                    <h3>
                      ¡Finalmente, la Fe Pública Mercantil al acceso de todos!
                    </h3>
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </card>
          </div> -->
        </div>
      </div>

      <!--
      <a id="como_funciona"></a> -->
      <ComoFunciona />

      <a id="servicios"></a>
      <Servicios />

      <a id="registro"></a>
      <SignIn id="registrar" class="registro" />

      <a id="contacto"></a>
      <Contacto id="contacto" />

      <Footer />
    </div>
  </div>
</template>

<script>
import TopNavbar from "src/layout/TopNavbar.vue";
import ComoFunciona from "src/pages/landingPage/ComoFunciona.vue";
import Servicios from "src/pages/landingPage/Servicios.vue";
// import Typography from "./Typography.vue";
import Card from "src/components/Cards/Card.vue";
import Contacto from "src/pages/landingPage/Contacto.vue";
// import QuienesSomos from "src/pages/landingPage/QuienesSomos.vue";

import SignIn from "src/pages/SignIn.vue";

import Footer from "src/layout/ContentFooter.vue";
import MobileMenu from "src/layout/MobileMenu.vue";

import config from "@/config";

export default {
  name: "Home",

  data: () => ({
    loading: false,
    show_side: false,
    publicPath: config.BASE_URL
    // disabled: false,
    // user: null,
    // password: null,
    // invalid: false,
    //  msg: "",
    // role: "",
  }),

  components: {
    TopNavbar,
    ComoFunciona,
    Servicios,
    // Typography,
    Contacto,
    Card,
    // QuienesSomos,
    MobileMenu,
    SignIn,
    Footer
  },

  methods: {
    toggleSidebar() {
      // console.log(this.$sidebar.displaySidebar);
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>

<style scoped>
.landing {
  background-color: #f7f7f8;
}

#wrapper {
  width: auto;
  margin: 0 auto;
}

#titulo {
  font-family: "Gill Sans MT", "Gill Sans", "Trebuchet MS", sans-serif !important;
}

#index {
  background-image: url("../../public/img/CD-Back2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
}

/* #cardText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.registro {
  background-color: #f7f7f8;
}
</style>
