<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-12">
            <ValidationProvider name="persona" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'La persona es obligatoria' }">
              <b-form-group id="input-group-1" label="Persona:" label-for="input-1">
                <b-form-select id="input-1" class="custom-select" v-model="tipoPersona" :options="optionsPersona">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccione una opción --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="tipoPersona === 'Física'">
          <div class="row mt-4">
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El nombre es obligatorio' }">
                <b-form-group id="input-group-2" label="Nombre:" label-for="input-2">
                  <b-form-input id="input-2" v-model="solicitantePersonaFisica.nombre" type="text"
                    placeholder="Nombre"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El apellido paterno es obligatorio' }">
                <b-form-group id="input-group-3" label="Apellido paterno:" label-for="input-3">
                  <b-form-input id="input-3" v-model="solicitantePersonaFisica.paterno" type="text"
                    placeholder="Paterno"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <b-form-group id="input-group-4" label="Apellido materno:" label-for="input-4">
                <b-form-input id="input-4" v-model="solicitantePersonaFisica.materno" type="text"
                  placeholder="Materno"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <b-form-group id="input-group-5" label="Actúa por su propio derecho:" label-for="input-5">
                <b-form-radio-group id="input-5" v-model="solicitantePersonaFisica.principal"
                  :checked="solicitantePersonaFisica.principal">
                  <b-form-radio :value="true">Si</b-form-radio>
                  <b-form-radio :value="false">No</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="mt-4" v-else-if="tipoPersona === 'Moral'">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'La denominacion social es obligatoria' }">
                <b-form-group id="input-group-6" label="Denominación social (Incluir tipo de sociedad):"
                  label-for="input-6">
                  <b-form-input id="input-6" v-model="solicitantePersonaMoral.denominacion_social" type="text"
                    placeholder="Denominación social"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <label class="col-md-12 fs-6 text-black-50 text-uppercase">Representante(s)</label>
          </div>
          <div v-for="(representante, index) in solicitantePersonaMoral.representantes" :key="index">
            <div class="row">
              <div class="col-md-4">
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                  :custom-messages="{ required: 'El nombre es obligatorio' }">
                  <b-form-group :id="'input-group-representante-nombre-' + index" label="Nombre:"
                    :label-for="'input-representante-nombre-' + index">
                    <b-form-input :id="'input-representante-nombre-' + index" v-model="representante.nombre" type="text"
                      placeholder="Nombre"></b-form-input>
                  </b-form-group>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                  :custom-messages="{ required: 'El apellido paterno es obligatorio', }">
                  <b-form-group :id="'input-group-representante-paterno-' + index" label="Apellido paterno:"
                    :label-for="'input-representante-materno-' + index">
                    <b-form-input :id="'input-representante-materno-' + index" v-model="representante.paterno"
                      type="text" placeholder="Paterno"></b-form-input>
                  </b-form-group>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <b-form-group :id="'input-group-representante-materno-' + index" label="Apellido materno:"
                  :label-for="'input-representante-materno-' + index">
                  <b-form-input :id="'input-representante-materno-' + index" v-model="representante.materno" type="text"
                    placeholder="Materno"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="row">
            <b-button v-if="canRemoveRepresentante" variant="danger" class="btn-fill spaceL" size="sm"
              @click="removeRepresentante">
              Remover representante
            </b-button>
            <b-button v-if="canAddRepresentante" variant="secondary" class="btn-fill spaceL" size="sm"
              @click="addRepresentante">
              Agregar otro representante
            </b-button>
          </div>
        </div>

        <div class="row mt-4"></div>

        <div class="mt-4 text-center">
          <button class="btn btn-fill float-right orange-btn" type="submit" :disabled="invalid">
            Guardar
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "AgregaRatificante",

  props: {
    currentSolicitante: { type: Object },
    maxNumberOfRepresentantes: {
      type: Number,
      default: () => 1
    },
  },

  data: function () {
    return ({
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],

      tipoPersona: null,

      solicitantePersonaFisica: {
        nombre: "",
        paterno: "",
        materno: "",
        principal: true,
      },
      solicitantePersonaMoral: {
        denominacion_social: "",
        representantes: []
      },
    });
  },

  created: function () {
    this.initProperties();
  },

  computed: {
    canAddRepresentante() {
      return this.solicitantePersonaMoral.representantes.length < this.maxNumberOfRepresentantes;
    },
    canRemoveRepresentante() {
      return this.solicitantePersonaMoral.representantes.length > 1;
    }
  },

  methods: {
    initProperties() {
      if (!this.currentSolicitante.persona) {
        this.tipoPersona = null;
        this.solicitantePersonaMoral.representantes.push({});

        return;
      }

      if (this.currentSolicitante.persona === "Física") {
        this.tipoPersona = "Física";
        this.solicitantePersonaFisica.nombre = this.currentSolicitante.nombre || "";
        this.solicitantePersonaFisica.paterno = this.currentSolicitante.paterno || "";
        this.solicitantePersonaFisica.materno = this.currentSolicitante.materno || "";
        this.solicitantePersonaFisica.principal = this.currentSolicitante.principal || false;

        return;
      }

      this.tipoPersona = "Moral";
      this.solicitantePersonaMoral.denominacion_social = this.currentSolicitante.denominacion_social || "";

      for (const representante of this.currentSolicitante.representantes) {
        this.solicitantePersonaMoral.representantes.push(representante);
      }
    },

    toggleRadio(radio) {
      this.solicitantePersonaFisica[radio] = !this.solicitantePersonaFisica[radio];
    },

    addRepresentante() {
      this.solicitantePersonaMoral.representantes.push({});
    },

    removeRepresentante() {
      this.solicitantePersonaMoral.representantes.pop();
    },

    onSubmit() {
      if (!this.tipoPersona) {
        return;
      }

      const solicitante = {
        id: this.currentSolicitante.id,
        persona: this.tipoPersona
      };

      if (this.tipoPersona === "Física") {
        Object.assign(solicitante, this.solicitantePersonaFisica);
      } else if (this.tipoPersona === "Moral") {
        Object.assign(solicitante, this.solicitantePersonaMoral);
      }

      this.$emit('submit-form', solicitante);
    }
  }
}
</script>

<style scoped></style>
