<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <h4 class="cardD">Complementa la información</h4>
    <!-- <card class="blue">
      <i class="fa fa-info"></i>
      Aquí van instrucciones.
      <p></p>
      <i class="fa fa-play"></i>
      Links a videos.
      <p></p>
      <i class="fa fa-question"></i>
      Datos de contacta a un asesor.
    </card> -->

    <h4 class="cardD">Firmantes</h4>
    <label class="leyenda">
      Edita cada uno de los firmante para llenar todos los datos necesarios.
    </label>
    <label class="leyenda">
      Serás enviado al apartado de comparecientes.
    </label>

    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDocumento)">
          <ModalDocumentos :currentDocumento="currentDocumento" :options="optionsDocs" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-documento" hide-footer
      id="modal-confirm-dialog-documento">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-documento')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <b-table hover :fields="fieldsCuadro" :items="formalizacion.firmantes">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editCompareciente(item)">
              <b-icon-pencil />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="accionista-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAccionista)">
          <ModalAccionista :currentAccionista="currentAccionista" @submit.prevent :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-accionista" hide-footer
      id="modal-confirm-dialog-accionista">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al accionista?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-accionista')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteAccionista">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="administrador-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAdministrador)">
          <ModalAdmin :currentAdministrador="currentAdministrador" @submit.prevent :complete="false" />
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-administrador" hide-footer
      id="modal-confirm-dialog-administrador">
      <h4 class="text-center">
        ¿Seguro que quiere eliminar al miembro?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-administrador')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteAdministrador">OK</b-button>
    </b-modal>

    <!--
    <h4 class="cardD mt-3">Cuadro accionario</h4>
    <label class="leyenda">
      Agrega los datos de cada uno de los accionistas.
    </label>
    <b-button :disabled="formalizacion.disabledComplementa" variant="success" class="btn-fill spaceL" size="sm"
      @click="addAccionista">+</b-button>
    <div class="row">
      <div class="col-12">
        <b-table ref="tableAccionistas" hover :fields="fieldsCuadroAccionistas"
          :items="formalizacion.accionistas_cuadro">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editAccionista(item)" :disabled="formalizacion.disabledComplementa">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
              @click="predeleteAccionista(item.id)" class="editBtn" :disabled="formalizacion.disabledComplementa">
              <b-icon-trash />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <h4 class="cardD mt-3">Administradores y apoderados</h4>
    <label class="leyenda">
      Agrega los datos de cada uno de los miembros.
    </label>
    <b-button :disabled="formalizacion.disabledComplementa" variant="success" class="btn-fill spaceL" size="sm"
      @click="addAdministrador">+</b-button>
    <div class="row">
      <div class="col-12">
        <b-table ref="tableAdministradores" hover :fields="fieldsCuadroAdmin"
          :items="formalizacion.administradores_cuadro">
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editAdministrador(item)" :disabled="formalizacion.disabledComplementa">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
              @click="predeleteAdministrador(item.id)" class="editBtn" :disabled="formalizacion.disabledComplementa">
              <b-icon-trash />
            </b-button>
          </template>
          <template #cell(listo)="{ item }">
            <div v-if="item.rfc">
              <div class="circle completado_green" v-if="item.rfc.length === 13">
                <span class="number"><i class="fa fa-check"> </i> </span>
              </div>
              <div class="circle completado_yellow" v-else>
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
            <div v-else>
              <div class="circle completado_yellow">
                <span class="number"><i class="fa fa-minus"> </i> </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
-->

    <div class="row mt-4">
      <div class="col-12">
        <h4 class="cardD">
          Documentos
          <b-button :disabled="formalizacion.disabledComplementa" variant="success" class="btn-fill" size="sm"
            @click="addDocumento">+</b-button>
        </h4>
      </div>
    </div>
    <div>
      <b-table hover :fields="fieldsDocus" :items="formalizacion.documentos">
        <template #cell(tipo)="{ item }">
          <div v-if="item.tipo === 'Otro'">
            <div v-b-tooltip.hover :title="item.descripcion">
              {{ item.tipo }}
            </div>
          </div>
          <div v-else>
            {{ item.tipo }}
          </div>
        </template>
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editDocumento(item)"
            class="editBtn" :disabled="formalizacion.disabledComplementa">
            <b-icon-pencil />
          </b-button>
          <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
            class="editBtn">
            <b-icon-eye />
          </b-button>
          <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)"
            class="editBtn" :disabled="formalizacion.disabledComplementa">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div v-if="!formalizacion.disabledComplementa">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!canValidate">Continuar</b-button>
      </div>
      <div v-else>
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="formalizacion.disabledComplementa">Continuar
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDocumentos from "@/views/clients/procesos/formalizacionactas/complementainfo/ModalDocumento.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import { CatDocumentosApi, FuncionesApi } from "@/api";
import ModalAccionista from "@/views/clients/procesos/formalizacionactas/complementainfo/ModalAccionista.vue";
import ModalAdmin from "@/views/clients/procesos/formalizacionactas/complementainfo/ModalAdmin.vue";

export default {
  name: "ComplementaInformacion",

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadro;
    },
    fieldsDocus() {
      let fieldsDocus = [
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDocus;
    },
    fieldsCuadroAccionistas() {
      let fieldsCuadroAccionistas = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "porcentaje", label: "Porcentaje", class: "text-center" },
        { key: "monto", label: "Monto", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadroAccionistas;
    },
    fieldsCuadroAdmin() {
      let fieldsCuadroAdmin = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "cargo", label: "Cargo", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
        { key: "listo", label: "", class: "text-center" }
      ];
      return fieldsCuadroAdmin;
    }
  },

  created() {
    this.initProperties();
    this.enableSave();
    this.getCatDocumentosPF();
  },

  watch: {
    "formalizacion.accionistas": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    },
    "formalizacion.documentos": {
      handler: function (val, oldVal) {
        this.enableSave();
      },
      deep: true
    }
  },

  components: {
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF,
    ModalAccionista,
    ModalAdmin
  },

  props: {
    formalizacion: { type: Object }
  },

  data() {
    return {
      canValidate: false,

      loading: false,
      currentDocumento: null,
      currentDocumentoId: null,

      documentos_originales: [],
      optionsDocs: [],

      currentAccionista: {},
      currentAccionistaId: null,
      currentAdministrador: {},
      currentAdministradorId: null
    };
  },

  methods: {
    initProperties() {
      if (!this.formalizacion.accionistas) {
        this.formalizacion.accionistas = [];
      }

      if (!this.formalizacion.accionistas_cuadro) {
        this.formalizacion.accionistas_cuadro = [];
      }

      if (!this.formalizacion.administradores_cuadro) {
        this.formalizacion.administradores_cuadro = [];
      }
    },

    async getCatDocumentosPF() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "formalizacion"
        });
        value.forEach(element => {
          // Se filtran: 
          // FAD
          // Póliza PDF
          // Póliza Word
          // Póliza generada
          if ([88, 89, 90, 91].includes(element.id)) {
            return;
          }

          const obj = { text: element.documento, value: element.id };
          this.optionsDocs.push(obj);
        });
        this.setTipos();
      } catch (error) {
        // this.loading = false;
      }
    },

    editCompareciente(item) {
      let accion = "newFormalizacion";
      // if (this.formalizacion.accion === "editFormalizacion") {
      //   accion = "editFormalizacion";
      // }
      this.$router.push({
        path: `/dashboard/comparecientes/editcomparecientes/${item.id}`,
        query: {
          proceso: "addformalizacion",
          procesoId: this.formalizacion.id,
          accion: accion
        }
      });
    },

    setTipos() {
      for (let r = 0; r < this.formalizacion.documentos.length; r++) {
        let index = this.optionsDocs.findIndex(o => o.value == this.formalizacion.documentos[r].cat_documentos_id);

        this.formalizacion.documentos[r].tipo = this.optionsDocs[index].text;
      }
    },

    enableSave() {
      let count = 0;

      this.formalizacion.firmantes.forEach(item => {
        if (item.rfc) {
          if (item.rfc.length !== 13) {
            count++;
          }
        } else {
          count++;
        }
      });

      this.formalizacion.accionistas.forEach(item => {
        if (item.rfc) {
          if (item.rfc.length !== 13) {
            count++;
          }
        } else {
          count++;
        }
      });

      if (this.formalizacion.accionistas.length < 2) {
        count++;
      }

      if (this.formalizacion.documentos.length < 2) {
        count++;
      }

      if (count) {
        this.canValidate = false;

        return;
      }

      this.canValidate = true;
    },

    addAccionista() {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = {};
    },

    editAccionista(item) {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = item;
    },

    putAccionista() {
      if (!this.currentAccionista.id) {
        let id = this.formalizacion.accionistas.length + 1;
        this.currentAccionista.id = id;
        this.currentAccionista.tipo = "Accionista";
        var a = Object.assign({}, this.currentAccionista);
        this.formalizacion.accionistas.push(a);
        this.formalizacion.accionistas_cuadro.push(a);
      } else {
        let index = this.formalizacion.accionistas.findIndex(
          o => o.id === this.currentAccionista.id
        );
        var a = Object.assign({}, this.currentAccionista);
        this.formalizacion.accionistas.splice([index], 1, a);

        let index_2 = this.formalizacion.accionistas.findIndex(
          o => o.id === this.currentAccionista.id
        );
        var b = Object.assign({}, this.currentAccionista);
        this.formalizacion.accionistas_cuadro.splice([index_2], 1, b);
      }
      this.$refs["accionista-modal"].hide();
      this.$refs.tableAccionistas.refresh();
    },

    predeleteAccionista(id) {
      this.currentAccionistaId = id;
      this.$refs["modal-confirm-dialog-accionista"].show();
    },

    deleteAccionista() {
      let index2 = this.formalizacion.accionistas.findIndex(
        o => o.id === this.currentAccionistaId
      );
      this.formalizacion.accionistas.splice(index2, 1);

      let index = this.formalizacion.accionistas_cuadro.findIndex(
        o => o.id === this.currentAccionistaId
      );
      this.formalizacion.accionistas_cuadro.splice(index, 1);

      this.$refs["modal-confirm-dialog-accionista"].hide();
      this.$refs.tableAccionistas.refresh();
    },

    addAdministrador() {
      this.$refs["administrador-modal"].show();
      this.currentAdministrador = {};
    },

    editAdministrador(item) {
      this.$refs["administrador-modal"].show();
      this.currentAdministrador = item;
    },

    putAdministrador() {
      if (!this.currentAdministrador.id) {
        let id = this.formalizacion.accionistas.length + 1;
        this.currentAdministrador.id = id;
        this.currentAdministrador.tipo = "Administrador";
        var a = Object.assign({}, this.currentAdministrador);
        this.formalizacion.accionistas.push(a);

        this.formalizacion.administradores_cuadro.push(a);
      } else {
        let index = this.formalizacion.accionistas.findIndex(
          o => o.id === this.currentAdministrador.id
        );
        var a = Object.assign({}, this.currentAdministrador);
        this.formalizacion.accionistas.splice([index], 1, a);

        let index_2 = this.formalizacion.accionistas.findIndex(
          o => o.id === this.currentAdministrador.id
        );
        var b = Object.assign({}, this.currentAdministrador);
        this.formalizacion.administradores_cuadro.splice([index_2], 1, b);
      }
      this.$refs["administrador-modal"].hide();
      this.$refs.tableAdministradores.refresh();
    },

    predeleteAdministrador(id) {
      this.currentAdministradorId = id;
      this.$refs["modal-confirm-dialog-administrador"].show();
    },

    deleteAdministrador() {
      let index2 = this.formalizacion.accionistas.findIndex(
        o => o.id === this.currentAdministradorId
      );
      this.formalizacion.accionistas.splice(index2, 1);

      let index = this.formalizacion.administradores_cuadro.findIndex(
        o => o.id === this.currentAdministradorId
      );
      this.formalizacion.administradores_cuadro.splice(index, 1);
      this.$refs["modal-confirm-dialog-administrador"].hide();
      this.$refs.tableAdministradores.refresh();
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    async postDocumento() {
      this.$refs["documento-modal"].hide();
      this.loading = true;

      await this.addFile();

      const index_s = this.optionsDocs.findIndex(
        o => o.text === this.currentDocumento.tipo
      );
      this.currentDocumento.cat_documentos_id = this.optionsDocs[index_s].value;

      if (this.currentDocumento.id === undefined) {
        let id = this.formalizacion.documentos.length + 1;
        this.currentDocumento.id = id;

        var a = Object.assign({}, this.currentDocumento);
        this.formalizacion.documentos.push(a);
      } else if (this.currentDocumento.id != undefined) {
        const index = this.formalizacion.documentos.findIndex(
          o => o.id === this.currentDocumento.id
        );
        var a = Object.assign({}, this.currentDocumento);
        this.formalizacion.documentos.splice([index], 1, a);
      }

      this.loading = false;
    },

    async addFile() {
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(
          this.formalizacion.id,
          "documentosFormalizaciones",
          formData,
          {}
        );
        this.currentDocumento.documento = value;
      } catch (error) {
        //
      }
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }
    },

    editDocumento(documento) {
      this.$refs["documento-modal"].show();
      this.currentDocumento = documento;
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog-documento"].show();
    },

    async deleteDocumento() {
      let index2 = this.formalizacion.documentos.findIndex(
        o => o.id === this.currentDocumentoId
      );
      this.currentDocumento = this.formalizacion.documentos[index2];
      // await this.deleteFile(this.currentDocumentoId);
      await this.deleteFile(this.formalizacion.id);

      this.formalizacion.documentos.splice(index2, 1);
      this.$refs["modal-confirm-dialog-documento"].hide();
    },

    async deleteFile(id) {
      try {
        this.loading = true;
        var data = {
          url: this.currentDocumento.documento,
          id: id
        };

        await FuncionesApi.deleteFile("documentosFormalizaciones", data, {});
      } catch (error) {
        //
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.blue {
  background-color: #effbff;
  border-color: #effbff !important;
  text-align: justify;
}

.bottom2 {
  float: right;
}

.leyenda {
  text-transform: lowercase !important;
  color: #79cff7;
}

.leyenda:first-letter {
  text-transform: capitalize;
}

.spaceL {
  margin-left: 1%;
}

.completado_green {
  background-color: #9acd32 !important;
}

.led-green {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #43eb34;
  /* #690 */
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
