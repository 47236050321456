<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <router-link class="nav-link" :to="'/'">
        <div class="logo-img">
          <img src="/img/cp_logooficial_color.png" alt="" style="max-width: 100px" />
        </div>
      </router-link>

      <button type="button" class="navbar-toggler navbar-toggler-right" :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Home', hash: '#como_funciona' }">¿Cómo funciona?
              <!-- v-scroll-to="'#como_funciona'"  to="#"-->
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Home', hash: '#servicios' }">Servicios
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <router-link class="nav-link" :to="'/signin'">Registro</router-link> -->
            <router-link class="nav-link" :to="{ name: 'Home', hash: '#registro' }">Registro
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Home', hash: '#contacto' }">Contacto</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/login'">Iniciar sesión</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },

  data() {
    return {
      activeNotifications: false,
      // loading: false,
    };
  },

  methods: {
    scrollTo(ancla) {
      this.$router.push({ hash: ancla });
      // this.$router.push(`/#${ancla}`);
      // this.$router.push("/");
      // this.$scrollTo(ancla, 0, { offset: -120 });
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // toggleNotificationDropDown() {
    //   this.activeNotifications = !this.activeNotifications;
    // },
    // closeDropDown() {
    //   this.activeNotifications = false;
    // },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style></style>
