<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putCompareciente)">
          <ModalValidaCompareciente :currentCompareciente="currentCompareciente" :cotejo="cotejo" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <h4 class="cardD">Valida Información</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="cotejo.solicitantes">
        <template #cell(acciones)="{ item }">
          <b-button v-if="cotejo.validaSolicitantes != 1" variant="warning" size="sm" v-b-tooltip.hover title="Editar"
            class="editBtn" @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkComparecientes()"
          :disabled="cotejo.validaSolicitantes == 1">Validar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalValidaCompareciente from "@/views/clients/procesos/cotejo/infoBase/modales/ModalValidaCompareciente.vue";

export default ({
  name: "ComplementaInformacion",

  components: {
    ModalValidaCompareciente
  },

  props: {
    cotejo: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "representante", label: "Representante", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    }
  },

  data() {
    return {
      currentCompareciente: null
    }
  },

  methods: {
    editCompareciente(item) {
      this.$refs["compareciente-modal"].show();
      this.currentCompareciente = item;
    },

    putCompareciente() {
      let nombreCompleto = ""
      if (this.currentCompareciente.persona === 'Física') {
        nombreCompleto = this.currentCompareciente.nombre + ' ' + this.currentCompareciente.paterno + ' ' + this.currentCompareciente.materno;
      } else {
        nombreCompleto = this.currentCompareciente.denominacion_social;
      }

      if (!this.currentCompareciente.id) {
        const id = this.cotejo.solicitantes.length + 1;
        this.currentCompareciente.id = id;
        this.cotejo.solicitantes.push(this.currentCompareciente);
      }

      const index = this.cotejo.solicitantes.findIndex((o) => o.id === this.currentCompareciente.id);

      this.cotejo.solicitantes[index].nombreCompleto = nombreCompleto;

      this.$refs.table.refresh();
      this.$refs["compareciente-modal"].hide();
    },

    checkComparecientes() {
      this.$emit('allChecksPassed');
    }
  }
})
</script>
