<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Procesos</b-breadcrumb-item>
      <b-breadcrumb-item active>Validar Proceso</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
      <!-- @click="selected('go')" -->
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="selected('go')">OK</b-button>
      <!-- @click="selected('go')" -->
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <!-- <b-col class="col-4">
          <card>
            <TreeNavigation @node="selected" :user="'Staff'" />
          </card>
        </b-col> -->
        <b-col class="col-3">
          <card>
            <TreeM @node="selected" :constitutiva="constitutiva" :selected-component="currentComponentBody" :user="'Staff'" />
          </card>
        </b-col>
        <b-col class="col-9">
          <card>
            <b-row>
              <component v-bind:is="currentComponentInfoHead" class="tab"></component>
            </b-row>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'DatosGenerales'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosGenerales :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :optionsObjetoSocial="optionsObjetoSocial" @validaForm="validar('DatosGenerales')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosCapital'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosCapital :constitutiva="constitutiva" @submit.prevent @node="selected"
                        @validaForm="validar('DatosCapital')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DatosOrganos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DatosOrganos :constitutiva="constitutiva" @submit.prevent @node="selected"
                        @validaForm="validar('DatosOrganos')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'RepresentantesYApoderados'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <RepresentantesYApoderados :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_facultades="cat_facultades" @validaForm="validar('RepresentantesYApoderados')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EntidadFederativa'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <EntidadFederativa :constitutiva="constitutiva" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" @validaForm="validar('EntidadFederativa')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'CapitulosEspeciales'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <CapitulosEspeciales :constitutiva="constitutiva" @submit.prevent @node="selected"
                        @validaForm="validar('CapitulosEspeciales')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Cotizacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <Cotizacion :constitutiva="constitutiva" @submit.prevent @node="selected" :cat_gastos="cat_gastos"
                        @validaForm="validar('Cotizacion')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'ComplementaInformacion'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <ComplementaInformacion :constitutiva="constitutiva" @submit.prevent @node="selected"
                        :cat_facultades="cat_facultades" @validaForm="validar('ComplementaInformacion')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Expediente'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <Expediente :constitutiva="constitutiva" @submit.prevent @node="selected"
                        @validaForm="validar('Expediente')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'CitaFirma'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <CitaFirma :constitutiva="constitutiva" @submit.prevent @node="selected"
                        @validaForm="validar('CitaFirma')" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'GeneraDocumento'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <GeneraDocumento :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'AsignarCorreduria'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <AsignarCorreduria :constitutiva="constitutiva" :catCorredurias="catCorredurias" @submit.prevent
                        @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DenominacionSocial'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <DenominacionSocial :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'DocFirmado'">
                  <!-- <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(saveConstitucion)"> -->
                  <DocFirmado :constitutiva="constitutiva" @submit.prevent @node="selected" />
                  <!-- </form>
                </ValidationObserver> -->
                </div>
                <div v-if="currentComponentBody === 'Terminado'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveConstitucion)">
                      <Terminado :constitutiva="constitutiva" @submit.prevent @node="selected" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import TreeNavigation from "@/views/clients/procesos/constitucion/TreeNavigation.vue";
import TreeM from "@/views/clients/procesos/constitucion/TreeM.vue";
import InfoBase from "@/views/clients/procesos/constitucion/infoBase/InfoBase.vue";
import AsignarCorreduria from "@/views/staff/tasks/validaciones/constitucionsociedades/TabAsignarCorreduria.vue";
import CapitulosEspeciales from "@/views/staff/tasks/validaciones/constitucionsociedades/TabCapitulosEspeciales.vue";
import CitaFirma from "@/views/staff/tasks/validaciones/constitucionsociedades/TabCitaFirma.vue";
import ComplementaInformacion from "@/views/staff/tasks/validaciones/constitucionsociedades/TabComplementaInformacion.vue";
import Cotizacion from "@/views/staff/tasks/validaciones/constitucionsociedades/TabCotizacion.vue";
import DatosCapital from "@/views/staff/tasks/validaciones/constitucionsociedades/TabDatosCapital.vue";
import DatosGenerales from "@/views/staff/tasks/validaciones/constitucionsociedades/TabDatosGenerales.vue";
import DatosOrganos from "@/views/staff/tasks/validaciones/constitucionsociedades/TabDatosOrganos.vue";
import DenominacionSocial from "@/views/staff/tasks/validaciones/constitucionsociedades/TabDenominacionSocial.vue";
import DocFirmado from "@/views/staff/tasks/validaciones/constitucionsociedades/TabDocFirmado.vue";
import EntidadFederativa from "@/views/staff/tasks/validaciones/constitucionsociedades/TabEntidadFederativa.vue";
import Expediente from "@/views/staff/tasks/validaciones/constitucionsociedades/TabExpediente.vue";
import GeneraDocumento from "@/views/staff/tasks/validaciones/constitucionsociedades/TabGeneraDocumento.vue";
import RepresentantesYApoderados from "@/views/staff/tasks/validaciones/constitucionsociedades/TabRepresentantesYApoderados.vue";
import Terminado from "@/views/staff/tasks/validaciones/constitucionsociedades/TabTerminado.vue";

import {
  CatDocumentosApi,
  CatFacultadesApi,
  CatFormulariosConstitucionSociedadesApi,
  CatGastosApi,
  CatObjetosSocialesApi,
  ComparecientesApi,
  ConstitucionSociedadesApi,
  CotizacionesApi,
  FuncionesApi
} from "@/api";

import Storage from "@/utils/storage";

const storage = new Storage();

export default {
  name: "ValidConstitucionSociedades",

  components: {
    TreeM,

    InfoBase,
    DatosGenerales,
    DatosCapital,
    DatosOrganos,
    RepresentantesYApoderados,
    EntidadFederativa,
    CapitulosEspeciales,
    Cotizacion,

    Expediente,
    CitaFirma,
    GeneraDocumento,
    ComplementaInformacion,
    DenominacionSocial,
    AsignarCorreduria,
    DocFirmado,
    Terminado
  },

  beforeRouteLeave(to, from, next) {
    // this.$refs["modal-confirm-dialog-route"].show();
    // this.next = next;
    if (to.name == "EditComparecientes") {
      this.next = next;
      this.beforeLeave(true);
    } else {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
  },

  created() {
    this.getCatDocumentosPF();
    this.getCatDocumentosPM();
    this.getCatDocumentosConstitucion();
    this.getCatObjetosSociales();
    this.getConstitucion();
  },

  data: () => ({
    next: null,
    prenode: null,

    loading: false,
    currentComponentInfoHead: "InfoBase",
    currentComponentBody: "DatosGenerales",
    cat_facultades: [],
    cat_gastos: [],
    optionsObjetoSocial: [],
    emailUserStaff: null,

    catCorredurias: [],

    optionsFisicas: [],
    optionsMorales: [],
    optionsConstitucionDocs: [],

    originalConstitutiva: {},

    constitutiva: {
      roleStaff: false,
      denom_otra: null,

      disabled: true, //los que afectan la cotizacion
      disabledGenerales: false,
      disabledCapital: false,
      disabledOrganos: false,
      disabledRepresentantes: false,
      disabledEspeciales: false,
      disabledEntidad: false,
      disabledCotizacion: false,
      disabledComplementa: false,
      // disabledDocumentos: false,
      disabledExpediente: false,
      disabledCita: true,
      disabledDenominacion: false,
      disbaledGeneraDocs: false,
      disabledTerminado: false,

      validoGenerales: false,
      validoCapital: false,
      validoOrganos: false,
      validoRepresentantes: false,
      validoCapitulos: false,
      validoEntidad: false,
      validoCotizacion: false,
      validoComplementa: false,
      validoDenominacion: false,
      validoExpediente: false,
      validoGenera: false,
      validoCita: false,
      validoDocListo: false,
      validoTerminado: false,

      accion: "valid", //para el regreso de comparecientes

      id: null,
      clientes_id: null,
      tipo_societario: null,
      denominaciones_sociales: [],
      denominaciones_sociales_1: null,
      denominaciones_sociales_2: null,
      denominaciones_sociales_3: null,
      tiene_capital_variable: false,
      autoriza_cd_denominacion: null,
      objeto_social: null,
      objeto_social_otro: null,
      objeto_actividad: null,

      capital_fijo: null,
      capital_variable: null,
      accionistas: [],
      capital_extranjero: null,

      tipo_administracion: null,
      administradores: [],
      presidente_voto: null,
      vigilancia: [],
      cotizacion: [],

      apoderados: [],

      link_calendario: null,
      // corredores: [],
      corredurias_id: null,
      firma_oficina: true,
      selectedEntidad: null,
      selectedMunicipio: null,
      selectedCorreduria: null,
      optionsEntidades: [],
      optionsMunicipios: [],
      optionsColonias: [],

      reglas_control: null,
      reglas_asambleas: null,

      titulos_acciones: null,
      libros_corporativos: null,
      cotizaciones_id: null,

      cupones_id: null,
      cupones_referencia_id: null,
      precio_total: 0, //para guardarlo, convierte a float
      pagado: null,
      folio_transaccion: null,
      gastos: [],
      subtotal_titulos: 0,
      subtotal_libros: 0,

      documentos: [],
      formularios: [],

      direccion_firma: null, //estos dos es hasta despues del pago y de haber firmado los docs firtuales fad
      fecha_firma: null,
      clausula_extranjeros: null,

      acciones_valor_nominal: true,
      exclusion_accionistas: null,

      numero_permiso: null,
      fecha_autoriza_se: null,

      cambios: [],

      domicilios: [],
      pais: null,
      entidadDomicilio: null,
      municipioDomicilio: null,

      documentos_proyecto: [],
      proyecto_generado: null,

      beneficiario_pld: null,
      beneficiario_cff: null,

      fad_procesos: [],

      entidad: null,
      delegacion_municipio: null,
      nombre_beneficiario_pld: null,
      nombre_beneficiario_cff: null,
    }
  }),

  methods: {
    async getCatDocumentosPF() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pf"
        });
        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsFisicas.push(obj);
        });
      } catch (error) {
        console.log(error);
        // this.loading = false;
      }
    },

    async getCatDocumentosPM() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pm"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsMorales.push(obj);
        });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getCatDocumentosConstitucion() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "constitucion_sociedades"
        });
        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsConstitucionDocs.push(obj);
        });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getCatObjetosSociales() {
      const { value } = await CatObjetosSocialesApi.list();
      value.forEach(element => {
        const obj = {
          text: element.objeto_social,
          value: element.objeto_social
        };
        this.optionsObjetoSocial.push(obj);
      });
      const last = { text: "Otro", value: "Otro" };
      this.optionsObjetoSocial.push(last);
    },

    async getCorredurias() {
      try {
        // let entidad = this.selectedEntidad;
        // let municipio = null;
        // if (entidad != "Ciudad de México") {
        //   municipio = this.selectedMunicipio;
        // }
        // console.log(entidad);
        const { value } = await FuncionesApi.getCorreduriasDomicilios({
          entidad: null,
          municipio: null
        });
        this.catCorredurias = value;

        value.forEach(element => {
          let exists = this.constitutiva.optionsEntidades.findIndex(
            x => x.text === element.entidad
          );
          if (exists == -1) {
            const obj = {
              text: element.entidad,
              value: element.entidad
            };
            this.constitutiva.optionsEntidades.push(obj);
          }
        });
      } catch { }
    },

    async getConstitucion() {
      this.loading = true;

      const id = this.$route.params.id;

      await this.getCorredurias();
      await FuncionesApi.checkFadKycsEstatus({
        proceso_id: parseInt(id),
        cat_servicios_id: 1
      });
      await FuncionesApi.checkFadProyectoConstitutivaEstatus({
        proceso_id: parseInt(id),
        cat_servicios_id: 1
      });

      let rolStaff = null;
      if (!this.emailUserStaff) {
        let emailUserStaff = storage.getItem("user");
        rolStaff = storage.getItem("role");
        this.emailUserStaff = emailUserStaff;
        // this.constitutiva.rolStaff = rolStaff;
      }
      if (rolStaff === "Staff Administrador") {
        this.constitutiva.disabled = false;
        this.constitutiva.roleStaff = true;
      }

      this.constitutiva.id = id;

      await this.getGastos();
      await this.getFacultades();

      try {
        const {
          link_calendario,
          tipo_societario,
          tiene_capital_variable,
          autoriza_cd_denominacion,
          objeto_social,
          objeto_actividad,
          capital_fijo,
          capital_variable,
          tipo_administracion,
          presidente_voto,
          firma_oficina,
          direccion_firma,
          fecha_firma,
          estatus,
          reglas_control,
          reglas_asambleas,
          capital_extranjero,
          clientes_id,
          denominaciones_sociales,
          accionistas,
          administradores,
          vigilancia,
          apoderados,
          // corredores,
          corredurias_id,
          cotizacion,
          gastos,
          formularios,
          documentos,
          clausula_extranjeros,
          acciones_valor_nominal,
          exclusion_accionistas,
          domicilios,

          titulos_acciones,
          libros_corporativos,
          cotizaciones_id,
          beneficiario_pld,
          beneficiario_cff,
          // numero_permiso,
          // fecha_autoriza_se
          fad_procesos,

          entidad,
          delegacion_municipio,
          folio,
          nombre_beneficiario_pld,
          nombre_beneficiario_cff
        } = await ConstitucionSociedadesApi.detail(id);

        this.constitutiva.tipo_societario = tipo_societario;
        this.constitutiva.fad_procesos = fad_procesos;
        this.constitutiva.entidad = entidad;
        this.constitutiva.delegacion_municipio = delegacion_municipio;
        this.constitutiva.formularios = formularios;
        this.constitutiva.folio = folio;
        this.constitutiva.nombre_beneficiario_pld = nombre_beneficiario_pld;
        this.constitutiva.nombre_beneficiario_cff = nombre_beneficiario_cff;

        //checar cada disabled de acuerdo a valor valido en formularios
        for (let u = 0; u < formularios.length; u++) {
          if (formularios[u].valido === 1) {
            switch (formularios[u].formulario) {
              case "DatosGenerales":
                this.constitutiva.disabledGenerales = true;
                break;
              case "DatosCapital":
                this.constitutiva.disabledCapital = true;
                break;
              case "DatosOrganos":
                this.constitutiva.disabledOrganos = true;
                break;
              case "RepresentantesYApoderados":
                this.constitutiva.disabledRepresentantes = true;
                break;
              case "CapitulosEspeciales":
                this.constitutiva.disabledEspeciales = true;
                break;
              case "EntidadFederativa":
                this.constitutiva.disabledEntidad = true;
                break;
              case "Cotizacion":
                this.constitutiva.disabledCotizacion = true;
                break;
              case "ComplementaInformacion":
                this.constitutiva.disabledComplementa = true;
                break;
              // case "EntregaDocumentos":
              //   this.constitutiva.disabledDocumentos = true;
              //   break;
              case "Expediente":
                this.constitutiva.disabledExpediente = true;
                break;
              case "CitaFirma":
                this.constitutiva.disabledCita = true;
                break;
              case "Terminado":
                this.constitutiva.disabledTerminado = true;
                break;
              default:
                break;
            }
          }
        }

        this.validaEstatusForms();

        this.constitutiva.tiene_capital_variable = false;
        if (tiene_capital_variable === 1) {
          this.constitutiva.tiene_capital_variable = true;
        }
        this.constitutiva.autoriza_cd_denominacion = false;
        if (autoriza_cd_denominacion === 1) {
          this.constitutiva.autoriza_cd_denominacion = true;
        }

        this.cotizaciones_id = cotizaciones_id;
        this.constitutiva.cotizacion = cotizacion;

        this.constitutiva.titulos_acciones = false;
        if (titulos_acciones == 1) {
          this.constitutiva.titulos_acciones = true;
          let indexT = this.cat_gastos.findIndex(x => x.clave === "titulos_acciones");
          this.constitutiva.subtotal_titulos = this.cat_gastos[indexT].costo;
        }
        this.constitutiva.libros_corporativos = false;
        if (libros_corporativos === 1) {
          this.constitutiva.libros_corporativos = true;
          const indexT = this.cat_gastos.findIndex(x => x.clave === "libros_corporativos");
          this.constitutiva.subtotal_libros = this.cat_gastos[indexT].costo;
        }

        this.constitutiva.link_calendario = link_calendario;
        this.constitutiva.beneficiario_pld = beneficiario_pld;
        this.constitutiva.beneficiario_cff = beneficiario_cff;

        const objSocial = this.optionsObjetoSocial.find(x => x.text === objeto_social);

        if (objSocial) {
          this.constitutiva.objeto_social = objeto_social;
        } else {
          this.constitutiva.objeto_social = "Otro";
          this.constitutiva.objeto_social_otro = objeto_social;
        }

        this.constitutiva.objeto_actividad = objeto_actividad;
        this.constitutiva.capital_fijo = capital_fijo;
        this.constitutiva.capital_variable = capital_variable;
        this.constitutiva.tipo_administracion = tipo_administracion;

        this.constitutiva.presidente_voto = false;
        if (presidente_voto === 1) {
          this.constitutiva.presidente_voto = true;
        }
        this.constitutiva.firma_oficina = false;
        if (firma_oficina === 1) {
          this.constitutiva.firma_oficina = true;
        }
        this.constitutiva.direccion_firma = direccion_firma;
        this.constitutiva.fecha_firma = fecha_firma;
        // if (fecha_firma.length != null) {
        if (fecha_firma) {
          this.constitutiva.validoCita = true;
        }

        this.constitutiva.estatus = estatus;
        this.constitutiva.reglas_control = false;
        if (reglas_control === 1) {
          this.constitutiva.reglas_control = true;
        }
        this.constitutiva.reglas_asambleas = false;
        if (reglas_asambleas === 1) {
          this.constitutiva.reglas_asambleas = true;
        }
        this.constitutiva.capital_extranjero = false;
        if (capital_extranjero === 1) {
          this.constitutiva.capital_extranjero = true;
        }
        this.constitutiva.clientes_id = clientes_id;

        this.constitutiva.clausula_extranjeros = false;
        if (clausula_extranjeros === 1) {
          this.constitutiva.clausula_extranjeros = true;
        }

        this.constitutiva.acciones_valor_nominal = false;
        if (acciones_valor_nominal === 1) {
          this.constitutiva.acciones_valor_nominal = true;
        }
        this.constitutiva.exclusion_accionistas = false;
        if (exclusion_accionistas === 1) {
          this.constitutiva.exclusion_accionistas = true;
        }

        this.constitutiva.pais = "México";
        if (domicilios.length > 0) {
          this.constitutiva.entidadDomicilio = domicilios[0].entidad;
          this.constitutiva.municipioDomicilio =
            domicilios[0].delegacion_municipio;
        }

        this.constitutiva.denominaciones_sociales_1 =
          denominaciones_sociales[0].denominacion_social;
        this.constitutiva.denominaciones_sociales_2 =
          denominaciones_sociales[1].denominacion_social;
        this.constitutiva.denominaciones_sociales_3 =
          denominaciones_sociales[2].denominacion_social;

        for (let o = 0; o < denominaciones_sociales.length; o++) {
          if (denominaciones_sociales[o].autorizada === 1) {
            this.constitutiva.numero_permiso =
              denominaciones_sociales[o].numero_permiso;
            this.constitutiva.fecha_autoriza_se = denominaciones_sociales[
              o
            ].fecha_autoriza_se.substring(0, 10);
          }

          denominaciones_sociales[o].numero_permiso = null;
          denominaciones_sociales[o].fecha_autoriza_se = null;
        }
        this.constitutiva.denominaciones_sociales = denominaciones_sociales;

        for (let y = 0; y < accionistas.length; y++) {
          if (accionistas[y].persona === "Física") {
            accionistas[y].persona = "Persona Física";
            accionistas[y].nombreCompleto = `${accionistas[y].nombre} ${accionistas[y].paterno
              } ${accionistas[y].materno}`;
          } else if (accionistas[y].persona === "Moral") {
            accionistas[y].persona = "Persona Moral";
            accionistas[y].nombreCompleto = `${accionistas[y].denominacion_social
              }`;
          }
          accionistas[y].porcentaje = accionistas[y].porcentaje_capital;

          for (let r = 0; r < accionistas[y].documentos.length; r++) {
            accionistas[y].documentos[r].nombreFile =
              accionistas[y].documentos[r].documento.substring(accionistas[y].documentos[r].documento.lastIndexOf("/") + 1);

            let tipo = this.optionsFisicas.find(x => x.value == accionistas[y].documentos[r].cat_documentos_id);

            if (!tipo) {
              tipo = this.optionsMorales.find(x => x.value == accionistas[y].documentos[r].cat_documentos_id);
            }

            accionistas[y].documentos[r].tipo = tipo.text;
          }
        }

        this.constitutiva.accionistas = accionistas;
        this.constitutiva.administradores = administradores;
        this.constitutiva.vigilancia = vigilancia;

        for (let i = 0; i < apoderados.length; i++) {
          for (let k = 0; k < apoderados[i].facultades.length; k++) {
            let obj = this.cat_facultades.find(
              x => x.id === apoderados[i].facultades[k].facultades_id
            );
            apoderados[i].facultades[k] = obj.facultad;
          }
        }
        this.constitutiva.apoderados = apoderados;

        //Buscar en cat_entidades el corredurias_id y ponerlo en los selected
        this.constitutiva.corredurias_id = corredurias_id;
        // if (corredurias_id != 0) {
        //   let correduriaSelected = this.catCorredurias.find(
        //     x => x.corredurias_id == corredurias_id
        //   );
        //   this.constitutiva.selectedEntidad = correduriaSelected.entidad;
        //   this.constitutiva.selectedMunicipio =
        //     correduriaSelected.delegacion_municipio;
        //   this.constitutiva.selectedColonia = correduriaSelected.colonia;
        //   this.constitutiva.selectedCorreduria =
        //     correduriaSelected.corredurias_id;
        // }
        this.constitutiva.selectedEntidad = entidad;
        this.constitutiva.selectedMunicipio = delegacion_municipio;
        this.constitutiva.selectedCorreduria = corredurias_id;

        if (cotizacion != null) {
          // this.constitutiva.cupones_id = cotizacion.cupones_id;
          // this.constitutiva.cupones_referencia_id =
          //   cotizacion.cupones_referencia_id;
          this.constitutiva.precio_total = cotizacion.precio_total;
          this.constitutiva.pagado = cotizacion.pagado;
          this.constitutiva.folio_transaccion = cotizacion.cupones_id;

          // this.constitutiva.titulos_acciones = false;
          // if (cotizacion.titulos_acciones === 1) {
          //   this.constitutiva.titulos_acciones = true;
          //   let indexT = this.cat_gastos.findIndex(
          //     x => x.clave === "titulos_acciones"
          //   );
          //   this.constitutiva.subtotal_titulos = this.cat_gastos[indexT].costo;
          // }
          // this.constitutiva.libros_corporativos = false;
          // if (cotizacion.libros_corporativos === 1) {
          //   this.constitutiva.libros_corporativos = true;
          //   let indexT = this.cat_gastos.findIndex(
          //     x => x.clave === "libros_corporativos"
          //   );
          //   this.constitutiva.subtotal_libros = this.cat_gastos[indexT].costo;
          // }
        }


        for (let r = 0; r < documentos.length; r++) {
          documentos[r].nombreFile = documentos[r].documento.substring(documentos[r].documento.lastIndexOf("/") + 1);

          let tipo = this.optionsConstitucionDocs.find(x => x.value == documentos[r].cat_documentos_id);

          if (!tipo) {
            tipo = this.optionsFisicas.find(x => x.value == documentos[r].cat_documentos_id);
          }

          if (!tipo) {
            tipo = this.optionsMorales.find(x => x.value == documentos[r].cat_documentos_id);
          }

          // Excluir las facturas
          if (documentos[r].cat_documentos_id === '94') {
            tipo = { text: "Factura" };
          }

          // TODO Refactorizar este if
          if (!tipo) {
            if (accionistas[0].documentos
              && accionistas[0].documentos[0]
              && accionistas[0].documentos[0].cat_documentos_id) {
              tipo = this.optionsFisicas.find(x => x.value == accionistas[0].documentos[0].cat_documentos_id);
            }
          }

          // Cat documento no identificado, Esto no deberia suceder!
          if (!tipo) {
            tipo = { text: "" };
          }

          documentos[r].tipo = tipo.text;
        }
        this.constitutiva.documentos = documentos;

        let documentos_proyecto = [];
        let documentos_kycs = [];
        for (let w = 0; w < documentos.length; w++) {
          if (
            //"Proyecto PDF"
            documentos[w].cat_documentos_id === "24" ||
            documentos[w].cat_documentos_id === "25" //"Proyecto Word"
          ) {
            //proyectos cargados por correduria
            documentos_proyecto.push(documentos[w]);
          } else if (documentos[w].cat_documentos_id === "23") {
            // "Proyecto generado"
            //proyecto generado por nosotros automatico
            this.constitutiva.proyecto_generado = documentos[w];
          } else if (documentos[w].cat_documentos_id === "22") {
            // "KYC"
            documentos_kycs.push(documentos[w]);
          }
        }
        this.constitutiva.documentos_proyecto = documentos_proyecto;
        this.constitutiva.documentos_kycs = documentos_kycs;

        //si estan en consit.cat_gastos, están checked
        for (let r = 0; r < gastos.length; r++) {
          // console.log(this.cat_gastos);
          let obj_en_cat = this.cat_gastos.find(
            x => x.id === gastos[r].cat_gastos_id
          );
          if (obj_en_cat) {
            let objGasto = this.constitutiva.gastos.find(
              x => x.id === obj_en_cat.id
            );
            if (!objGasto) {
              var a = Object.assign({}, obj_en_cat);
              this.constitutiva.gastos.push(a);
            }
          }
        }

        //node cuando regresan de comparecientes
        const query = Object.assign({}, this.$route.query);
        if (query) {
          let node = query.node;
          if (node) {
            this.currentComponentBody = node;
          }
        }

        const originalConstitutiva = JSON.parse(JSON.stringify(this.constitutiva));
        this.originalConstitutiva = originalConstitutiva;

        await this.colocaEstatusKYCs();
      } catch (error) {
        console.log(error);

        this.$toast.error("Ocurrió un error al recibir los datos del proceso.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async colocaEstatusKYCs() {
      //poner estatus en cada docs_kycs
      for (let i = 0; i < this.constitutiva.documentos_kycs.length; i++) {
        let nombreFile = this.constitutiva.documentos_kycs[i].nombreFile;
        //de nombreFile obtiene la string que está entre el guion bajo y el punto
        let nombre_completo = nombreFile.substring(
          nombreFile.lastIndexOf("_") + 1,
          nombreFile.lastIndexOf(".")
        );
        let index = await this.findIndexAccio(nombre_completo);

        // console.log(index);
        if (index) {
          let compar_id = this.constitutiva.accionistas[index].id;
          let indexFad = this.constitutiva.fad_procesos.findIndex(
            o => o.comparecientes_id === compar_id
          );

          if (indexFad != -1) {
            let estatus_id = this.constitutiva.fad_procesos[indexFad]
              .cat_fad_responses_id;

            this.constitutiva.documentos_kycs[
              i
            ].cat_fad_responses_id = estatus_id;

            //luego ya teniendo el cat_fad_responses_id, llamo a request que traiga name de este estatus y se lo pongo en .estatus
            let estatus_name = await FuncionesApi.getCatFadResponseRespuesta(
              estatus_id
            );
            this.constitutiva.documentos_kycs[i].estatus = estatus_name;

            var a = Object.assign({}, this.constitutiva.documentos_kycs[i]);
            this.constitutiva.documentos_kycs.splice([i], 1, a);
          } else {
            this.constitutiva.documentos_kycs[i].cat_fad_responses_id = null;
          }
        }
      }
    },

    async findIndexAccio(nombre_completo) {
      let index;
      let nombre_completo_nospace = nombre_completo.replaceAll(" ", "");
      this.constitutiva.accionistas.forEach(accio => {
        let nombre_accio = accio.nombreCompleto.replaceAll(" ", "");
        if (nombre_accio === nombre_completo_nospace) {
          index = this.constitutiva.accionistas.findIndex(
            o => o.id === accio.id
          );
        }
      });
      return index;
    },

    validaEstatusForms() {
      for (let i = 0; i < this.constitutiva.formularios.length; i++) {
        if (this.constitutiva.formularios[i].valido === 1) {
          switch (this.constitutiva.formularios[i].formulario) {
            case "DatosGenerales":
              this.constitutiva.validoGenerales = true;
              break;
            case "DatosCapital":
              this.constitutiva.validoCapital = true;
              break;
            case "DatosOrganos":
              this.constitutiva.validoOrganos = true;
              break;
            case "RepresentantesYApoderados":
              this.constitutiva.validoRepresentantes = true;
              break;
            case "CapitulosEspeciales":
              this.constitutiva.validoCapitulos = true;
              break;
            case "EntidadFederativa":
              this.constitutiva.validoEntidad = true;
              break;
            case "Cotizacion":
              this.constitutiva.validoCotizacion = true;
              break;
            case "ComplementaInformacion":
              this.constitutiva.validoComplementa = true;
              break;
            case "DenominacionSocial":
              this.constitutiva.validoDenominacion = true;
              break;
            // case "Expediente":
            //   this.constitutiva.validoExpediente = true;
            //   break;
            case "GeneraDocumento":
              this.constitutiva.validoGenera = true;
              break;
            case "CitaFirma":
              this.constitutiva.validoCita = true;
              break;
            case "Terminado":
              this.constitutiva.validoTerminado = true;
              break;
          }
        }
      }
    },

    async getFacultades() {
      try {
        const { value } = await CatFacultadesApi.list({
          page: 0,
          registersPerPage: 0
        });
        this.cat_facultades = value;
      } catch (error) { }
    },

    async getGastos() {
      try {
        const { value } = await CatGastosApi.list({
          page: 0,
          registersPerPage: 0,
          cat_servicios_id: 1
        });
        this.cat_gastos = value;
      } catch (error) { }
    },

    async validar(form) {
      this.loading = true;
      //ToDo: tambien enviar put por si hacen cambios
      await this.editConstitucion(1);

      let form_obj = this.constitutiva.formularios.find(
        x => x.formulario === form
      );

      try {
        // const { value } =
        let data = {
          cat_formularios_id: form_obj.cat_formularios_id,
          estatus: 1,
          valido: 1,
          validador: this.emailUserStaff,
          constitucion_sociedades_id: this.constitutiva.id,
          activo: 1
        };
        await CatFormulariosConstitucionSociedadesApi.edit(form_obj.id, data);
        this.$toast.success("Formulario validado", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        // console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }

      if (form === "DatosGenerales") {
        this.constitutiva.disabledGenerales = true;
        this.constitutiva.validoGenerales = true;
      }
      if (form === "DatosCapital") {
        this.constitutiva.disabledCapital = true;
        this.constitutiva.validoCapital = true;

        await this.completaComparecientes();
      }
      if (form === "DatosOrganos") {
        this.constitutiva.disabledOrganos = true;
        this.constitutiva.validoOrganos = true;
      }
      if (form === "RepresentantesYApoderados") {
        this.constitutiva.disabledRepresentantes = true;
        this.constitutiva.validoRepresentantes = true;
      }
      if (form === "CapitulosEspeciales") {
        this.constitutiva.disabledEspeciales = true;
        this.constitutiva.validoCapitulos = true;
      }
      if (form === "EntidadFederativa") {
        this.constitutiva.disabledEntidad = true;
        this.constitutiva.validoEntidad = true;
      }
      if (form === "Cotizacion") {
        this.constitutiva.disabledCotizacion = true;
        this.constitutiva.validoCotizacion = true;
      }
      if (form === "ComplementaInformacion") {
        this.constitutiva.disabledComplementa = true;
        this.constitutiva.validoComplementa = true;
      }
      // if (form === "EntregaDocumentos") {
      //   this.constitutiva.disabledEntrega = true;
      // }
      if (form === "Expediente") {
        this.constitutiva.disabledExpediente = true;
      }
      if (form === "CitaFirma") {
        this.constitutiva.disabledCita = true;
        this.constitutiva.validoCita = true;
      }
      if (form === "DenominacionSocial") {
        this.constitutiva.disabledDenominacion = true;
        this.constitutiva.validoDenominacion = true;
      }
      if (form === "GeneraDocumento") {
        this.constitutiva.disbaledGeneraDocs = true;
        this.constitutiva.validoGenera = true;
      }
      this.loading = false;

      // await this.selected(form);
      let nodos = [
        "DatosGenerales",
        "DatosCapital",
        "DatosOrganos",
        "RepresentantesYApoderados",
        "CapitulosEspeciales",
        "EntidadFederativa",
        "Cotizacion",
        "ComplementaInformacion",
        "AsignarCorreduria",
        "DenominacionSocial",
        // "EntregaDocumentos",
        "Expediente",
        "GeneraDocumento",
        "DocFirmado",
        "CitaFirma",
        "Terminado"
      ];

      const index = nodos.findIndex(o => o === this.currentComponentBody);
      let nextIndex = parseInt(index);
      let node = null;
      if (nextIndex < nodos.length - 1) {
        node = nodos[nextIndex + 1];
      } else {
        //a ver a donde envían después de completar esto
        node = "Terminado";
      }

      this.selected(node);
    },

    async setCambios() {
      // this.constitutiva.cambios = [];

      if (this.currentComponentBody === "DatosGenerales") {
        if (
          this.constitutiva.tipo_societario !=
          this.originalConstitutiva.tipo_societario
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "tipo_societario",
            valor_original: this.originalConstitutiva.tipo_societario,
            valor_cambio: this.constitutiva.tipo_societario
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.tipo_societario = this.constitutiva.tipo_societario;
        }
        if (
          this.constitutiva.tiene_capital_variable !=
          this.originalConstitutiva.tiene_capital_variable
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "tiene_capital_variable",
            valor_original: this.originalConstitutiva.tiene_capital_variable,
            valor_cambio: this.constitutiva.tiene_capital_variable
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.tiene_capital_variable = this.constitutiva.tiene_capital_variable;
        }
        if (
          this.constitutiva.autoriza_cd_denominacion !=
          this.originalConstitutiva.autoriza_cd_denominacion
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "autoriza_cd_denominacion",
            valor_original: this.originalConstitutiva.autoriza_cd_denominacion,
            valor_cambio: this.constitutiva.autoriza_cd_denominacion
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.autoriza_cd_denominacion = this.constitutiva.autoriza_cd_denominacion;
        }
        if (
          this.constitutiva.objeto_social !=
          this.originalConstitutiva.objeto_social
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "objeto_social",
            valor_original: this.originalConstitutiva.objeto_social,
            valor_cambio: this.constitutiva.objeto_social
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.objeto_social = this.constitutiva.objeto_social;
        }
        if (
          this.constitutiva.objeto_social_otro !=
          this.originalConstitutiva.objeto_social_otro
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "objeto_social_otro",
            valor_original: this.originalConstitutiva.objeto_social_otro,
            valor_cambio: this.constitutiva.objeto_social_otro
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.objeto_social_otro = this.constitutiva.objeto_social_otro;
        }
        if (
          this.constitutiva.objeto_actividad !=
          this.originalConstitutiva.objeto_actividad
        ) {
          let obj = {
            formulario: "DatosGenerales",
            campo: "objeto_actividad",
            valor_original: this.originalConstitutiva.objeto_actividad,
            valor_cambio: this.constitutiva.objeto_actividad
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.objeto_actividad = this.constitutiva.objeto_actividad;
        }

        if (this.originalConstitutiva.denominaciones_sociales) {
          //.length == 3
          if (
            this.constitutiva.denominaciones_sociales_1 !=
            this.originalConstitutiva.denominaciones_sociales[0]
              .denominacion_social
          ) {
            let obj = {
              formulario: "DatosGenerales",
              campo: "denominaciones_sociales_1",
              valor_original: this.originalConstitutiva
                .denominaciones_sociales[0].denominacion_social,
              valor_cambio: this.constitutiva.denominaciones_sociales_1
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.denominaciones_sociales[0].denominacion_social = this.constitutiva.denominaciones_sociales_1;
          }
          if (
            this.constitutiva.denominaciones_sociales_2 !=
            this.originalConstitutiva.denominaciones_sociales[1]
              .denominacion_social
          ) {
            let obj = {
              formulario: "DatosGenerales",
              campo: "denominaciones_sociales_2",
              valor_original: this.originalConstitutiva
                .denominaciones_sociales[1].denominacion_social,
              valor_cambio: this.constitutiva.denominaciones_sociales_2
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.denominaciones_sociales[1].denominacion_social = this.constitutiva.denominaciones_sociales_2;
          }
          if (
            this.constitutiva.denominaciones_sociales_3 !=
            this.originalConstitutiva.denominaciones_sociales[2]
              .denominacion_social
          ) {
            let obj = {
              formulario: "DatosGenerales",
              campo: "denominaciones_sociales_3",
              valor_original: this.originalConstitutiva
                .denominaciones_sociales[2].denominacion_social,
              valor_cambio: this.constitutiva.denominaciones_sociales_3
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.denominaciones_sociales[2].denominacion_social = this.constitutiva.denominaciones_sociales_3;
          }
        }
      }

      if (this.currentComponentBody === "DatosCapital") {
        //accionistas no veo cambios o sí???
        if (
          this.constitutiva.capital_fijo !=
          this.originalConstitutiva.capital_fijo
        ) {
          let obj = {
            formulario: "DatosCapital",
            campo: "capital_fijo",
            valor_original: this.originalConstitutiva.capital_fijo,
            valor_cambio: this.constitutiva.capital_fijo
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.capital_fijo = this.constitutiva.capital_fijo;
        }
        if (
          this.constitutiva.capital_variable !=
          this.originalConstitutiva.capital_variable
        ) {
          let obj = {
            formulario: "DatosCapital",
            campo: "capital_variable",
            valor_original: this.originalConstitutiva.capital_variable,
            valor_cambio: this.constitutiva.capital_variable
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.capital_variable = this.constitutiva.capital_variable;
        }
        if (
          this.constitutiva.capital_extranjero !=
          this.originalConstitutiva.capital_extranjero
        ) {
          let obj = {
            formulario: "DatosCapital",
            campo: "capital_extranjero",
            valor_original: this.originalConstitutiva.capital_extranjero,
            valor_cambio: this.constitutiva.capital_extranjero
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.capital_extranjero = this.constitutiva.capital_extranjero;
        }
      }

      if (this.currentComponentBody === "DatosOrganos") {
        // data["vigilancia"] = this.constitutiva.vigilancia;
        if (
          this.constitutiva.tipo_administracion !=
          this.originalConstitutiva.tipo_administracion
        ) {
          let obj = {
            formulario: "DatosOrganos",
            campo: "tipo_administracion",
            valor_original: this.originalConstitutiva.tipo_administracion,
            valor_cambio: this.constitutiva.tipo_administracion
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.tipo_administracion = this.constitutiva.tipo_administracion;
        }

        if (
          this.constitutiva.presidente_voto !=
          this.originalConstitutiva.presidente_voto
        ) {
          let obj = {
            formulario: "DatosOrganos",
            campo: "presidente_voto",
            valor_original: this.originalConstitutiva.presidente_voto,
            valor_cambio: this.constitutiva.presidente_voto
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.presidente_voto = this.constitutiva.presidente_voto;
        }

        try {
          for (let i = 0; i < this.constitutiva.administradores.length; i++) {
            const objKeys = Object.keys(this.constitutiva.administradores[i]);
            objKeys.forEach(key => {
              if (this.constitutiva.administradores[i][key] != this.originalConstitutiva.administradores[i][key]) {
                let obj = {
                  formulario: "DatosOrganos",
                  campo: `admin_${i}_${[key]}`,
                  valor_original: this.originalConstitutiva.administradores[i][key],
                  valor_cambio: this.constitutiva.administradores[i][key]
                };
                this.constitutiva.cambios.push(obj);
                this.originalConstitutiva.administradores[i][key] = this.constitutiva.administradores[i][key];
              }
            });
          }
        } catch (e) {
          console.log(e);
        }

        try {
          for (let i = 0; i < this.constitutiva.vigilancia.length; i++) {
            const objKeys = Object.keys(this.constitutiva.vigilancia[i]);
            objKeys.forEach(key => {
              if (
                this.constitutiva.vigilancia[i][key] !=
                this.originalConstitutiva.vigilancia[i][key]
              ) {
                let obj = {
                  formulario: "DatosOrganos",
                  campo: `admin_${i}_${[key]}`,
                  valor_original: this.originalConstitutiva.vigilancia[i][key],
                  valor_cambio: this.constitutiva.vigilancia[i][key]
                };
                this.constitutiva.cambios.push(obj);
                this.originalConstitutiva.vigilancia[i][
                  key
                ] = this.constitutiva.vigilancia[i][key];
              }
            });
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (this.currentComponentBody === "RepresentantesYApoderados") {
        try {
          for (let i = 0; i < this.constitutiva.apoderados.length; i++) {
            if (
              this.constitutiva.apoderados[i].nombre !=
              this.originalConstitutiva.apoderados[i].nombre
            ) {
              let obj = {
                formulario: "RepresentantesYApoderados",
                campo: "apoderados_nombre_" + i,
                valor_original: this.originalConstitutiva.apoderados[i].nombre,
                valor_cambio: this.constitutiva.apoderados[i].nombre
              };
              this.constitutiva.cambios.push(obj);
              this.originalConstitutiva.apoderados[
                i
              ].nombre = this.constitutiva.apoderados[i].nombre;
            }

            if (
              this.constitutiva.apoderados[i].paterno !=
              this.originalConstitutiva.apoderados[i].paterno
            ) {
              let obj = {
                formulario: "RepresentantesYApoderados",
                campo: "apoderados_paterno_" + i,
                valor_original: this.originalConstitutiva.apoderados[i].paterno,
                valor_cambio: this.constitutiva.apoderados[i].paterno
              };
              this.constitutiva.cambios.push(obj);
              this.originalConstitutiva.apoderados[
                i
              ].paterno = this.constitutiva.apoderados[i].paterno;
            }

            if (
              this.constitutiva.apoderados[i].materno !=
              this.originalConstitutiva.apoderados[i].materno
            ) {
              let obj = {
                formulario: "RepresentantesYApoderados",
                campo: "apoderados_materno_" + i,
                valor_original: this.originalConstitutiva.apoderados[i].materno,
                valor_cambio: this.constitutiva.apoderados[i].materno
              };
              this.constitutiva.cambios.push(obj);
              this.originalConstitutiva.apoderados[i].materno = this.constitutiva.apoderados[i].materno;
            }

            for (
              let r = 0;
              r < this.constitutiva.apoderados[i].facultades.length;
              r++
            ) {
              if (
                this.constitutiva.apoderados[i].facultades[r] !=
                this.originalConstitutiva.apoderados[i].facultades[r]
              ) {
                let obj = {
                  formulario: "RepresentantesYApoderados",
                  campo: "apoderados_" + i + "_facultades_" + r,
                  valor_original: this.originalConstitutiva.apoderados[i]
                    .facultades[r],
                  valor_cambio: this.constitutiva.apoderados[i].facultades[r]
                };
                this.constitutiva.cambios.push(obj);
                this.originalConstitutiva.apoderados[i].facultades[
                  r
                ] = this.constitutiva.apoderados[i].facultades[r];
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (this.currentComponentBody === "CapitulosEspeciales") {
        if (
          this.constitutiva.reglas_control !=
          this.originalConstitutiva.reglas_control
        ) {
          let obj = {
            formulario: "CapitulosEspeciales",
            campo: "reglas_control",
            valor_original: this.originalConstitutiva.reglas_control,
            valor_cambio: this.constitutiva.reglas_control
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.reglas_control = this.constitutiva.reglas_control;
        }
        if (
          this.constitutiva.reglas_asambleas !=
          this.originalConstitutiva.reglas_asambleas
        ) {
          let obj = {
            formulario: "CapitulosEspeciales",
            campo: "reglas_asambleas",
            valor_original: this.originalConstitutiva.reglas_asambleas,
            valor_cambio: this.constitutiva.reglas_asambleas
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.reglas_asambleas = this.constitutiva.reglas_asambleas;
        }
      }

      if (this.currentComponentBody === "EntidadFederativa") {
        if (
          this.constitutiva.firma_oficina !=
          this.originalConstitutiva.firma_oficina
        ) {
          let obj = {
            formulario: "EntidadFederativa",
            campo: "firma_oficina",
            valor_original: this.originalConstitutiva.firma_oficina,
            valor_cambio: this.constitutiva.firma_oficina
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.firma_oficina = this.constitutiva.firma_oficina;
        }

        if (
          this.constitutiva.selectedEntidad !=
          this.originalConstitutiva.selectedEntidad
        ) {
          let obj = {
            formulario: "EntidadFederativa",
            campo: "selectedEntidad",
            valor_original: this.originalConstitutiva.selectedEntidad,
            valor_cambio: this.constitutiva.selectedEntidad
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.selectedEntidad = this.constitutiva.selectedEntidad;
        }

        if (
          this.constitutiva.selectedMunicipio !=
          this.originalConstitutiva.selectedMunicipio
        ) {
          let obj = {
            formulario: "EntidadFederativa",
            campo: "selectedMunicipio",
            valor_original: this.originalConstitutiva.selectedMunicipio,
            valor_cambio: this.constitutiva.selectedMunicipio
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.selectedMunicipio = this.constitutiva.selectedMunicipio;
        }

        // if (
        //   this.constitutiva.selectedCorredor !=
        //   this.originalConstitutiva.selectedCorredor
        // ) {
        //   let obj = {
        //     formulario: "EntidadFederativa",
        //     campo: "selectedCorredor",
        //     valor_original: this.originalConstitutiva.selectedCorredor,
        //     valor_cambio: this.constitutiva.selectedCorredor
        //   };
        //   this.constitutiva.cambios.push(obj);
        //   this.originalConstitutiva.selectedCorredor = this.constitutiva.selectedCorredor;
        // }
      }

      if (this.currentComponentBody === "Cotizacion") {
        if (
          this.constitutiva.titulos_acciones !=
          this.originalConstitutiva.titulos_acciones
        ) {
          let obj = {
            formulario: "Cotizacion",
            campo: "titulos_acciones",
            valor_original: this.originalConstitutiva.titulos_acciones,
            valor_cambio: this.constitutiva.titulos_acciones
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.titulos_acciones = this.constitutiva.titulos_acciones;
        }
        if (
          this.constitutiva.libros_corporativos !=
          this.originalConstitutiva.libros_corporativos
        ) {
          let obj = {
            formulario: "Cotizacion",
            campo: "libros_corporativos",
            valor_original: this.originalConstitutiva.libros_corporativos,
            valor_cambio: this.constitutiva.libros_corporativos
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.libros_corporativos = this.constitutiva.libros_corporativos;
        }

        // for (let j = 0; j < this.constitutiva.gastos.length; j++) {
        //   let objGastos = {
        //     cat_gastos_id: this.constitutiva.gastos[j].id,
        //     costo: this.constitutiva.gastos[j].costo
        //   };
        //   gastos.push(objGastos);
        // }
        // data["gastos"] = gastos;
        for (let i = 1; i < this.constitutiva.gastos.length; i++) {
          if (
            this.constitutiva.gastos[i].servicio !=
            this.originalConstitutiva.gastos[i].servicio
          ) {
            let obj = {
              formulario: "Cotizacion",
              campo: "gastos_servicio_" + i,
              valor_original: this.originalConstitutiva.gastos[i].servicio,
              valor_cambio: this.constitutiva.gastos[i].servicio
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.gastos[
              i
            ].servicio = this.constitutiva.gastos[i].servicio;
          }
        }
      }

      if (this.currentComponentBody === "ComplementaInformacion") {
        if (
          this.constitutiva.clausula_extranjeros !=
          this.originalConstitutiva.clausula_extranjeros
        ) {
          let obj = {
            formulario: "ComplementaInformacion",
            campo: "clausula_extranjeros",
            valor_original: this.originalConstitutiva.clausula_extranjeros,
            valor_cambio: this.constitutiva.clausula_extranjeros
          };
          this.constitutiva.cambios.push(obj);
          this.originalConstitutiva.clausula_extranjeros = this.constitutiva.clausula_extranjeros;
        }

        try {
          for (let i = 0; i < this.constitutiva.administradores.length; i++) {
            const objKeys = Object.keys(this.constitutiva.administradores[i]);
            objKeys.forEach(key => {
              if (
                this.constitutiva.administradores[i][key] !=
                this.originalConstitutiva.administradores[i][key]
              ) {
                let obj = {
                  formulario: "DatosOrganos",
                  campo: `admin_${i}_${[key]}`,
                  valor_original: this.originalConstitutiva.administradores[i][
                    key
                  ],
                  valor_cambio: this.constitutiva.administradores[i][key]
                };
                this.constitutiva.cambios.push(obj);
                this.originalConstitutiva.administradores[i][
                  key
                ] = this.constitutiva.administradores[i][key];
              }
            });
          }
        } catch (e) {
          // console.log(e);
        }

        try {
          for (let i = 0; i < this.constitutiva.vigilancia.length; i++) {
            const objKeys = Object.keys(this.constitutiva.vigilancia[i]);
            objKeys.forEach(key => {
              if (
                this.constitutiva.vigilancia[i][key] !=
                this.originalConstitutiva.vigilancia[i][key]
              ) {
                let obj = {
                  formulario: "DatosOrganos",
                  campo: `admin_${i}_${[key]}`,
                  valor_original: this.originalConstitutiva.vigilancia[i][key],
                  valor_cambio: this.constitutiva.vigilancia[i][key]
                };
                this.constitutiva.cambios.push(obj);
                this.originalConstitutiva.vigilancia[i][
                  key
                ] = this.constitutiva.vigilancia[i][key];
              }
            });
          }
        } catch (e) {
          // console.log(e);
        }

        for (let i = 0; i < this.constitutiva.apoderados.length; i++) {
          if (
            this.constitutiva.apoderados[i].nombre !=
            this.originalConstitutiva.apoderados[i].nombre
          ) {
            let obj = {
              formulario: "RepresentantesYApoderados",
              campo: "apoderados_nombre_" + i,
              valor_original: this.originalConstitutiva.apoderados[i].nombre,
              valor_cambio: this.constitutiva.apoderados[i].nombre
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.apoderados[
              i
            ].nombre = this.constitutiva.apoderados[i].nombre;
          }

          if (
            this.constitutiva.apoderados[i].paterno !=
            this.originalConstitutiva.apoderados[i].paterno
          ) {
            let obj = {
              formulario: "RepresentantesYApoderados",
              campo: "apoderados_paterno_" + i,
              valor_original: this.originalConstitutiva.apoderados[i].paterno,
              valor_cambio: this.constitutiva.apoderados[i].paterno
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.apoderados[
              i
            ].paterno = this.constitutiva.apoderados[i].paterno;
          }

          if (
            this.constitutiva.apoderados[i].materno !=
            this.originalConstitutiva.apoderados[i].materno
          ) {
            let obj = {
              formulario: "RepresentantesYApoderados",
              campo: "apoderados_materno_" + i,
              valor_original: this.originalConstitutiva.apoderados[i].materno,
              valor_cambio: this.constitutiva.apoderados[i].materno
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.apoderados[
              i
            ].materno = this.constitutiva.apoderados[i].materno;
          }

          if (
            this.constitutiva.apoderados[i].rfc !=
            this.originalConstitutiva.apoderados[i].rfc
          ) {
            let obj = {
              formulario: "RepresentantesYApoderados",
              campo: "apoderados_rfc_" + i,
              valor_original: this.originalConstitutiva.apoderados[i].rfc,
              valor_cambio: this.constitutiva.apoderados[i].rfc
            };
            this.constitutiva.cambios.push(obj);
            this.originalConstitutiva.apoderados[
              i
            ].rfc = this.constitutiva.apoderados[i].rfc;
          }

          for (
            let r = 0;
            r < this.constitutiva.apoderados[i].facultades.length;
            r++
          ) {
            if (
              this.constitutiva.apoderados[i].facultades[r] !=
              this.originalConstitutiva.apoderados[i].facultades[r]
            ) {
              const obj = {
                formulario: "RepresentantesYApoderados",
                campo: "apoderados_" + i + "_facultades_" + r,
                valor_original: this.originalConstitutiva.apoderados[i]
                  .facultades[r],
                valor_cambio: this.constitutiva.apoderados[i].facultades[r]
              };
              this.constitutiva.cambios.push(obj);
              this.originalConstitutiva.apoderados[i].facultades[r] = this.constitutiva.apoderados[i].facultades[r];
            }
          }
        }
      }
    },

    async saveConstitucion() {
      this.loading = true;
      let saved = await this.editConstitucion();

      if (saved) {
        let nodos = [
          "DatosGenerales",
          "DatosCapital",
          "DatosOrganos",
          "RepresentantesYApoderados",
          "CapitulosEspeciales",
          "EntidadFederativa",
          "Cotizacion",
          "ComplementaInformacion",
          "AsignarCorreduria",
          "DenominacionSocial",
          // "EntregaDocumentos",
          "Expediente",
          "GeneraDocumento",
          "DocFirmado",
          "CitaFirma",
          "Terminado"
        ];

        const index = nodos.findIndex(o => o === this.currentComponentBody);
        let nextIndex = parseInt(index);
        let node = null;
        if (nextIndex < nodos.length - 1) {
          node = nodos[nextIndex + 1];
        } else {
          //a ver a donde envían después de completar esto
          node = "Terminado";
        }

        this.selected(node);
      }
      this.loading = false;
    },

    async editConstitucion(valid) {
      this.loading = true;

      this.constitutiva.cambios = [];
      await this.setCambios();

      let capita_var = 0;
      if (this.constitutiva.tiene_capital_variable === true) {
        capita_var = 1;
      }
      let autoriza_cd = 0;
      if (this.constitutiva.autoriza_cd_denominacion === true) {
        autoriza_cd = 1;
      }
      let objeto_social = this.constitutiva.objeto_social;
      if (this.constitutiva.objeto_social === "Otro") {
        objeto_social = this.constitutiva.objeto_social_otro;
      }

      let accionistasA = [];

      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        let persona = "Física";
        if (this.constitutiva.accionistas[i].persona === "Persona Moral") {
          persona = "Moral";
        }
        if (this.constitutiva.accionistas[i].id) {
          const obj = {
            comparecientes_id: this.constitutiva.accionistas[i].id,
            porcentaje_capital: this.constitutiva.accionistas[i].porcentaje,
            constitucion_sociedades_id: this.constitutiva.accionistas[i].constitucion_sociedades_id,
            cat_servicio_id: this.constitutiva.accionistas[i].cat_servicio_id,
            servicio_id: this.constitutiva.accionistas[i].servicio_id
          };
          accionistasA.push(obj);
        } else {
          const obj = {
            cliente_id: this.constitutiva.clientes_id,
            nombre: this.constitutiva.accionistas[i].nombre,
            paterno: this.constitutiva.accionistas[i].paterno,
            materno: this.constitutiva.accionistas[i].materno,
            persona: persona,
            denominacion_social: this.constitutiva.accionistas[i].denominacion_social,
            porcentaje_capital: this.constitutiva.accionistas[i].porcentaje,
            visible: this.constitutiva.accionistas[i].visible
          };
          accionistasA.push(obj);
        }
      }

      let presidente_voto = 0;
      if (this.constitutiva.presidente_voto === true) {
        presidente_voto = 1;
      }

      let apoderados = [];
      let objA = {};
      for (let i = 0; i < this.constitutiva.apoderados.length; i++) {
        objA = {
          nombre: this.constitutiva.apoderados[i].nombre,
          paterno: this.constitutiva.apoderados[i].paterno,
          materno: this.constitutiva.apoderados[i].materno,
          rfc: this.constitutiva.apoderados[i].rfc
        };
        objA.facultades = [];
        // if (this.constitutiva.apoderados[i].facultades) {
        for (
          let j = 0;
          j < this.constitutiva.apoderados[i].facultades.length;
          j++
        ) {
          let index = this.cat_facultades.findIndex(
            o => o.facultad === this.constitutiva.apoderados[i].facultades[j]
          );
          let objB = {};
          objB = { facultades_id: this.cat_facultades[index].id };
          objA.facultades.push(objB);
        }
        apoderados.push(objA);
        // }
      }

      let firma_oficina = 0;
      if (this.constitutiva.firma_oficina === true) {
        firma_oficina = 1;
      }

      let reglas_control = 0;
      if (this.constitutiva.reglas_control === true) {
        reglas_control = 1;
      }

      let reglas_asambleas = 0;
      if (this.constitutiva.reglas_asambleas === true) {
        reglas_asambleas = 1;
      }

      let capital_extranjero = 0;
      if (this.constitutiva.capital_extranjero === true) {
        capital_extranjero = 1;
      }

      var data = {
        clientes_id: this.constitutiva.clientes_id,
        activo: 1,
        cambios: this.constitutiva.cambios
      };

      if (this.currentComponentBody === "DatosGenerales") {
        // data["estatus"] = "Pagado";
        let denominaciones_sociales = [];
        const obj = {};
        obj[
          "denominacion_social"
        ] = this.constitutiva.denominaciones_sociales_1;
        denominaciones_sociales.push(obj);
        const obj2 = {};
        obj2[
          "denominacion_social"
        ] = this.constitutiva.denominaciones_sociales_2;
        denominaciones_sociales.push(obj2);
        const obj3 = {};
        obj3[
          "denominacion_social"
        ] = this.constitutiva.denominaciones_sociales_3;
        denominaciones_sociales.push(obj3);

        data["tipo_societario"] = this.constitutiva.tipo_societario;
        data["tiene_capital_variable"] = capita_var;
        data["autoriza_cd_denominacion"] = autoriza_cd;
        data["objeto_social"] = objeto_social;
        data["objeto_actividad"] = this.constitutiva.objeto_actividad;
        data["denominaciones_sociales"] = denominaciones_sociales;
        data["formularios"] = [
          {
            formulario: "DatosGenerales",
            estatus: 1,
            //  valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "DatosCapital") {
        // data["estatus"] = "Pagado";
        data["capital_fijo"] = this.constitutiva.capital_fijo;
        data["capital_variable"] = this.constitutiva.capital_variable;
        data["accionistas"] = accionistasA;
        data["capital_extranjero"] = capital_extranjero;
        data["formularios"] = [
          {
            formulario: "DatosCapital",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "DatosOrganos") {
        // data["estatus"] = "Pagado";
        data["tipo_administracion"] = this.constitutiva.tipo_administracion;
        data["presidente_voto"] = presidente_voto;
        data["administradores"] = this.constitutiva.administradores;
        data["vigilancia"] = this.constitutiva.vigilancia;
        data["formularios"] = [
          {
            formulario: "DatosOrganos",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "RepresentantesYApoderados") {
        // data["estatus"] = "Pagado";
        data["apoderados"] = apoderados;
        data["beneficiario_pld"] = this.constitutiva.beneficiario_pld;
        data["beneficiario_cff"] = this.constitutiva.beneficiario_cff;
        data["formularios"] = [
          {
            formulario: "RepresentantesYApoderados",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];

        data.nombre_beneficiario_pld = this.constitutiva.nombre_beneficiario_pld;
        data.nombre_beneficiario_cff = this.constitutiva.nombre_beneficiario_cff;
      }
      if (this.currentComponentBody === "CapitulosEspeciales") {
        let exclusion_accionistas = 0;
        if (this.constitutiva.exclusion_accionistas === true) {
          exclusion_accionistas = 1;
        }

        data["reglas_control"] = reglas_control;
        data["reglas_asambleas"] = reglas_asambleas;
        data["exclusion_accionistas"] = exclusion_accionistas;
        data["formularios"] = [
          {
            formulario: "CapitulosEspeciales",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "EntidadFederativa") {
        // data["corredurias_id"] = this.constitutiva.corredurias_id;
        data["firma_oficina"] = firma_oficina;
        data["entidad"] = this.constitutiva.selectedEntidad;
        data["delegacion_municipio"] = this.constitutiva.selectedMunicipio;
        data["formularios"] = [
          {
            formulario: "EntidadFederativa",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }

      if (this.currentComponentBody === "Cotizacion") {

        this.constitutiva.disabled = true;

        let titulos_acciones = 0;
        if (this.constitutiva.titulos_acciones === true) {
          titulos_acciones = 1;
        }
        data["titulos_acciones"] = titulos_acciones;
        let libros_corporativos = 0;
        if (this.constitutiva.libros_corporativos === true) {
          libros_corporativos = 1;
        }
        data["libros_corporativos"] = libros_corporativos;

        // let objCoti = {
        //   id: this.constitutiva.cotizacion.id,
        //   cupones_id: this.constitutiva.cotizacion.cupones_id,
        //   // cupones_referencia_id: this.constitutiva.cupones_referencia_id,
        //   precio_total: this.constitutiva.precio_total,
        //   // pagado: this.constitutiva.pagado,
        //   // folio_transaccion: this.constitutiva.folio_transaccion
        // };
        // data["cotizacion"] = objCoti;

        let gastos = [];
        for (let j = 0; j < this.constitutiva.gastos.length; j++) {
          let objGastos = {
            cat_gastos_id: this.constitutiva.gastos[j].id,
            costo: this.constitutiva.gastos[j].costo
          };
          gastos.push(objGastos);
        }
        data["gastos"] = gastos;
        data["formularios"] = [
          {
            formulario: "Cotizacion",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }
      if (this.currentComponentBody === "ComplementaInformacion") {
        if (valid === 1) {
          //si viene de validación, cambia estatus
          data["estatus_id"] = "4";
        }
        data["administradores"] = this.constitutiva.administradores;
        data["vigilancia"] = this.constitutiva.vigilancia;
        data["apoderados"] = apoderados;
        data["formularios"] = [
          {
            formulario: "ComplementaInformacion",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];

        let clausula_extranjeros = 0;
        if (this.constitutiva.clausula_extranjeros === true) {
          clausula_extranjeros = 1;
        }
        data["clausula_extranjeros"] = clausula_extranjeros;

        let acciones_valor_nominal = 0;
        if (this.constitutiva.acciones_valor_nominal === true) {
          acciones_valor_nominal = 1;
        }
        data["acciones_valor_nominal"] = acciones_valor_nominal;

        const domicilios = [];
        const domicilio = {
          pais: this.constitutiva.pais,
          entidad: this.constitutiva.entidadDomicilio,
          delegacion_municipio: this.constitutiva.municipioDomicilio
        };
        domicilios.push(domicilio);
        data["domicilios"] = domicilios;
      }
      if (this.currentComponentBody === "DenominacionSocial") {
        for (let o = 0; o < this.constitutiva.denominaciones_sociales.length; o++) {
          if (this.constitutiva.denominaciones_sociales[o].autorizada === 1) {
            this.constitutiva.denominaciones_sociales[o].numero_permiso = this.constitutiva.numero_permiso;
            this.constitutiva.denominaciones_sociales[o].fecha_autoriza_se = this.constitutiva.fecha_autoriza_se;
          }
        }

        data["denominaciones_sociales"] = this.constitutiva.denominaciones_sociales;
        data["documentos"] = this.constitutiva.documentos;
        data["formularios"] = [{
          formulario: "DenominacionSocial",
          estatus: 1,
          // valido: 0,
          validador: this.emailUserStaff
        }];
      }

      if (this.currentComponentBody === "GeneraDocumento") {
        for (let s = 0; s < this.constitutiva.documentos_proyecto.length; s++) {
          let a = Object.assign({}, this.constitutiva.documentos_proyecto[s]);
          this.constitutiva.documentos.push(a);
        }
        data["documentos"] = this.constitutiva.documentos;
        // ];
      }
      if (this.currentComponentBody === "AsignarCorreduria") {
        data["corredurias_id"] = this.constitutiva.corredurias_id;
      }
      if (this.currentComponentBody === "CitaFirma") {
        data["link_calendario"] = this.constitutiva.link_calendario;
        data["direccion_firma"] = this.constitutiva.direccion_firma;
        data["fecha_firma"] = this.constitutiva.fecha_firma;
        data["formularios"] = [
          {
            formulario: "CitaFirma",
            estatus: 1,
            // valido: 0,
            validador: this.emailUserStaff
          }
        ];
      }

      if (this.currentComponentBody === "Terminado") {
        data["estatus_id"] = "6";
        data["formularios"] = [
          {
            formulario: "Terminado",
            estatus: 1,
            valido: 1, //unico btn
            validador: this.emailUserStaff
          }
        ];
      }

      try {
        // Trae la cotizacion
        const _cotizacion = await CotizacionesApi.detail(this.constitutiva.cotizacion.id);
        data.cotizacion = _cotizacion;

        await ConstitucionSociedadesApi.edit(this.constitutiva.id, data);

        this.$toast.success("Información guardada", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        return true;
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.loading = false;
        return false;
      }
    },

    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      this.loading = true;

      if (node != "go") {
        this.prenode = node;
      }

      await this.setCambios();

      if (this.constitutiva.cambios.length > 0) {
        if (node === "go") {
          this.constitutiva.cambios = [];
          this.$refs["modal-confirm-dialog"].hide();
          node = this.prenode;
          this.currentComponentBody = node;
          if (
            node === "DatosGenerales" ||
            node === "DatosCapital" ||
            node === "DatosOrganos" ||
            node === "RepresentantesYApoderados" ||
            node === "CapitulosEspeciales" ||
            node === "EntidadFederativa"
          ) {
            this.currentComponentInfoHead = "InfoBase";
          }

          if (
            node === "CitaFirma" ||
            node === "GeneraDocumento" ||
            node === "InfoBase" ||
            node === "Cotizacion" ||
            node === "Expediente" || //EntregaDocumentos
            node === "ComplementaInformacion" ||
            node === "DenominacionSocial" ||
            node === "AsignarCorreduria" ||
            node === "DocFirmado" ||
            node === "Terminado"
          ) {
            this.currentComponentInfoHead = null;
          }
        } else {
          this.$refs["modal-confirm-dialog"].show();
        }
      } else {
        this.currentComponentBody = node;
        if (
          node === "DatosGenerales" ||
          node === "DatosCapital" ||
          node === "DatosOrganos" ||
          node === "RepresentantesYApoderados" ||
          node === "CapitulosEspeciales" ||
          node === "EntidadFederativa"
        ) {
          this.currentComponentInfoHead = "InfoBase";
        }

        if (
          node === "CitaFirma" ||
          node === "GeneraDocumento" ||
          node === "InfoBase" ||
          node === "Cotizacion" ||
          node === "Expediente" || //EntregaDocumentos
          node === "ComplementaInformacion" ||
          node === "AsignarCorreduria" ||
          node === "DenominacionSocial" ||
          node === "DocFirmado" ||
          node === "Terminado"
        ) {
          this.currentComponentInfoHead = null;
        }
      }
      this.loading = false;
    },

    async getEstatusForm(formulario, consitucion_sociedades_id) {
      try {
        const estatus = await CatFormulariosConstitucionSociedadesApi.getEstatusForm(formulario, consitucion_sociedades_id);

        return estatus;
      } catch { }
    },

    async completaComparecientes() {
      for (const accionista of this.constitutiva.accionistas) {
        accionista.completado = true;
        accionista.cat_servicio_id = 1;
        accionista.servicio_id = this.constitutiva.id;

        await ComparecientesApi.edit(accionista.id, accionista);
      }
    },
  }
};
</script>

<style scoped></style>
