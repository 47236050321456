<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-regenerate" hide-footer id="modal-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar el Acta de Proyecto? En caso de haber una existente, será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaActaProyecto()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-regenerate-firmas" hide-footer id="modal-regenerate-firmas">
      <h4 class="text-center">
        ¿Está seguro de regenerar la Hoja de Firmas? En caso de haber una existente, será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-regenerate-firmas')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaHojaFirmas(primeraVez = false)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="entrega-documento-modal" title="" hide-footer id="modalC" size="xl">
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumentoModal)">
            <ModalEntregaDocumento :currentDocumento="currentDocumento" :ratificacion="ratificacion" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-word" title="" hide-footer id="modaldd" size="xl">
      <ModalPreviewDocx :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD">Actas de Proyecto</h4>

    <b-table ref="table" hover :fields="fieldsCuadro" :items="Actas">
      <template #cell(acciones)="{ item }">
        <b-button v-if="isDocumentoActaGenerada(item) || DocumentType(item) == 'word'" variant="success" size="sm"
          v-b-tooltip.hover title="Descarga documento" @click="descargaFile(item.documento)" class="editBtn">
          <i class="fa fa-download"></i>
        </b-button>

        <b-button v-if="!isDocumentoActaGenerada(item) && DocumentType(item) != 'word' && item.documento"
          variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="openPreviewModal(item)"
          class="editBtn">
          <b-icon-eye />
        </b-button>

        <b-button v-if="[42, 70, 71].includes(parseInt(ratificacion.estatus_id)) && parseInt(item.cat_documentos_id) != 70"
          variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="openEditDocumentoModal(item)"
          class="editBtn">
          <b-icon-upload />
        </b-button>
      </template>
    </b-table>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" @click="openModalRegenerateFirmas()">Regenerar Hoja de
          Firmas</b-button>
      </div>
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" @click="openModalRegenerate()">Regenerar Acta de
          Proyecto</b-button>
      </div>
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!documentosCompletos || !([42, 70, 71].includes(parseInt(ratificacion.estatus_id)))">Continuar</b-button>
      </div>
    </div>

    <div ref="hojaFirmas" hidden>
      <HojaFirmasTemplate :key="hojaKey" :ratificacion="ratificacion" />
    </div>
  </div>
</template>

<script>
// Modales
import ModalPreviewDocx from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewDocx.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalEntregaDocumento from "@/views/clients/procesos/ratificaciones/infoBase/modales/ModalEntregaDocumento.vue";

// Documentos
import HojaFirmasTemplate from "@/views/clients/procesos/ratificaciones/HojaFirmasTemplate.vue";

// API
import { DocumentosApi, DocumentosXServiciosApi, FuncionesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: 'Actas',

  components: {
    ModalPreviewPDF,
    ModalEntregaDocumento,
    ModalPreviewDocx,
    HojaFirmasTemplate
  },

  props: {
    ratificacion: { type: Object }
  },

  mixins: [loadingMixin],

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    }
  },

  created() {
    this.toggleOnLoading();
    this.selectActasFromDocumentos()
      .then(() => this.generaPrimeraHojaFirmas())
      .then(() => this.generaPrimerasActas())
      .then(() => this.areDocumentosCompletos())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.')
      })
      .finally(() => { 
        this.toggleOffLoading();
        console.log(this.documentosCompletos);
        console.log(this.ratificacion.estatus_id);
       })
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,
      documentosCompletos: false,


      proyectoActa: null,
      datos: {},
      actaKey: 0,
      hojaKey: 0,

      Actas: [],

      style_pdf: `<style>
        </style>`,

      stgAccessKey: "?sv=2023-01-03&st=2024-02-09T17%3A04%3A42Z&se=2034-02-11T17%3A04%3A00Z&sr=c&sp=rl&sig=rq9SDEcZMPv%2FbgiTq9FRE66lHZvEAeeiMqytpyiH2sk%3D",
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPreviewModal(item) {
      this.currentDocumento = item;
      this.$refs["documento-modal-preview"].show();
    },

    closePreviewModal() {
      this.$refs["documentos-modal-preview"].hide();
    },

    openModalRegenerate() {
      this.$refs['modal-regenerate'].show();
    },

    closeModalRegenerate() {
      this.$refs['modal-regenerate'].hide();
    },

    openModalRegenerateFirmas() {
      this.$refs['modal-regenerate-firmas'].show();
    },

    closeModalRegenerateFirmas() {
      this.$refs['modal-regenerate-firmas'].hide();
    },

    openDocxModal() {
      this.$refs["documento-modal-preview-word"].show();
    },

    hideDocxModal() {
      this.$refs["documento-modal-preview-word"].hide();
    },

    openEditDocumentoModal(documento) {
      this.$refs["entrega-documento-modal"].show();
      this.currentDocumento = documento;
    },

    hideEntregaDocumentoModal() {
      this.$refs["entrega-documento-modal"].hide();
    },

    selectActasFromDocumentos() {
      return new Promise(resolve => {
        this.Actas = this.ratificacion.documentos
          .filter(doc => (parseInt(doc.cat_documentos_id) >= 70 && parseInt(doc.cat_documentos_id) <= 72) ||
            parseInt(doc.cat_documentos_id) == 87)
          .sort((a, b) => parseInt(a.cat_documentos_id) - parseInt(b.cat_documentos_id));
        resolve(this.Actas);
      });
    },

    async generaPrimeraHojaFirmas() {
      if (!(this.Actas.some(doc => parseInt(doc.cat_documentos_id) === 87))) {
        await this.generaHojaFirmas(true);
      }
    },

    async generaHojaFirmas(primeraVez) {
      const htmlContent = this.$refs.hojaFirmas.outerHTML;
      const style_pdf = `<style>
        .new-page {
            page-break-before: always;
        }

        .tabla {
            width: 100%;
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: separate;
            margin-top: 10px;
        }

        .section {
            text-align: center;
            height: 40px;
        }

        .title {
            text-align: center;
            height: 40px;
        }

        .paragraph {
            padding-bottom: 2em;
        }

        .firma {
            width: 50%;
        }
      </style>`;

      let htmlToConvertPdf = `<!DOCTYPE html>
        <html>
        <head>
          <meta charset="utf-8">
          ${style_pdf}
        </head>
        <body>
        ${htmlContent}
        </body>
        </html>
        `;

      htmlToConvertPdf = htmlToConvertPdf.replace('//');
      const regex = /<body>\s*<div data-v-[a-z0-9]*="" hidden="hidden"><div data-v-[a-z0-9]*=""><table class="tabla">/;
      htmlToConvertPdf = htmlToConvertPdf.replace(regex, '<body><table class="tabla">');
      const regex2 = /<\/table><\/div><\/div>\s*<\/body>/;
      htmlToConvertPdf = htmlToConvertPdf.replace(regex2, '</table> </body>');
      var buffer = Buffer.from(htmlToConvertPdf);
      var buffer_str = buffer.toString("base64");
      var html_firmas = 'hojafirmas' + buffer_str;

      const dataHojaFirmasHtml = {
        proceso_id: this.ratificacion.ratificaciones_id,
        cat_servicios_id: 6,
        html: html_firmas
      };

      await FuncionesApi.generaKyc(dataHojaFirmasHtml);
      if (primeraVez) {
        location.reload();
      } else {
        this.$toast.success("La hoja de firmas fue regenerada exitosamente.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.closeModalRegenerateFirmas();
        return;
      }
    },

    async generaPrimerasActas() {
      let promises = []

      const documents = [
        { id: 70, name: 'Acta Generada' },
        { id: 71, name: 'Acta Word' },
        { id: 72, name: 'Acta Pdf' },
      ];

      documents.forEach(doc => {
        if (!this.ratificacion.documentos.some(item => parseInt(item.cat_documentos_id) === doc.id)) {
          promises.push(this.postDocumento(doc.id, null, null, null, doc.name));
        }
      });

      if (promises.length > 0) {
        await Promise.all(promises);
        location.reload();
      }
      else {
        return;
      }
    },

    postDocumentoModal() {
      this.hideEntregaDocumentoModal();
      this.sendCurrentDocumentoToAzureStorage();
    },

    async sendCurrentDocumentoToAzureStorage() {
      try {
        this.toggleOnLoading();
        const formData = new FormData();
        this.currentDocumento.nombre = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const path = await FuncionesApi.file(
          this.ratificacion.ratificaciones_id,
          "documentosRatificaciones",
          formData,
          {}
        );
        this.currentDocumento.documento = path;
        this.updateDocumentoBD();
      } catch (error) {
        this.showErrorToast('Error al mandar el archivo. Intente más tarde.');
        console.log(error);
      } finally {
        this.toggleOffLoading();
      }
    },

    async updateDocumentoBD() {
      try {
        await DocumentosApi.edit(this.currentDocumento.id, {
          documento: this.currentDocumento.documento,
          cat_documentos_id: this.currentDocumento.cat_documentos_id,
          auto_referencia: this.currentDocumento.auto_referencia,
          descripcion: this.currentDocumento.descripcion,
          paginas: parseInt(this.currentDocumento.paginas),
          copias_certificadas: null,
          nombre: this.currentDocumento.nombre
        })
        this.updateRatificacionDocumentoFromCurrentDocumento();
      } catch (error) {
        this.showErrorToast('Error al actualizar el documento. Intente más tarde.');
        console.log(error);
      } finally {
      }
    },


    updateRatificacionDocumentoFromCurrentDocumento() {
      const ratificacionDocumentosIndex = this.getRatificacionDocumentosIndex(this.currentDocumento);
      this.ratificacion.documentos[ratificacionDocumentosIndex] = this.currentDocumento;
      this.areDocumentosCompletos();
    },

    getRatificacionDocumentosIndex(documento) {
      let ratificacionDocumentosIndex = this.ratificacion.documentos.findIndex(
        (ratificacionDocumento) => ratificacionDocumento.id === parseInt(documento.id)
      );
      return ratificacionDocumentosIndex;
    },

    async postDocumento(cat_documentos_id, auto_referencia, descripcion, paginas, nombre) {
      const { id } = await DocumentosApi.create({
        documento: "",
        cat_documentos_id: cat_documentos_id,
        auto_referencia: auto_referencia,
        descripcion: descripcion,
        paginas: paginas,
        copias_certificadas: null,
        nombre: nombre
      })
      await DocumentosXServiciosApi.create({
        documentos_id: id,
        cat_servicios_id: 6,
        proceso_id: this.ratificacion.ratificaciones_id
      })
      return id;
    },

    areDocumentosCompletos() {
      // debugger;
      if (this.Actas.length === 0) {
        return;
      }

      this.documentosCompletos = this.Actas.every(documento => documento.documento);
    },

    async generaActaProyecto() {
      this.closeModalRegenerate();
      this.toggleOnLoading();
      const url_proyecto = await FuncionesApi.generaProyecto({
        proceso_id: this.ratificacion.ratificaciones_id,
        cat_servicios_id: 6,
        ratificacion: this.ratificacion
      });
      console.log(url_proyecto)
      this.$toast.success("El acta fue regenerada exitosamente.", {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
      this.toggleOffLoading();
      location.reload();
    },


    isDocumentoActaGenerada(documento) {
      return parseInt(documento.cat_documentos_id) === 70;
    },

    DocumentType(item) {
      let documentoSeparatedByDots = item.nombre.split('.')
      let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]
      if (imageExtensions.includes(extensionDocumento)) {
        return 'image';
      }
      if (['doc', 'docx'].includes(extensionDocumento)) {
        return 'word'
      }
      return 'pdf';
    },


    descargaFile(item) {
      const link = document.createElement("a");
      link.href = item + this.stgAccessKey;
      document.body.appendChild(link);
      link.click();
    },
  }

}
</script>

<style scoped>
.bottom1 {
  float: left;
}

.green-btn {
  background-color: green;
}
</style>
