import { render, staticRenderFns } from "./ReestablecerPass.vue?vue&type=template&id=b47a6556&scoped=true"
import script from "./ReestablecerPass.vue?vue&type=script&lang=js"
export * from "./ReestablecerPass.vue?vue&type=script&lang=js"
import style0 from "./ReestablecerPass.vue?vue&type=style&index=0&id=b47a6556&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b47a6556",
  null
  
)

export default component.exports