import { render, staticRenderFns } from "./TreeEditRatificaciones.vue?vue&type=template&id=31fdecba&scoped=true"
import script from "./TreeEditRatificaciones.vue?vue&type=script&lang=js"
export * from "./TreeEditRatificaciones.vue?vue&type=script&lang=js"
import style0 from "./TreeEditRatificaciones.vue?vue&type=style&index=0&id=31fdecba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fdecba",
  null
  
)

export default component.exports