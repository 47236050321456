import { render, staticRenderFns } from "./TreeRatificaciones.vue?vue&type=template&id=33bc71e0&scoped=true"
import script from "./TreeRatificaciones.vue?vue&type=script&lang=js"
export * from "./TreeRatificaciones.vue?vue&type=script&lang=js"
import style0 from "./TreeRatificaciones.vue?vue&type=style&index=0&id=33bc71e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bc71e0",
  null
  
)

export default component.exports