<template>
  <div class="container" id="container-montos">

    <h4 class="cardD">Montos</h4>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="monto_global" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El monto es obligatorio',
        }">
          <label for="">Monto Global de la Operación (Incluye todos los documentos)</label>
          <base-input class="custom-number" type="number" required placeholder="0" :min="0" :max="75000000"
            v-model="ratificacion.monto_global">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-6">
      <div class="col-md-12">
        <ValidationProvider name="clausula_adeudo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La selección de cláusula de adeudo es obligatoria'
        }">
          <b-form-checkbox id="chkClausulaAdeudo" v-model="clausula_adeudo">
            <label for="chkClausulaAdeudo" class="">Hay cláusula expresa de reconocimiento de adeudo</label>
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-6" v-if="clausula_adeudo">
      <div class="col-md-12">
        <ValidationProvider name="adeudo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El monto es obligatorio',
        }">
          <label for="">Monto de adeudo</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="ratificacion.adeudo">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-6">
      <div class="col-md-12">
        <ValidationProvider name="inscripcion_rug" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La selección de inscripción es obligatoria'
        }">
          <b-form-checkbox id="chkInscripcionRug" v-model="inscripcion_rug">
            <label for="chkInscripcionRug" class="">Se inscribe en el RUG</label>
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-6" v-if="inscripcion_rug">
      <div class="col-md-12">
        <ValidationProvider name="monto_garantia" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El monto es obligatorio',
        }">
          <label for="">Monto de Garantía</label>
          <base-input class="custom-number" type="number" required placeholder="1" :min="1"
            v-model="ratificacion.monto_garantia">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkMontos()">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Montos",

  components: {
  },

  props: {
    ratificacion: { type: Object }
  },

  computed: {
  },

  created() {
    if (this.ratificacion.clausula_adeudo === 1) {
      this.clausula_adeudo = true;
    }
    if (this.ratificacion.inscripcion_rug === 1) {
      this.inscripcion_rug = true;
    }
  },

  data() {
    return {
      clausula_adeudo: false,
      inscripcion_rug: false
    }
  },

  methods: {
    checkMontos() {
      if (this.clausula_adeudo) {
        this.ratificacion.clausula_adeudo = 1;
      } else {
        this.ratificacion.clausula_adeudo = 0;
        this.ratificacion.adeudo = 0;
      };
      if (this.inscripcion_rug) {
        this.ratificacion.inscripcion_rug = 1;
      } else {
        this.ratificacion.inscripcion_rug = 0;
        this.ratificacion.monto_garantia = 0;
      }
      ;
      this.$emit('allChecksPassed');
    }
  }
}
</script>

<style scoped>
.bottom2 {
  /* position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100; */
  float: right;
}

.container>>>.form-check-sign {
  float: left;
  margin-left: 10px;
  position: absolute;
}

.container>>>.control-label {
  margin-left: 30px;
  width: 500px;
  position: absolute;
  vertical-align: middle;
}

.container>>>.form-control {
  width: 300px;
  margin-left: 30px;
}

.card label {
  width: 400px;
}
</style>
